import React, { Component } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { TableComp } from 'sz-react-utils-lite'

import moment from 'moment'
import {
    Table,
    Badge,
    Card,
    Tag,
    Tooltip,
    Popconfirm,
    Select,
    Form,
    notification,
    Switch,
    Input, Button, Icon
} from 'antd'
import Request from '../../../request'
import Color from 'color'
import _ from 'lodash'
import async from 'async'
import Highlighter from 'react-highlight-words'
import styles from './styles.less'
import { connect } from 'react-redux'
import memoizeOne from 'memoize-one'
import update from 'immutability-helper'
import { getUrlPushWrapper, getPushPathWrapper } from '../../../routes'
import { timeStampFormat } from '../../../settings'


@Form.create()
class AllHelpDesk extends Component {

    constructor(props) {
        super(props)
        this.table = React.createRef();
        this.state = {
            loading: false,
            pagination: {},
            allhelpdesk: []
        }
    }
    deleteHelpDesk = async id => {
        this.setState({ loading: true })
        let x = await Request.deleteHelpDesk(id)
        this.setState({ loading: false })
        this.table.current.reload()

        if (!x.error) {
            notification.success({
                message: x.message,
            })
        }
        else {
            notification.error({
                message: x.message,
            })
        }
    }

    componentDidMount() {
        // this.getCompanies()
        // this.apiRequest()
    }
    apiRequest = (params, columns) => {
        return new Promise(async resolve => {
            let filters = {
                ...params,
            }
            this.setState({ loading: true })
            let data = await Request.getAllHelpDesk(filters);
            this.setState({ loading: false })
            if (data) {
                this.setState({ allhelpdesk: data.data, total: data.total })
            }
            if (data.error) {
                notification.error({
                    message: data.message,
                })
            }
            resolve(data)
        })
    }
    render() {
        const { submitting, dispatch, form: { getFieldDecorator } } = this.props
        const { loading, total, allhelpdesk, pagination } = this.state


        const columns = [
            {
                title: 'S.No',
                dataIndex: 'sno',
                width: 50,
                key: 'sno',
                render: (value, item, index) => (1 - 1) * 10 + index + 1
            },
            {
                title: 'Keyword',
                dataIndex: 'keyword',
                key: 'keyword',
                // searchTextName: true
                width: 150
            },
            {
                title: 'Language',
                dataIndex: 'language',
                key: 'language',
                width: 100

                // searchTextName: true
            },

            {
                title: 'HTML',
                dataIndex: 'terms',
                key: 'terms',
                // searchTextName: true,
                width: 500
            },
            {
                title: 'Created At',
                dataIndex: 'createdAt',
                key: 'createdAt',
                width: 100,
                render: (val, record) => {
                    return <Tag>{moment(record.createdAt).format(timeStampFormat)}</Tag>
                }
            },

            {
                key: 'actions',
                title: 'Actions',
                width: 100,
                render: (text, record) => {
                    return <React.Fragment>
                        <Tooltip title="Edit">
                            <Button
                                shape="circle"
                                style={{ marginRight: 6 }}
                                size="small"
                                onClick={() => {
                                    dispatch(
                                        getPushPathWrapper('helpDesk.editHelpdesk', { id: record.id })
                                    )
                                }}
                                icon="edit"
                            />
                        </Tooltip>
                        <Tooltip title="Delete">
                            <Popconfirm
                                title="Are you sure delete this help desk setting?"
                                onConfirm={() => {
                                    this.deleteHelpDesk(record.id)
                                }}
                                onCancel={() => {
                                    console.log()
                                }}
                                okText="Yes"
                                cancelText="No">
                                <Button
                                    shape="circle"
                                    size="small"
                                    icon="delete"
                                    type='danger'
                                />
                            </Popconfirm>
                        </Tooltip>
                    </React.Fragment>
                }
            }

        ]
        return (
            <PageHeaderWrapper
                title={`All Help Desk Settings (${total && total})`}>
                <Card bordered={true}>
                    <TableComp ref={this.table}
                        columns={columns}
                        bordered={true}
                        // dataSource={allGroups}
                        loading={loading}
                        apiRequest={params => this.apiRequest(params, columns)}
                        extraProps={{
                            scroll: { x: true }
                        }}
                        pagination={{
                            showSizeChanger: true,
                            defaultPageSize: 20,
                            pageSizeOptions: ['10', '20', '50', '100', '1000']
                        }}
                    />

                </Card>

            </PageHeaderWrapper>)

    }
}


const mapStateToProps = ({ global }) => ({
    categories: global.categories
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AllHelpDesk)
