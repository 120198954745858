import React, { useEffect, useState, useRef } from 'react'
import {
    notification,
    Table,
    Button,
    Spin,
    Form,
    Drawer, Card, Tag,
    Select,
    Tooltip,
    Popconfirm,
    Switch
} from 'antd'
import { TableComp } from 'sz-react-utils-lite'
import _ from 'lodash'
import FormItem from '../../../components/FormItem'
import moment from 'moment'
import { dateFormat, timeStampFormat } from '../../../settings'
import Request, { API_URL } from '../../../request'
import { FormUtils as GetAllFormFields } from 'sz-react-utils-lite'
import { AddTestingUserAction, ListIps } from '../actions/testingUserAction'
import { useSelector, useDispatch } from 'react-redux'

const AddTestingUser = (props) => {
    const { form } = props
    const dispatch = useDispatch()
    const [state, setState] = useState({
        edit: false
    })

    const { testingUserLoader, currentUser, testingUserResp, addedSuccessfully } = useSelector((state) => ({
        testingUserResp: state.testingUser.testingUserResp,
        testingUserLoader: state.testingUser.testingUserLoader,
        addedSuccessfully: state.testingUser.addedSuccessfully,
        currentUser: state.global.currentUser
    }))
    console.log(addedSuccessfully)
    useEffect(() => {
        if (addedSuccessfully) {
            form.resetFields()
            form.setFieldsValue({ enableDisable: false })
            dispatch({ type: 'TESTING_USER_ERROR_CHECKER' })
        }
    }, [addedSuccessfully])



    const handleSubmit = (e) => {
        e.preventDefault()
        const { form } = props
        console.log(form.getFieldValue('enableDisable'))
        form.validateFieldsAndScroll(async (err, valData) => {
            console.log(valData)
            if (!err) {

                // dispatch(showLoader())
                if (!currentUser) {
                    return notification.error({
                        message: 'Error Saving',
                        description: 'Try Again'
                    })
                }




                dispatch(AddTestingUserAction(valData))
                // form.resetFields()


            } else {
                notification.warning({
                    message: 'Fill All Required Fields'
                })
            }
        })
    }






    const {
        form: { getFieldDecorator }
    } = props



    const submitFormLayout = {
        wrapperCol: {
            xs: { span: 24, offset: 0 },
            sm: { span: 10, offset: 7 },
            md: { span: 12, offset: 8 }
        }
    }
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
            md: { span: 8 }
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
            md: { span: 12 }
        }
    }
    const validateRule = {
        rules: [{ required: true, message: 'Required field' }]
    }
    const fIAll = {
        getFieldDecorator,
        validateRule
    }
    return (
        <Card title={'Add Testing User'}>
            <Form onSubmit={handleSubmit} style={{ marginTop: 8 }}>


                <FormItem
                    {...fIAll}
                    {...formItemLayout}
                    name='name'
                />
                <FormItem
                    {...fIAll}
                    {...formItemLayout}
                    label={'Mobile No.'}
                    inputType={'number'}
                    name='mobile'
                />
                <Form.Item {...formItemLayout} label={'Enable/Disable'}>
                    {getFieldDecorator('enableDisable', {
                        valuePropName: "checked"
                    })(
                        <Switch ></Switch>
                    )}
                </Form.Item>
                <Form.Item {...submitFormLayout} style={{ marginTop: 32 }}>
                    <Button
                        type="primary"
                        htmlType="submit"
                        loading={testingUserLoader}
                    >
                        SAVE
                            </Button>
                </Form.Item>
            </Form>
        </Card>





    )
}
const WrappedComponent = Form.create()(AddTestingUser)

export default WrappedComponent