import { apiUrl } from "../../../settings";

export const addPackageUrl = () => {
    return apiUrl + '/smsPacks';
};
export const updatePackageUrl = (id) => {
    return apiUrl + '/smsPack/' + id;
};
export const deletePackageUrl = (id) => {
    return apiUrl + '/smsPack/' + id;
};

export const listPackageUrl = () => {
    return apiUrl + '/smsPacks';
};