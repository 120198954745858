import React, { PureComponent } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
    Form,
    Select,
    Button,
    Card,
    InputNumber,
    Input,
} from 'antd'
import _ from 'lodash'
import 'react-quill/dist/quill.snow.css'
import { notification } from 'antd/lib/index'
import { hideLoader, showLoader } from '../../../modules/actions'
import Request from '../../../request'
import { connect } from 'react-redux'
import { getUrlParams } from '../../../routes'
import FormItem from '../../../components/FormItem'
import capitalize from 'capitalize'
const { Option } = Select

@Form.create()
class addUser extends PureComponent {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            companies: []
        }
    }

    componentDidMount() {
        this.getChildrens()
        let data = getUrlParams('user.updateUser', this.props.pathname)
        if (data && data.id) {
            this.setState({
                loading: true,
                edit: true,
                id: data.id
            }, () => {
                this.setFormValues(data.id)
            })
        }
    }
    componentWillUpdate(prevsProps) {
        console.log('company update', this.props.company, prevsProps.company)
        if (prevsProps.company != this.props.company) {
            !this.state.edit && this.props.form.resetFields()
        }
    }
    getChildrens = async () => {
        let data = await Request.listCompanies()
        if (!data.error) {
            this.setState({ companies: data.data })
        }
    }
    handleSubmit = e => {
        const { dispatch, form } = this.props
        // console.log(this.props.company)
        e.preventDefault()
        form.validateFieldsAndScroll(async (err, valData) => {
            if (!err) {

                dispatch(showLoader())
                valData.companyId = this.props.company || valData.company
                if(this.state.edit){ 
                    valData.companyId = this.props.company || valData.company
                    delete valData.company
                }

                let x = this.state.edit ? await Request.updateUser(this.state.id, valData) : await Request.addUser(valData)
                dispatch(hideLoader())
                console.log(x)
                if (!x.error) {
                    notification.success({
                        message: x.message
                    })
                    !this.state.edit && this.props.form.resetFields()
                    this.props.afterAdd && this.props.afterAdd()
                }
                else {
                    notification.error({
                        message: 'Error Saving',
                        description: x.message
                    })
                }
            }

        })
    }
    setFormValues = async (id) => {
        this.setState({ loading: true })
        let data = await Request.getUser(id)
        this.setState({ loading: false })
        if (data.error || !data.data) {
            notification.error({
                message: 'Something Went Wrong'
            })
            return false;
        }
        console.log(data) 
        let obj = {
            name: data.data.name,
            email: data.data.email,
            address: data.data.address,
            mobile: data.data.mobile,
            company: data.data.companyId && data.data.companyId.id,
            // password: data.data.password
        }
        this.props.form.setFieldsValue(obj)
    }

    render() {
        const {
            form: { getFieldDecorator, getFieldValue, setFields },
            loading
        } = this.props
        const { loading: cardLoading, edit, companies } = this.state
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
                md: { span: 8 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
                md: { span: 12 }
            }
        }

        const submitFormLayout = {
            wrapperCol: {
                xs: { span: 24, offset: 0 },
                sm: { span: 10, offset: 7 },
                md: { span: 12, offset: 8 }
            }
        }
        const validateRule = {
            rules: [{ required: true, message: 'Required field' }]
        }
        const fIAll = {
            getFieldDecorator,
            validateRule
        }
        const fIAll1 = {
            getFieldDecorator
        }
        return (
            <Card id={'primaryDetails'} title='Add User' loading={cardLoading}>
                <Form {...formItemLayout} onSubmit={this.handleSubmit} >
                    <Form.Item label='User Type'>{
                        getFieldDecorator('userType', {
                            rules: [{ required: true }],
                            initialValue: 'company'
                        })(
                            <Select>
                                <Option value='company' >Company</Option>
                                {!this.props.company && <Option value='subAdmin' >SubAdmin</Option>}
                            </Select>
                        )
                    }</Form.Item>

                    {!this.props.company && !this.props.form.getFieldValue('userType') !== 'company' && <Form.Item label='Company'>{
                        getFieldDecorator('company', {
                            rules: [{ required: true }]
                        })(
                            <Select showSearch optionFilterProp="children">{
                                companies.map(val => (
                                    < Option value={val.id} > {val.name}</Option>
                                ))
                            }</Select>
                        )
                    }</Form.Item>}
                    <FormItem
                        {...fIAll}
                        {...formItemLayout}
                        name='name'
                    />
                    <FormItem
                        {...fIAll}
                        {...formItemLayout}
                        name='address'
                    />
                    <FormItem
                        {...fIAll}
                        {...formItemLayout}
                        name='mobile'
                        label='Mobile No.'
                    />
                    <FormItem
                        {...fIAll}
                        {...formItemLayout}
                        name='email'
                        label='E-mail'
                    />
                    {
                    // !edit &&
                     }
                    <Form.Item {...submitFormLayout}>
                        <Button type='primary' htmlType='submit' loading={loading} >{edit ? 'update' : 'Add'}</Button>
                    </Form.Item>
                </Form>
            </Card>

        )
    }
}

const mapStateToProps = ({ global, router }) => ({
    loading: global.buttonLoading,
    categories: global.categories,
    search: router.location.search,
    pathname: router.location.pathname,
    currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(addUser)
