import React, { PureComponent } from 'react'
import _ from 'lodash'
import { Spin } from 'antd'
import styles from './styles.less'
import { FormUtils as GetAllFormFields } from 'sz-react-utils-lite'
import { API_URL } from '../../request'
import { getUrlParams } from '../../routes'

import Request from '../../request'
import { connect } from 'react-redux'

class AutoMilkType extends PureComponent {

    state = {
        cardLoading: false,
        loading: false
    }
    // }
    apiRequest = async () => {
        let id = getUrlParams('mcp.editMcc', this.props.pathname)
        const { currentUser, form, companyId, settingLevel } = this.props
        this.setState({ loading: true })
        let filter = { level: settingLevel, companyId }
        if (settingLevel == 'MCP' && id && id.id)
            filter.mcpId = id.id
        else
            filter.level = 'COMPANY'
        let data = await Request.getAutoMilkType(filter)
        this.setState({ loading: false })
        if (data && data.data) {
            const settingObj = data.data
            form.setFieldsValue({
                autoMilkType: settingObj.autoMilkType
            }, () => {
                form.setFieldsValue({
                    fat: settingObj.fat
                })
            })
        }
        else {
            form.setFieldsValue({ autoMilkType: false })
        }

    }

    componentDidMount() {
        // this.getTemplates()
        this.props.setDefaultSettings(this.apiRequest.bind(this))
        this.apiRequest()
    }


    render() {
        const {
            form: { getFieldDecorator, getFieldValue, setFieldsValue },
            setInputFields,
            settingLevel,
            isCompany
        } = this.props


        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
                md: { span: 8 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
                md: { span: 12 }
            }
        }


        const inputType = {
            fields: [
                {
                    label: 'Auto Milk Type',
                    key: 'autoMilkType',
                    dataIndex: 'autoMilkType',
                    type: 'switch',
                    required: true,
                    onChange: (autoMilkType) => {
                        setFieldsValue({ autoMilkType })
                    }

                },
                {
                    label: 'Maximum FAT for Cow Milk',
                    key: 'fat',
                    dataIndex: 'fat',
                    required: true,
                    hidden: !getFieldValue('autoMilkType'),
                    type: 'number',
                    onChange: (fat) => {
                        setFieldsValue({ fat })
                    }
                }
            ]
        }
        setInputFields(inputType.fields.map(field => field.key), 3)

        return (
            <div className={styles.container}>
                {settingLevel === 'COMPANY' && !isCompany ?
                    <h4 style={{ color: 'blue' }}>{'NOTE :This MCP has company level settings'}</h4>
                    : <Spin tip={'Loading...'} spinning={this.state.loading}>
                        <GetAllFormFields
                            apiurl={API_URL}
                            inputSchema={inputType}
                            formItemLayout={formItemLayout}
                            getFieldDecorator={getFieldDecorator}
                        />
                    </Spin>}
            </div>

        )
    }
}

const mapStateToProps = ({ global, router }) => ({
    loading: global.buttonLoading,
    categories: global.categories,
    search: router.location.search,
    currentUser: global.currentUser,
    pathname: router.location.pathname

})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AutoMilkType)
