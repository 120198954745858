export default (
    state = {
        trialCompanyLoader: false,
        trialCompanyList: {},
        testingCompanyEdit: {}

    },
    action
) => {
    switch (action.type) {
        case "TRIAL_COMPANY_SHOW_LOADER":
            return { ...state, trialCompanyLoader: true };
        case "TRIAL_COMPANY_HIDE_LOADER":
            return { ...state, trialCompanyLoader: false };
        case "TRIAL_COMPANY_LIST":
            return { ...state, trialCompanyList: action.payload };
        case "TRIAL_COMPANY_EDIT_RESPONSE":
            return { ...state, trialCompanyEdit: action.payload };


        default:
            return state;
    }
};
