import axios from "axios";
import { addIpUrl, listIpUrl, updateIpUrl } from "../api/ipUrls";
import { notification } from 'antd'
import { useSelector } from "react-redux";
import { getToken } from "../../../request";

export const AddIpAction = (valData) => async (dispatch) => {
    dispatch({ type: 'IP_BTN_SHOW_LOADER' });

    await axios
        .post(addIpUrl(), valData, getToken())
        .then(function ({ data: x }) {
            dispatch({ type: 'IP_BTN_HIDE_LOADER' });

            if (!x.error) {
                notification.success({
                    message: x.message

                })
                dispatch({ type: 'IP_RESPONSE', payload: x })
            } else {
                notification.error({
                    message: 'Error Saving',
                    description: x.message
                })
            }
        })
        .catch(function (error) {
            // toast.error(JSON.stringify(error));
            dispatch({ type: 'IP_BTN_HIDE_LOADER' });
        });
};
export const UpdateIpAction = (id, valData) => async (dispatch) => {
    dispatch({ type: 'IP_BTN_SHOW_LOADER' });

    await axios
        .put(updateIpUrl(id), valData, getToken())
        .then(function ({ data: x }) {
            dispatch({ type: 'IP_BTN_HIDE_LOADER' });

            if (!x.error) {
                notification.success({
                    message: x.message

                })
                dispatch({ type: 'IP_RESPONSE', payload: x })
            } else {
                notification.error({
                    message: 'Error Saving',
                    description: x.message
                })
            }
        })
        .catch(function (error) {
            // toast.error(JSON.stringify(error));
            dispatch({ type: 'IP_BTN_HIDE_LOADER' });
        });
};
export const DeleteIpAction = (id) => async (dispatch) => {
    dispatch({ type: 'IP_SHOW_LOADER' });

    await axios
        .delete(updateIpUrl(id), getToken())
        .then(function ({ data: x }) {
            dispatch({ type: 'IP_HIDE_LOADER' });

            if (!x.error) {
                notification.success({
                    message: x.message

                })
                dispatch({ type: 'IP_RESPONSE', payload: x })
            } else {
                notification.error({
                    message: 'Error Saving',
                    description: x.message
                })
            }
        })
        .catch(function (error) {
            // toast.error(JSON.stringify(error));
            dispatch({ type: 'IP_HIDE_LOADER' });
        });
};
export const ListIps = (inputData) => async (dispatch) => {
    dispatch({ type: 'IP_SHOW_LOADER' });
    await axios
        .get(listIpUrl(), { params: { ...inputData }, ...getToken() })
        .then(function ({ data }) {
            dispatch({ type: 'IP_HIDE_LOADER' });
            if (data.error) {
                notification.error({
                    message: data.message,
                })
            }
            else
                dispatch({
                    type: 'IP_LIST',
                    payload: data,
                });
        })
        .catch(function (error) {
            // toast.error(JSON.stringify(error));
            dispatch({ type: 'IP_HIDE_LOADER' });
        });
};