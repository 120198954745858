import React, { PureComponent } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
  Form,
  Button,
  Card,
  Tabs,
} from 'antd'
import _ from 'lodash'
import { FormUtils as GetAllFormFields } from 'sz-react-utils-lite'
import { API_URL } from '../../../request'
import GeneralSettings from '../../../components/Settings/generalSettings'
import FarmerTitle from '../../../components/Settings/farmerTitle'
import AutoMilkTypeSettings from '../../../components/Settings/autoMilkTypeSetting'
import HardwareSettings from '../../../components/Settings/hardwareSetting'
import PrinterSettings from '../../../components/Settings/printerSettings'
import { notification } from 'antd/lib/index'
import Request from '../../../request'

import { connect } from 'react-redux'
import { getUrlParams } from '../../../routes'
import CollectionExportSetting from '../../../components/Settings/collectionExportSetting'

const shortid = require('shortid')

const { TabPane } = Tabs

@Form.create()
class AddMcc extends PureComponent {

  constructor(props) {
    super(props)
    this.state = {
      major: '',
      value: 1,
      activeKey: '1',
      allCompany: [],
      allGroups: [],
      tabsAlert: [],
      fields: [],
      id: false,
      uuid: shortid.generate(),
      settingLevel: '',
      loading: false,
      cardLoading: true
    }
  }

  handleSubmit = e => {
    const { dispatch, form, currentUser } = this.props
    const { fields, settingLevel, tabsAlert } = this.state
    let paramId = getUrlParams('mcp.editMcc', this.props.pathname)
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        if (valData.tear && !valData.tearValue) {
          notification.warning({
            message: 'Scale Tear Value is Required Fields'
          })
          tabsAlert.push(5)
          this.setState({ tabsAlert: tabsAlert })
          return;
        }
        if (valData.collectionMode == 'AUTOMATIC') {
          valData.manualAnalyser = false
          valData.manualScale = false
        }
        valData.parameterDecimal = {
          quantity: valData.quantity,
          scanner: valData.scanner,
          totalAmount: valData.totalAmount
        }
        delete valData.quantity
        delete valData.scanner
        delete valData.totalAmount
        valData.farmerTitle = {
          title: valData.farmerTitle
        }
        if (this.state.farmerTitleId) {
          valData.farmerTitleId = this.state.farmerTitleId
        }
        valData.machineSettings = this.createMachineSettings(valData)

        let x = null
        // valData.companyId = currentUser.companyId
        valData.fields = this.getExportFields()
        this.setState({ loading: true })
        if (paramId && paramId.id) {
          valData._id = paramId.id
          x = await Request.updateMcp({ ...valData, settingLevel })
        } else {
          x = await Request.addMcp(valData)
        }
        this.setState({ loading: false })
        if (!x.error) {
          notification.success({
            message: x.message

          })
          if (!paramId || !paramId.id) {
            this.props.form.resetFields()
            this.defaultSettings()
            this.farmerTitle()
            this.autoMilkType()
            this.collectionExport()
            this.hardwareSetting()
            this.printerSetting()
            this.setState({
              uuid: shortid.generate(),
              tabsAlert: []
            })
            this.props.form.setFieldsValue({ uuid: this.state.uuid })
          } else {
            this.apiRequest()
          }
        } else {
          notification.error({
            message: 'Error Saving',
            description: x.message
          })
        }
      }
      else {
        let alertsKey = []
        _.forEach(err, (val, key) => {
          _.forEach(fields, (arr, index) => {
            if (arr && arr.includes(key)) {
              alertsKey.push(index)
              return
            }
          })
        })
        this.setState({ tabsAlert: alertsKey })
        notification.warning({
          message: 'Fill All Required Fields'
        })
      }
    })
  }

  setFormValues = async mccId => {
    if (mccId) {
      this.setState({ cardLoading: true })
      Request.getCompleteMcpById(mccId).then(async ({ data, setObj, farmerTitle, error, message }) => {
        if (!error) {
          this.getGroups(data.companyId)
          this.setState({ settingLevel: data.settingLevel, loading: false, cardLoading: false })
          let inputValue = {
            contactName: data && data.contactName,
            uuid: data && data.uuid,
            // contactPersonNo: data && data.contactPersonNo,
            name: data && data.name,
            mobile: data && data.mobile,
            email: data && data.email,
            address: data && data.address,
            companyId: data.companyId,
            mcpGroupId: data.mcpGroupId,
            mcpId: data.mcpId,
            companyId: data.companyId,
            ...setObj,
            ...setObj.parameterDecimal
          }
          if (farmerTitle) {
            this.setState({ farmerTitleId: farmerTitle.id })
            inputValue.farmerTitle = farmerTitle.title
          }
          this.props.form.setFieldsValue(inputValue)

        } else {
          notification.error({
            message: 'Error Getting Data'
          })
        }
      })
    }
  }

  apiRequest = async () => {
    this.getCompanies()
    let id = getUrlParams('mcp.editMcc', this.props.pathname)
    if (id && id.id) {
      this.setState({
        id: true,
      })
      this.setFormValues(id.id)

    } else {
      this.setState({ loading: false, cardLoading: false }, () => {
        this.props.form.setFieldsValue({ uuid: this.state.uuid })
      })
    }


  }
  createMachineSettings = (valData) => {
    //create machine Settings
    let hardwareSettings = [
      {
        type: 'analyser',
        value: valData.analyser
      },
      {
        type: 'scale',
        value: valData.scale
      },
      {
        type: 'tear',
        enabled: valData.tear
      },
      {
        type: 'tearValue',
        value: valData.tearValue
      },
      {
        type: 'showTearButton',
        enabled: valData.showTearButton
      },
      {
        type: 'autoTear',
        enabled: valData.autoTear
      }
    ]
    let printerSettings = [
      {
        type: 'printer',
        value: valData.printer
      },
      {
        type: 'printerHeaderFooter',
        value: { header: valData.printHeader, footer: valData.printFooter }
      },
      {
        type: 'printerParams',
        value: {
          farmerNameAndId: valData.printFarmerNameAndId,
          farmerMobile: valData.printFarmerMobile,
          quantity: valData.printQuantity,
          fat: valData.printFat,
          snf: valData.printSnf,
          clr: valData.printClr,
          density: valData.printDensity,
          lactose: valData.printLactose,
          salts: valData.printSalts,
          protein: valData.printProtein,
          temp: valData.printTemp,
          addedWater: valData.printAddedWater,
          freezingPoint: valData.printFreezingPoint,
          milkType: valData.printMilkType,
          collectionDate: valData.printCollectionDate,
          collectionTime: valData.printCollectionTime,
          rateChartName: valData.printRateChartName,
          rate: valData.printRate,
          totalAmount: valData.printTotalAmount,
          status: valData.printStatus,
          stage: valData.printStage,
          timeOfPrint: valData.printTimeOfPrint,
          incentive: valData.printIncentive,
        }
      }
    ]
    return [...hardwareSettings, ...printerSettings]
  }


  setInputFields = (fields, tab) => {
    let newFields = [...this.state.fields]
    newFields[tab - 1] = fields
    this.state.fields = newFields
  }

  getGroups = async (id) => {
    let { data: allGroups } = await Request.getAllGroup({ companyId: id })
    if (allGroups) {
      if (this.props.currentUser.userType === 'manager')
        allGroups = allGroups.filter(val => this.props.currentUser.mcpGroups.includes(val.id))
      this.setState({ allGroups })
    }

  }
  getValue = async (headName, e, head) => {
    let { settingRecords } = this.state
    let v = _.find(settingRecords, x => x.headName === headName)

    v[head] = e
    this.setState({
      settingRecords,
      key: Math.random() * 100
    })
  }

  componentDidMount() {
    const { form: { setFields } } = this.props

    setFields({ table: ['dd'] })
    this.apiRequest()
  }

  getCompanies = async () => {
    let { data: allCompany } = await Request.listCompanies()
    this.setState({ allCompany: allCompany || [] })

  }

  onChange = e => {
    this.setState({
      value: e.target.value
    })
  }

  updateUUid(val) {
    let { form: { setFieldsValue } } = this.props
    if (!this.state.id) {
      if (!val) {
        setFieldsValue({
          uuid: shortid.generate()
        })
      }
    }
  }

  render() {
    const {
      form: { getFieldDecorator, setFieldsValue, getFieldValue }
    } = this.props
    const { editorState, settingRecords, loading, tabsAlert, id, settingLevel, cardLoading } = this.state
    const subInputTypes1 = {
      fields: [
        {
          label: 'Select Company',
          key: 'companyId',
          disabled: id ? true : false,
          type: 'select',
          showSearch: true,
          keyAccessor: x => x.id,
          valueAccessor: x => `${x.name} (${x.email})`,
          options: this.state.allCompany,
          onChange: companyId => {
            this.setState({ allGroups: [] })
            this.getGroups(companyId)
            this.props.form.setFieldsValue({ companyId, mcpGroupId: undefined })
          },
          required: true
        },
        {
          label: 'Select Group',
          key: 'mcpGroupId',
          type: 'select',
          showSearch: true,
          keyAccessor: x => x.id,
          valueAccessor: x => `${x.groupName}`,
          options: this.state.allGroups,
          onChange: mcpGroupId => {
            this.props.form.setFieldsValue({ mcpGroupId })
          },
          required: true
        },
        {
          label: 'Name',
          key: 'name',
          //  dataIndex: 'name',
          required: true
        },
        {
          label: 'MCP Id',
          key: 'mcpId',
          //  dataIndex: 'name',
          required: true,
          disabled: id ? true : false
        },
        {
          label: 'Unique Id',
          key: 'uuid',
          //dataIndex: 'uuid',
          disabled: true
        },
        {
          label: 'Contact Person',
          key: 'contactName'
          // dataIndex: 'contactName'
        },
        {
          label: 'Mobile No.',
          key: 'mobile'
          //dataIndex: 'mobile'
        },
        {
          title: 'Address',
          //dataIndex: 'address',
          key: 'address'
        }
      ]
    }
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
        md: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
        md: { span: 12 }
      }
    }

    const submitFormLayout = {
      wrapperCol: {
        xs: { span: 24, offset: 0 },
        sm: { span: 10, offset: 7 },
        md: { span: 12, offset: 8 }
      }
    }

    this.setInputFields(subInputTypes1.fields.map(field => field.key), 1)
    return (
      <PageHeaderWrapper title={this.state.id ? 'Update Mcp' : 'Add Mcp'}>
        <Card loading={cardLoading}>
          <Form onSubmit={this.handleSubmit} style={{ marginTop: 8 }} noValidate>
            <Tabs type="card" defaultActiveKey="1">
              <TabPane forceRender={true} tab={<b id={'testing'} style={tabsAlert.includes(0) ? { color: 'red' } : {}}>MCP General Details</b>} key='1'>
                <div style={{ marginTop: 30 }} id="uniqueid" >

                  <GetAllFormFields
                    apiurl={API_URL}
                    inputSchema={subInputTypes1}
                    formItemLayout={formItemLayout}
                    getFieldDecorator={getFieldDecorator}
                  />

                </div>
              </TabPane>
              <TabPane forceRender={true} tab={<b id={'testing2'} style={tabsAlert.includes(1) ? { color: 'red' } : {}}>MCP Simple Settings</b>} key='2'>
                <div key={getFieldValue('companyId')} style={{ marginTop: 30 }}>
                  {
                    !id && <GeneralSettings companyId={getFieldValue('companyId')} setInputFields={this.setInputFields} setDefaultSettings={(fn) => {
                      this.defaultSettings = fn
                    }} form={this.props.form} />
                  }
                  {

                    id && settingLevel == 'MCP' && <GeneralSettings companyId={getFieldValue('companyId')} setInputFields={this.setInputFields} setDefaultSettings={(fn) => {
                      this.defaultSettings = fn
                    }} form={this.props.form} />
                  }

                  {
                    id && settingLevel == 'COMPANY' &&
                    <h4 style={{ color: 'blue', textAlign: 'center' }}>{'NOTE :This MCP has company level settings'}</h4>
                  }

                </div>
              </TabPane>
              <TabPane forceRender={true} tab={<b style={tabsAlert.includes(2) ? { color: 'red' } : {}}>Auto Milk Type Settings</b>} key='3'>
                <div key={getFieldValue('companyId')} style={{ marginTop: 30 }}>
                  <AutoMilkTypeSettings companyId={getFieldValue('companyId')} settingLevel={settingLevel} setInputFields={this.setInputFields} setDefaultSettings={(fn) => {
                    this.autoMilkType = fn
                  }} form={this.props.form} />
                </div>
              </TabPane>

              <TabPane forceRender={true} tab={<b style={tabsAlert.includes(3) ? { color: 'red' } : {}}>MCP Farmer Title Setting</b>} key='4'>
                <div key={getFieldValue('companyId')} style={{ marginTop: 30 }}>
                  <FarmerTitle companyId={getFieldValue('companyId')} settingLevel={settingLevel} setInputFields={this.setInputFields} setDefaultSettings={(fn) => { this.farmerTitle = fn }} form={this.props.form} />
                </div>
              </TabPane>

              <TabPane forceRender={true} tab={<b style={tabsAlert.includes(4) ? { color: 'red' } : {}}>Collection Export Settings</b>} key='5'>
                <div key={getFieldValue('companyId')} style={{ marginTop: 30 }}>
                  <CollectionExportSetting companyId={getFieldValue('companyId')} settingLevel={settingLevel} setInputFields={this.setInputFields}
                    setDefaultSettings={(fn) => {
                      this.collectionExport = fn
                    }}
                    getExportFields={(fn) => {
                      this.getExportFields = fn
                    }}
                  />
                </div>
              </TabPane>
              <TabPane forceRender={true} tab={<b style={tabsAlert.includes(5) ? { color: 'red' } : {}}>Hardware Setting</b>} key='6'>
                <div key={getFieldValue('companyId')} style={{ marginTop: 30 }}>
                  <HardwareSettings companyId={getFieldValue('companyId')} settingLevel={settingLevel} setInputFields={this.setInputFields} setDefaultSettings={(fn) => { this.hardwareSetting = fn }} form={this.props.form} />
                </div>
              </TabPane>
              <TabPane forceRender={true} tab={<b style={tabsAlert.includes(6) ? { color: 'red' } : {}}>Printer Setting</b>} key='7'>
                <div key={getFieldValue('companyId')} style={{ marginTop: 30 }}>
                  <PrinterSettings companyId={getFieldValue('companyId')} settingLevel={settingLevel} setInputFields={this.setInputFields} setDefaultSettings={(fn) => { this.printerSetting = fn }} form={this.props.form} />
                </div>
              </TabPane>
            </Tabs>
            <Form.Item {...submitFormLayout} style={{ marginTop: 32 }}>
              <Button
                type="primary"
                htmlType="submit"
                loading={loading}
              >
                SAVE
              </Button>
            </Form.Item>

          </Form>

        </Card>
      </PageHeaderWrapper>
    )
  }
}

const mapStateToProps = ({ global, router }) => ({
  loading: global.buttonLoading,
  categories: global.categories,
  search: router.location.search,
  pathname: router.location.pathname,
  currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddMcc)
