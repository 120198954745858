import React, { Component } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { getPushPathWrapper } from '../../../routes'
import { TableComp } from 'sz-react-utils-lite'
import {
    Table,
    Badge,
    Card,
    Tooltip,
    notification,
    Switch,
    Tag,
    Input, Button, Icon
} from 'antd'
import Request from '../../../request'
import _ from 'lodash'
import Highlighter from 'react-highlight-words'
import { connect } from 'react-redux'
import memoizeOne from 'memoize-one'
import update from 'immutability-helper'
import styles from './style.less'
import { hideLoader, showLoader } from '../../../modules/actions';
import { any } from 'prop-types'

import capitalize from 'capitalize'
import moment from 'moment'
class listTickets extends Component {


    state = {
        tableLoading: false,
        loading: false,
        dataSource: []
    }


    componentDidMount() {
        this.apiRequest();
    }
    apiRequest = (params = {}) => {
        return new Promise(async (resolve) => {
            this.setState({ tableLoading: true })

            let data = await Request.listTickets({ company: this.props.currentUser.company && this.props.currentUser.company });
            console.log(data)
            this.setState({
                dataSource: data.error ? [] : data.data,
                tableLoading: false,
                loading: false,
                filteredInfo: params.filters || {}
            })
            return resolve(data.data)
        })
    }
    handleChange = (pagination, filters, sorter) => {
        console.log('Various parameters', pagination, filters, sorter);
        this.setState({
            filteredInfo: filters,
        });
        this.apiRequest({
            results: pagination.pageSize,
            page: pagination.current,
            sortField: sorter.field,
            sortOrder: sorter.order,
            filters
        })
    };

    getColumnSearchProps = (dataIndex, index) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    id={`input-${dataIndex}`}
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    id={`search-${dataIndex}`}
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm)}
                    icon="search"
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
            </Button>
                <Button id={`reset-${dataIndex}`} onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reset
            </Button>
            </div>
        ),
        filterIcon: filtered => (
            <Icon id={`filter-menu-${dataIndex}`} type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) => {
            return index ?
                (
                    record[dataIndex][index]
                        .toString()
                        .toLowerCase()
                        .includes(value.toLowerCase())

                ) :
                (
                    record[dataIndex]
                        .toString()
                        .toLowerCase()
                        .includes(value.toLowerCase())

                )
        },
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: text => (
            <Highlighter
                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                searchWords={[this.state.searchText]}
                autoEscape
                textToHighlight={text.toString()}
            />
        ),
    });

    handleSearch = (selectedKeys, confirm) => {
        confirm();
        this.setState({ searchText: selectedKeys[0] });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };
    undo = async (id) => {
        const response = await Request.restoreTicket(id)
        notification.destroy()
        this.apiRequest()
    }
    deleteTicket = async (id) => {
        this.setState({ loading: true })
        const response = await Request.deleteTicket(id)

        if (!response.error) {
            notification.success({
                message: capitalize.words(response.message),
                btn: <Button type='primary' onClick={() => this.undo(id)}>Undo</Button>
            })
            this.apiRequest()
        } else {
            notification.error({
                message: 'Error Saving',
                description: capitalize.words(response.message)
            })
        }
    }
    ticketsDetails = async (id) => {
        this.props.dispatch(getPushPathWrapper('ticketDetails', { id }))
    }
    render() {
        const { tableLoading, dataSource, loading } = this.state
        const columns = [
            {
                title: 'id',
                key: 'id',
                dataIndex: 'id'
            },
            {
                title: 'Raised By',
                dataIndex: 'raisedBy',
                key: 'raisedBy',
                // ...this.getColumnSearchProps('name'),
            },
            {
                title: 'Subject',
                dataIndex: 'subject',
                key: 'subject',
                // ...this.getColumnSearchProps('name'),
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                render: (val) => {
                    return (
                        val === 'Resolved' ? <Tag color='green'>{val}</Tag> :
                            val === 'Pending' ? <Tag color='red'>{val}</Tag> :
                                <Tag color='blue'>{val}</Tag>
                    )
                }
                // ...this.getColumnSearchProps('name'),
            },
            {
                title: 'Severity',
                dataIndex: 'severity',
                key: 'severity'
                // ...this.getColumnSearchProps('address'),
            },
            {
                title: 'Device',
                dataIndex: 'device.id',
                key: 'device',
                // ...this.getColumnSearchProps('discount'),

            },
            {
                title: 'Description',
                dataIndex: 'description',
                key: 'description',
                width: 200,
                render: (val) => {
                    return <div className={styles.desColumn}>{val}</div>
                }
                // ...this.getColumnSearchProps('discount')
            },
            {
                title: 'Created At',
                dataIndex: 'createdAt',
                key: 'createdAt',
                render: (val) => <div>{moment(val).format('L')}</div>
                // ...this.getColumnSearchProps('address'),
            },
            {
                title: 'Updated At',
                dataIndex: 'updatedAt',
                key: 'updatedAt',
                render: (val) => <div>{moment(val).fromNow()}</div>
                // ...this.getColumnSearchProps('address'),
            },
            {
                title: 'Action',
                dataIndex: 'id',
                key: 'action',
                render: (val) => {
                    return (
                        <div>
                            <Button shape="circle" icon='info' id={'info'} className={styles.iconBtn} onClick={() => this.ticketsDetails(val)} />
                            <Button shape="circle" icon="delete" loading={loading} type='danger' id={'delete'} className={styles.iconBtn} onClick={() => this.deleteTicket(val)} />
                        </div>
                    )
                }
            }
        ];

        return (
            <PageHeaderWrapper
                title={'All Tickets'}>
                <Card id={'listTickets'}>
                    <Table bordered size='small' id={'listTickets-table'} loading={tableLoading} columns={columns} rowKey={record => record.id} dataSource={dataSource} onChange={this.handleChange} />
                </Card>

            </PageHeaderWrapper>)

    }
}


const mapStateToProps = ({ global }) => ({
    categories: global.categories,
    currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(listTickets)
