import React, { Component, useEffect, useRef, useState } from 'react'
import moment from 'moment'
import {
    Divider,
    InputNumber,
    notification,
    Drawer,
    DatePicker,
    Form,
    Card,
    Input, Button, message,
    Select
} from 'antd'
import Request from '../../../request'
import Color from 'color'
import _ from 'lodash'
import async from 'async'
import { push } from 'connected-react-router'
import styles from './styles.less'
import { connect, useDispatch, useSelector } from 'react-redux'
import { FormUtils as GetAllFormFields } from 'sz-react-utils-lite'
import { hideLoader, showLoader } from '../../../modules/actions'
import { apiUrl, SITEKEY } from '../../../settings'
import { Auth } from 'aws-amplify'
import { register } from '../action/registerAction'
const { Option } = Select;

const Register = (props) => {
    const dispatch = useDispatch()
    const captchaDemoRef = useRef(null)
    const captchaDemo = captchaDemoRef.current
    const { form } = props

    const [state, setState] = useState({
        loading: false,
        anchorFlag: true,
        loader: false,
        register: false,
        cardLoading: true
    })
    const { registerLoader, registerChecker } = useSelector(state => ({
        registerLoader: state.register.registerLoader,
        registerChecker: state.register.registerChecker,
    }))
    useEffect(() => {
        if (registerChecker) {
            form.resetFields()
            dispatch({ type: 'REGISTER_ERROR_CHECKER' })
            dispatch(push('/login'))
        }

    }, [registerChecker])



    const handleSubmit = (e) => {
        e.preventDefault()
        const { form } = props
        form.validateFieldsAndScroll(async (err, valData) => {
            valData.trial = true
            if (!err) {

                // dispatch(showLoader())





                dispatch(register(valData))
                // form.resetFields()


            } else {
                notification.warning({
                    message: 'Fill All Required Fields'
                })
            }
        })
    }







    const { getFieldDecorator, getFieldValue } = props.form
    const { cardLoading } = state
    const formItemLayout = {
        labelCol: { span: 7 },
        wrapperCol: { span: 15 },
        // labelCol: {
        //     xs: { span: 24 },
        //     sm: { span: 8 },
        //     md: { span: 8 }
        // },
        // wrapperCol: {
        //     xs: { span: 24 },
        //     sm: { span: 16 },
        //     md: { span: 12 }
        // }
    }

    const required = {
        rules: [{
            required: true,
            message: 'Field Required'
        }]
    }
    return (
        <Form {...formItemLayout} layout={'horizontal'} onSubmit={handleSubmit} style={{ marginTop: 8 }} className={styles.main}>

        <h1 style={{display:'flex', justifyContent:'center'}}>Add New Company</h1>

            <Form.Item 
            label='Your Name'
            labelAlign={'right'}
            >{getFieldDecorator('contactPerson', required)(
                <Input maxLength={'20'} placeholder='Full Name' />
            )}</Form.Item>
            <Form.Item 
            label='Mobile'
            >{getFieldDecorator('mobile', required)(
                <Input placeholder='Mobile No.' />
            )}</Form.Item>
            <Form.Item 
            label='Company Name'
            >{getFieldDecorator('name', required)(
                <Input placeholder='Company Name' />
            )}</Form.Item>
            <Form.Item 
            label='Address'
            >{getFieldDecorator('address', required)(
                <Input.TextArea placeholder='address' />
            )}</Form.Item>
            <Form.Item 
            label='State'
            >{getFieldDecorator('state', required)(
                <Input placeholder='State' />
            )}</Form.Item>
            <Form.Item 
            label='City'
            >{getFieldDecorator('city', required)(
                <Input placeholder='city' />
            )}</Form.Item>
            <Form.Item 
            label='Pin Code'
            >{getFieldDecorator('pincode', required)(
                <InputNumber placeholder='Pin Code' />
            )}</Form.Item>
            <Form.Item 
            label='GST NO.'
            >{getFieldDecorator('gstNo')(
                <Input placeholder='GST No.' />
            )}</Form.Item>
             <Form.Item 
            label='No Of MCPs'
            >{getFieldDecorator('maxMcp', required)(
                <InputNumber placeholder='No. Of MCPs' />
            )}</Form.Item>
            <Form.Item 
            label='E-Mail'
            >{getFieldDecorator('email', required)(
                <Input type='email' placeholder='E-mail id' />
            )}</Form.Item>
            <Form.Item 
            label='Password'
            >{getFieldDecorator('password', required)(
                <Input.Password visibilityToggle={true} placeholder='Password' />
            )}</Form.Item>
            <Form.Item 
            label='Source'
            >{getFieldDecorator('source', required)(
                <Select placeholder='Source'>
                    <Option value="India Mart">India Mart</Option>
                    <Option value="Direct Visit">Direct Visit</Option>
                    <Option value="Other">Other</Option>

                </Select>
            )}</Form.Item>


            <div className={styles.btns}>
                <Button id='register' type="primary" htmlType="submit" loading={registerLoader}>
                    Register
              </Button>
            </div>
            <div className={styles.btns}>
                <a id='login' className={styles.cancel} onClick={(e) => {
                    e.preventDefault()
                    dispatch(push('/login'))
                }
                }>
                    Already have an account?
            </a>
            </div>
        </Form>
    )
}

const wrapperRegister = Form.create()(Register)

export default wrapperRegister
