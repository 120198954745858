import React, { useEffect, useState, useRef } from 'react'
import {
    notification,
    Table,
    Button,
    Spin,
    Form,
    Drawer, Card, Tag,
    Select,
    Tooltip,
    Popconfirm,
    Switch,
    Input,
    Icon
} from 'antd'
import Highlighter from 'react-highlight-words'
import { SearchOutline } from '@ant-design/icons'
import { TableComp } from 'sz-react-utils-lite'
import _ from 'lodash'
import moment from 'moment'
import { dateFormat, timeStampFormat } from '../../../settings'
import Request, { API_URL } from '../../../request'
import { FormUtils as GetAllFormFields } from 'sz-react-utils-lite'
import { ListTestingUser, deleteTestingUserById } from '../actions/testingUserAction'
import { useSelector, useDispatch } from 'react-redux'
import { getPushPathWrapper } from '../../../routes'

const AllTestingUser = (props) => {
    const dispatch = useDispatch()


    const { testingUserLoader, currentUser, testingUserList } = useSelector((state) => ({
        testingUserList: state.testingUser.testingUserList,
        testingUserLoader: state.testingUser.testingUserLoader,
        currentUser: state.global.currentUser
    }))

    const [state, setState] = useState({})
    const [pagination, setPagination] = useState({})
    useEffect(() => {
        dispatch(ListTestingUser())
    }, [])
    const switchUser = (checked) => {
        console.log(checked)
    }
    const editTestingUser = async (id) => {
        dispatch(getPushPathWrapper('testingUser.editTestingUser', { id }))
    }
    const deleteTestingUser = async (id) => {
        dispatch(deleteTestingUserById(id)).then(() => {
            dispatch(ListTestingUser())
        })
    }
    const {
        form: { getFieldDecorator }
    } = props
    const getColumnSearchProps = (dataIndex, index) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    id={`input-${dataIndex}`}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    id={`search-${dataIndex}`}
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm)}
                    icon="search"
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
            </Button>
                <Button id={`reset-${dataIndex}`} onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reset
            </Button>
            </div>
        ),
        filterIcon: filtered => (
            <Icon id={`filter-menu-${dataIndex}`} style={{ color: filtered ? '#1890ff' : undefined }} type='search' />
        ),
        onFilter: (value, record) => {
            return index ?
                (
                    record[dataIndex][index]
                        .toString()
                        .toLowerCase()
                        .includes(value.toLowerCase())

                ) :
                (
                    record[dataIndex]
                        .toString()
                        .toLowerCase()
                        .includes(value.toLowerCase())

                )
        },
        render: text => (
            <Highlighter
                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                searchWords={[state.searchText]}
                autoEscape
                textToHighlight={text.toString()}
            />
        ),
    });

    const handleSearch = (selectedKeys, confirm) => {
        confirm();
        setState({ ...state, searchText: selectedKeys[0] });
    };

    const handleReset = clearFilters => {
        clearFilters();
        setState({ ...state, searchText: '' });
    };
    const columns = [

        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            ...getColumnSearchProps('name')
        },
        {
            title: 'Mobile',
            dataIndex: 'mobile',
            key: 'mobile',
            ...getColumnSearchProps('mobile')

        },
        {
            title: 'Enable/Disable',
            dataIndex: 'enableDisable',
            key: 'enableDisable',
            filters: [{ text: 'True', value: true }, { text: 'False', value: false }],
            onFilter: (value, record) => (record.enableDisable === value),
            render: (val) => {
                return val ? <Tag color='blue'>True</Tag> : <Tag color='red'>False</Tag>
            }

        },
        {
            title: 'Actions',
            dataIndex: 'id',
            key: 'action',
            render: (val) => {
                return (<><Button shape="circle" icon="edit" id={'edit'} onClick={() => editTestingUser(val)} />
                    <Button style={{ marginLeft: 6 }} color={'red'} shape="circle" icon="delete" id={'delete'} onClick={() => deleteTestingUser(val)} />
                    </>)
            }
        }
    ]
    return (
        <Card title={`All Testing Users (${testingUserList.data.length})`}>

            <TableComp
                size='small'
                loading={testingUserLoader}
                columns={columns}
                dataSource={testingUserList.data}
                pagination={{
                    ...pagination,
                    defaultPageSize: 10,
                    pageSizeOptions: ['10', '25', '50', '100', '500'],
                    showSizeChanger: false
                }}
            />
        </Card>
    )
}
const WrappedComponent = Form.create()(AllTestingUser)

export default WrappedComponent