import React, { useEffect, useState } from 'react'
import { Card, Tabs } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import { GetMcpDetailAction } from '../action/debugActions'
import { LazyLog, ScrollFollow } from 'react-lazylog';
import styles from './styles.less'
import { socketUrl } from '../../../settings'
import IO from 'socket.io-client'
import moment from 'moment'
const { TabPane } = Tabs
const DebugScreen = (props) => {

    const dispatch = useDispatch()
    const [text, setText] = useState(' ')
    let socket = null
    useEffect(() => {
        if (props.uuid) {
            socket = IO(socketUrl, { secure: true, transports: ['websocket'] }) // replace 'environment.serverUrl' with your server url
            socket.on('connect', () => {
                console.log('socket connected')
                socket.emit('superAdmin', props.uuid)
            })
            socket.on('debugLogs', (data) => {
                console.log(data, 'socket datatata')
                setText((prevText) => {
                    let newText = `${moment().format("hh:mm:ss")}: ${JSON.stringify(data)}`
                    return (
                        prevText === ' ' ?
                            `${newText}` :
                            `${prevText}\n${newText}`
                    )
                })
            })
        }
        // if (props.mcpId)
        // dispatch(GetMcpDetailAction(props.mcpId))
    }, [props.mcpId])

    const { currentUser, mcpDetails } = useSelector((state) => ({
        currentUser: state.global.currentUser,
        mcpDetails: state.debug.mcpDetails.data || {}
    }))
    return (
        <div className={styles.lazyLog}>
            <ScrollFollow
                startFollowing
                render={({ onScroll, follow, startFollowing, stopFollowing }) => (
                    <LazyLog enableSearch
                        lineClassName={styles.lineClass}
                        caseInsensitive
                        style={{}}
                        text={text}
                        onScroll={onScroll} follow={follow} />
                )}
            />
        </div>
    )
}

export default DebugScreen
