export default (
    state = {
        packageLoader: false,
        packageBtnLoader: false,
        packageList: {data:[]},
        packageResp: {},
    },
    action
) => {
    switch (action.type) {
        case "PACKAGE_SHOW_LOADER":
            return { ...state, packageLoader: true };
        case "PACKAGE_HIDE_LOADER":
            return { ...state, packageLoader: false };
        case "PACKAGE_BTN_SHOW_LOADER":
            return { ...state, packageBtnLoader: true };
        case "PACKAGE_BTN_HIDE_LOADER":
            return { ...state, packageBtnLoader: false };
        case "PACKAGE_LIST":
            return { ...state, packageList: action.payload };
        case "PACKAGE_RESPONSE":
            return { ...state, packageResp: action.payload };
        default:
            return state;
    }
};
