import axios from "axios";
import { listTrialCompanies, editTrialCompanies } from "../apis/trialCompaniesApi";
import { notification } from 'antd'
import { useSelector } from "react-redux";
import { getToken } from "../../../request";


export const ListTrialCompanies = (inputData) => async (dispatch) => {
    console.log(inputData)
    dispatch({ type: 'TRIAL_COMPANY_SHOW_LOADER' });
    await axios
        .get(listTrialCompanies(), { params: { ...inputData }, ...getToken() })
        .then(function ({ data }) {
            if (data.error) {
                notification.error({
                    message: data.message,
                })
            }
            else
                dispatch({
                    type: 'TRIAL_COMPANY_LIST',
                    payload: data,
                });
            dispatch({ type: 'TRIAL_COMPANY_HIDE_LOADER' });

        })
        .catch(function (error) {
            console.log(error)
            // toast.error(JSON.stringify(error));
            dispatch({ type: 'TRIAL_COMPANY_HIDE_LOADER' });
        });
};
export const UpdateTrialCompany = (id, inputData) => async (dispatch) => {
    dispatch({ type: 'TRIAL_COMPANY_SHOW_LOADER' });
    await axios
        .put(editTrialCompanies(id), inputData, getToken())
        .then(function ({ data }) {
            dispatch({ type: 'TRIAL_COMPANY_HIDE_LOADER' });

            if (data.error) {
                notification.error({
                    message: data.message,
                })
            }else {
                notification.success({
                    message:data.message,
                })
            }
            
        })
        .catch(function (error) {
            console.log('error', error);
            notification.error({
                message: JSON.stringify(error)
            })
            // toast.error(JSON.stringify(error));
            dispatch({ type: 'TRIAL_COMPANY_HIDE_LOADER' });
        });
};