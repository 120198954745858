import React, { useEffect, useState, useRef } from 'react'
import {
    Button,
    Drawer, Card, Tag,
    Popconfirm,
    Icon,
    Input,
    DatePicker,
    Table
} from 'antd'
// import styles from './styles.less'
import Highlighter from 'react-highlight-words'
import async from 'async'
import { TableComp } from 'sz-react-utils-lite'
import _ from 'lodash'
import moment from 'moment'
import { dateFormat, timeStampFormat } from '../../../settings'
import Request, { API_URL } from '../../../request'
import { FormUtils as GetAllFormFields } from 'sz-react-utils-lite'
import { CollectionCountList } from '../action/collectionCountAction'
import { useSelector, useDispatch } from 'react-redux'
const { RangePicker } = DatePicker;

const AllCollectionCount = (props) => {
    const table = useRef(null)

    const dispatch = useDispatch()
    const [state, setState] = useState({
        dateFilters: {}, searchText: '', list: [], listFirst: [], filter: [], total: 0
    })

    const { collectionCountLoader, currentUser, collectionCountList } = useSelector((state) => ({
        collectionCountList: state.collectionCount.collectionCountList,
        collectionCountLoader: state.collectionCount.collectionCountLoader,
        currentUser: state.global.currentUser
    }))

    const [pagination, setPagination] = useState({})
    useEffect(() => {
        apiRequest()
    }, [])
    useEffect(() => {
        if (collectionCountList) {
            setState({ ...state, total: collectionCountList.total })
        }
    }, [collectionCountList])
    const apiRequest = (params1) => {
        console.log(params1)
        let params = { ...params1 }
        return new Promise(async (resolve) => {
            if (!params1) {
                params.page = 1
                params.results = 20
                setState({ ...state, isFilter: false })
            }
            setState({
                ...state,
                dataSearchParams: params
            })
            let filter = { ...params }
            dispatch(CollectionCountList(filter))

        })
    }
    const handleSearch = (selectedKeys, confirm) => {
        confirm();
        setState({ ...state, searchText: selectedKeys[0] });
    };
    const getColumnSearchProps = (dataIndex, index) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    id={`input-${dataIndex}`}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    id={`search-${dataIndex}`}
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm)}
                    icon="search"
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
            </Button>
                <Button id={`reset-${dataIndex}`} onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reset
            </Button>
            </div>
        ),
        filterIcon: filtered => (
            <Icon id={`filter-menu-${dataIndex}`} style={{ color: filtered ? '#1890ff' : undefined }} type='search' />
        ),
        onFilter: (value, record) => {
            return index ?
                (
                    record[dataIndex][index]
                        .toString()
                        .toLowerCase()
                        .includes(value.toLowerCase())

                ) :
                (
                    record[dataIndex]
                        .toString()
                        .toLowerCase()
                        .includes(value.toLowerCase())

                )
        },
        render: text => (
            <Highlighter
                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                searchWords={[state.searchText]}
                autoEscape
                textToHighlight={text.toString()}
            />
        ),
    });

    const handleReset = clearFilters => {
        clearFilters();
        setState({ ...state, searchText: '' });
    };
    const handleChange = (params) => {
        console.log(params)
        apiRequest(params)
    }
    console.log(collectionCountList)
    const columns = [
        {
            title: 'Company Name',
            dataIndex: 'companyId.name',
            key: 'companyId.name',
            fixed: 'left',
            width: 150,
            ...getColumnSearchProps('companyId','name')

        },
        {
            title: 'Total Collection',
            dataIndex: 'collectionCount',
            key: 'collectionCount',
            align: 'center',
            // defaultSortOrder: 'ascend',
            // sorter: (a,b) => a.collectionCount - b.collectionCount
        },
        {
            title: 'E-mail',
            dataIndex: 'companyId.email',
            key: 'companyId.email',
            align: 'center',
            ...getColumnSearchProps('companyId','email')
        },


        {
            title: 'Created On',
            dataIndex: 'companyId.createdOn',
            key: 'companyId.createdOn',
            align: 'center',
            render: (val, record) => {
                return <Tag>{moment(val).format(timeStampFormat)}</Tag>

            }
            // ...this.getColumnSearchProps('branchArray')
        },
    ]
    return (
        <Card title={`Collection Count Results (${state.total == undefined ? 0 : state.total})`}>

            <Table
                sortDirections={[]}
                onChange={(params) => handleChange(params)}
                bordered={true}
                ref={table}
                size='small'
                loading={collectionCountLoader}
                columns={columns}
                dataSource={collectionCountList.data}
                pagination={{
                    total: state.total,
                    ...pagination,
                    defaultPageSize: 10,
                    pageSizeOptions: ['10', '25', '50', '100', '500'],
                    showSizeChanger: true
                }}
            />
        </Card>
    )
}

export default AllCollectionCount