export default (
    state = {
        testingUserLoader: false,
        testingUserList: { data: [] },
        testingUserResp: {},
        testingUserEdit: {}
    },
    action
) => {
    switch (action.type) {
        case "TESTING_USER_SHOW_LOADER":
            return { ...state, testingUserLoader: true };
        case "TESTING_USER_HIDE_LOADER":
            return { ...state, testingUserLoader: false };
        case "TESTING_USER_CARD_SHOW_LOADER":
            return { ...state, testingUserCardLoader: true };
        case "TESTING_USER_CARD_HIDE_LOADER":
            return { ...state, testingUserCardLoader: false };
        case "TESTING_USER_LIST":
            return { ...state, testingUserList: action.payload };
        case "TESTING_USER_RESPONSE":
            return { ...state, testingUserResp: action.payload };
        case "TESTING_USER_EDIT_RESPONSE":
            return { ...state, testingUserEdit: action.payload };
        case "TESTING_USER_ERROR_CHECKER":
            return { ...state, addedSuccessfully: false };
        case "TESTING_USER_SUCCESS_CHECKER":
            return { ...state, addedSuccessfully: true };
        default:
            return state;
    }
};
