import axios from "axios";
import { registerUrl } from "../api/registerUrl";
import { notification, message } from 'antd'
import { useSelector } from "react-redux";
import { getToken } from "../../../request";
import { push } from "connected-react-router";
export const register = (inputData) => async (dispatch) => {
    dispatch({ type: 'REGISTER_SHOW_LOADER' });
    await axios
        .post(registerUrl(), inputData)
        .then(function ({ data }) {
            dispatch({ type: 'REGISTER_HIDE_LOADER' });

            if (!data.error) {
                dispatch({ type: 'REGISTER_SUCCESS_CHECKER' });

                return notification.success({
                    message: 'Registeration Successful',
                    description: data.message
                })

            } else {
                dispatch({ type: 'REGISTER_ERROR_CHECKER' });

                notification.error({
                    message: 'Signing up failed',
                    description: data.message
                })
            }
        })
        .catch(function (error) {
            console.log(error)
            // toast.error(JSON.stringify(error));
            dispatch({ type: 'REGISTER_HIDE_LOADER' });
            dispatch({ type: 'REGISTER_ERROR_CHECKER' });

        });
};