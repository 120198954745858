import React, { PureComponent } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
    Form,
    Input,
    Select,
    Button,
    Radio,
    Col,
    Row,
    Card,
    Switch,
    InputNumber,
    DatePicker,
    Tabs,
    Icon,
    Table
} from 'antd'
import _ from 'lodash'


import styles from './styles.less'

import { notification } from 'antd/lib/index'
import { hideLoader, showLoader } from '../../../modules/actions'
import Request from '../../../request'
import { connect } from 'react-redux'
import { createMatchSelector } from 'connected-react-router'
// import mockData from '../MOCK_DATA.json'

const FormItem = Form.Item
const { Option } = Select
const { TabPane } = Tabs
const defaults = [
    { field: 'fat', fieldMin: 3, fieldMax: 10, disabled: true, show: true },
    { field: 'snf', fieldMin: 3, fieldMax: 12, disabled: false, show: true },
    { field: 'clr', fieldMin: 2, fieldMax: 4, disabled: false, show: false },
    { field: 'density', fieldMin: 2, fieldMax: 4, disabled: false, show: false },
    { field: 'lactose', fieldMin: 2, fieldMax: 4, disabled: false },
    { field: 'salts', fieldMin: 2, fieldMax: 4, disabled: false },
    { field: 'protein', fieldMin: 2, fieldMax: 4, disabled: false },
    { field: 'temp', fieldMin: 20, fieldMax: 88, disabled: false },
    { field: 'addedWater', fieldMin: 40, fieldMax: 100, disabled: false },
    { field: 'freezingPoint', fieldMin: 2, fieldMax: 4, disabled: false },
    { field: 'ph', fieldMin: 5.5, fieldMax: 8, disabled: false },
    { field: 'conductivity', fieldMin:6, fieldMax: 12, disabled: false },
    /*{field: 'mode', fieldMin: 2, fieldMax: 4, disabled: false}*/
]

@Form.create()
class milkScannerOptions extends PureComponent {

    state = {
        requiredRule: {
            rules: [{
                required: true,
                message: 'This is required field'
            }]
        },
        dataSource: [],
        settingRecords: _.cloneDeep(defaults),
        reset: false
    }
    columns = [
        {
            title: 'Field',
            key: 'field',
            dataIndex: 'field',
            render: (item, record) => {
                return (
                    <Switch checked={defaults.show} onChange={() => {

                        /*let newData = _.cloneDeep(defaults)
                         newData.allowDelete = !defaults.allowDelete
                         this.setState({ defaults: newData })*/
                    }} />
                )
            }
        },
        {
            title: 'Field',
            key: 'field',
            dataIndex: 'field'
        },
        {
            title: 'Field Max',
            key: 'fieldMax',
            dataIndex: 'fieldMax'
        },
        {
            title: 'Field Min',
            key: 'fieldMin',
            dataIndex: 'fieldMin'
        },
        {
            title: 'Show',
            key: 'show',
            dataIndex: 'show',
            render: (val) => val ? <>True</> : <>False</>
        },
        {
            title: 'Disabled',
            key: 'disabled',
            dataIndex: 'disabled',
            render: (val) => val ? <>True</> : <>False</>
        }
    ]

    constructor(props) {
        super(props)
    }

    componentDidMount() {
        this.apiRequest()
    }

    apiRequest = async () => {
        const { currentUser, form, company } = this.props
        if (company) {
            this.setState({ cardLoading: true })
            let { success, data } = await Request.getMilkAnalyserOptn({ companyId: company })
            if (success && data && data.length) {

                let tempArr = _.clone(defaults)
                _.each(tempArr, (val) => {
                    let findIn = _.find(data, (vv) => {
                        return vv.field == val.field
                    })
                    if (findIn) {
                        val.fieldMin = findIn.fieldMin
                        val.fieldMax = findIn.fieldMax
                        val.show = findIn.show
                    }
                })


                this.setState({ cardLoading: false, settingRecords: tempArr })
            } else {
                this.setState({ cardLoading: false, settingRecords: _.cloneDeep(defaults) })
            }
        } else {
            this.setState({ settingRecords: _.cloneDeep(defaults) })
        }
    }
    handleSubmit = async () => {
        const { currentUser, form } = this.props;
        const { settingRecords } = this.state;


        if (!settingRecords.length) {
            return
        }
        if (settingRecords) {
            let error = false
            _.forEach(settingRecords, (record) => {
                if ((record.field == 'fat' || record.field == 'fat') && typeof (parseFloat(record.fieldMax)) !== 'number' && typeof (parseFloat(record.fieldMin)) !== 'number') {
                    notification.error({ message: 'FAT and SNF are compulsory' })
                    error = true
                }
            })
            if (error) return
        }
        this.setState({ loading: true })
        const apiRequest = await Request.updateMilkAnalyserOptn({
            companyId: currentUser.companyId,
            settingRecords
        })

        this.setState({ loading: false })
        if (!apiRequest.error) {
            notification.success({
                message: 'Success',
                description: apiRequest.message
            })
        } else {
            notification.error({
                message: 'Error',
                description: apiRequest.message
            })
        }
        this.setState({ loading: false })
    }

    updateProps = () => {
        const { updateFormData, submit, form, reset } = this.props
        if (reset !== this.state.reset) {
            this.state.reset = reset
            this.setState({ cardLoading: false, settingRecords: _.cloneDeep(defaults) })
            this.forceUpdate()
        }
        if (submit !== this.state.submit) {
            this.state.submit = submit
            updateFormData('scannerData', this.state.settingRecords)
        }
    }
    getValue = async (headName, e, head) => {
        let { settingRecords } = this.state
        let v = _.find(settingRecords, x => x.field === headName)

        v[head] = e
        this.setState({
            settingRecords,
            key: Math.random() * 100
        })
    }

    render() {
        const { company } = this.props
        this.updateProps()
        const {
            form: { getFieldDecorator, getFieldValue, setFieldsValue }
        } = this.props

        const { id, cardLoading, settingRecords } = this.state
        let tableData = []
        _.forEach(defaults, (data, index) => {
            _.forEach(settingRecords, (val, key) => {
                if (data.field == val.field) {
                    tableData.push(
                        <tr key={val.field}>
                            <td>
                                {/*{val.field !== 'fat' ?
                                 : null}*/}
                                <Switch checked={val.show}
                                    disabled={val.field == 'fat'}
                                    onChange={() => {
                                        let newData = _.cloneDeep(settingRecords)
                                        let valF = _.find(newData, (item) => {
                                            return item.field == val.field
                                        })
                                        if (valF) {
                                            valF.show = !valF.show
                                            this.setState({ settingRecords: newData })
                                        }
                                    }} />
                            </td>
                            <td>{val.field}</td>
                            <td>
                                <InputNumber
                                    value={val.fieldMin}
                                    onChange={e => {
                                        this.getValue(val.field, e, 'fieldMin')
                                    }}
                                />
                            </td>
                            <td>
                                <InputNumber
                                    value={val.fieldMax}
                                    onChange={e => {
                                        this.getValue(val.field, e, 'fieldMax')
                                    }}
                                />
                            </td>
                        </tr>
                    )
                }
            })
        })
        return (
            <Card loading={cardLoading} className={styles.container}>
                <table className={styles.mainTable}>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Head Name</th>
                            <th>Min Value</th>
                            <th>Max Value</th>
                        </tr>
                    </thead>

                    <tbody>
                        {
                            tableData
                        }
                    </tbody>
                </table>
                {/* <Form.Item>
                    <Button type='primary' icon={'save'} loading={loading} onClick={this.handleSubmit}>Save</Button>
                </Form.Item> */}
            </Card>
        )
    }
}

const mapStateToProps = ({ global, router }) => ({
    loading: global.buttonLoading,
    categories: global.categories,
    search: router.location.search,
    currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(milkScannerOptions)
