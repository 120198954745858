import React, { Component } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { TableComp } from 'sz-react-utils-lite'
import moment from 'moment'
import {
    Table,
    Pagination,
    Card,
    Tooltip,
    Tag,
    Popconfirm,
    notification,
    Select,
    Form,
    Input, Button, Icon
} from 'antd'
import Request from '../../../request'
import Color from 'color'
import _ from 'lodash'
import Highlighter from 'react-highlight-words'
import styles from './styles.less'
import { connect } from 'react-redux'
import memoizeOne from 'memoize-one'
import update from 'immutability-helper'
import { getUrlPushWrapper, getPushPathWrapper } from '../../../routes'
import { timeStampFormat } from '../../../settings'


@Form.create()
class AllFarmers extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            pagination: {}
        }

    }
    deleteFarmer = async id => {
        this.setState({ loading: true })
        let x = await Request.deleteFarmer(id)
        this.setState({ loading: false })

        this.apiRequest()
        if (!x.error) {
            notification.success({
                message: x.message,
                btn: <Button type='primary' onClick={() => this.undo(id)}>Undo</Button>,
                key: `${id}-close`
            })

        }
        else {
            notification.error({
                message: x.message,
                duration: 20,
                key: `${id}-close`
            })
        }


    }
    undo = async (id) => {
        const response = await Request.restoreFarmer(id)
        notification.destroy()
        this.apiRequest()
    }
    apiRequest = async (params = {}, isFilter) => {
        this.setState({ loading: true })
        console.log(params)
        let data = await Request.getAllFarmers({
            ...params,
        })
        console.log(data)
        const pagination = { ...this.state.pagination }
        pagination.total = isFilter ? data.length : data.total
        this.setState({ dataSource: data.data, loading: false, pagination })
    }
    mcpChildrens = []
    getMcp = async () => {
        console.log(this.state.selectedCompany)
        let { data } = await Request.getAllmcp({ companyId: this.state.selectedCompany })
            console.log(data)
            this.mcpChildrens=[]   
        if (data) {
            _.forEach(data, (mcc) => {
                this.mcpChildrens.push(
                    <Select.Option value={mcc.id} key={mcc.id}>{mcc.name}</Select.Option>
                )
            })
        }
    }
    companyChildrens = []
    getCompanies = async () => {
        let { data } = await Request.listCompanies()
        if (data) {
            _.forEach(data, (company) => {
                this.companyChildrens.push(
                    <Select.Option value={company.id} key={company.id}>{company.name}</Select.Option>
                )
            })
        }
    }
    handleTableChange = (pagination, filters, sorter) => {
        console.log(filters, pagination)
        const pager = { ...this.state.pagination }
        pager.current = pagination.current
        this.setState({
            pagination: pager,
            filteredInfo: { ...filters }
        })
        if (this.state.selectedMcp) filters.mcpId = this.state.selectedMcp
        this.apiRequest({
            sortField: sorter.field,
            sortOrder: sorter.order,
            ...filters
        })
    }
    componentDidMount() {
        this.getCompanies()
      
        this.apiRequest()
    }
    getColumnSearchProps = (dataIndex, index) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    className={`search-value-${dataIndex}`}
                    ref={node => {
                        this.searchInput = node
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    className={`search-${dataIndex}`}
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm)}
                    icon="search"
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
        </Button>
                <Button className={`reset-${dataIndex}`} onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reset
        </Button>
            </div>
        ),
        filterIcon: filtered => (
            <Icon className={`filter-menu-${dataIndex}`} type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) => {
            return index ?
                (
                    record[dataIndex] && record[dataIndex][index] && record[dataIndex][index]
                        .toString()
                        .toLowerCase()
                        .includes(value.toLowerCase())

                ) :
                (
                    record[dataIndex] && record[dataIndex]
                        .toString()
                        .toLowerCase()
                        .includes(value.toLowerCase())

                )
        },
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select())
            }
        },
        render: text => (
            text && <Highlighter
                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                searchWords={[this.state.searchText]}
                autoEscape
                textToHighlight={text.toString()}
            />
        )
    })
    handleSearch = (selectedKeys, confirm) => {
        confirm()
        this.setState({ searchText: selectedKeys[0] })
    }
    handleReset = clearFilters => {
        clearFilters()
        this.setState({ searchText: '' })
    }
    onSelectChange = (val) => {
        this.state.selectedMcp = val
        this.apiRequest({  companyId:this.state.selectedCompany,mcpId: val }, true)
    }
    onSelectChangeCompany = (val) => {
        this.state.selectedCompany = val
        this.getMcp()
        this.apiRequest({ companyId: val }, true)
        this.props.form.resetFields('mcp')
    }
    onReset = () => {
        this.props.form.resetFields()
        this.state.selectedMcp = null
        this.state.selectedCompany = null
        this.apiRequest()
    }
    render() {
        const { submitting, dispatch, form: { getFieldDecorator } } = this.props
        const { dataSource, loading, pagination } = this.state

        const columns = [
            // {
            //     title: 'id',
            //     dataIndex: 'id',
            //     key: 'id',
            //     ...this.getColumnSearchProps('id')
            // },
            {
                title: 'Name',
                dataIndex: 'farmerName',
                key: 'farmerName',
                // ...this.getColumnSearchProps('farmerName')
            },
            {
                title: 'Mobile',
                dataIndex: 'phone',
                key: 'phone',
                ...this.getColumnSearchProps('phone')
            },
            // {
            //     title: 'Company Id',
            //     dataIndex: 'companyId',
            //     key: 'companyId',
            //     // ...this.getColumnSearchProps('farmerName')
            // },
            {
                     title: 'Company Name',
                     dataIndex: 'companyId.name',
                     key: 'companyId',
                    
            },
            {
                title: 'Mcp Name',
                dataIndex: 'mcpId.name',
                key: 'mcpIdname',
                // ...this.getColumnSearchProps('farmerName')
            },
            {
                title: 'Created At',
                dataIndex: 'createdOn',
                key: 'createdOn',
                render: (val, record) => {
                    return <Tag>{moment(record.time).format(timeStampFormat)}</Tag>
                }
            },
            {
                title: 'Updated At',
                dataIndex: 'updatedOn',
                key: 'updatedOn',
                render: (val, record) => {
                    return <Tag>{moment(record.time).format(timeStampFormat)}</Tag>
                }
            },
            {
                title: 'Created By',
                dataIndex: 'createdOn',
                key: 'createdOn',
                render: (val, record) => {
                    return <Tag>{moment(record.time).format('YYYY-MMM-DD')}</Tag>
                }
            },


            // {
            //     key: 'actions',
            //     title: 'Actions', fixed: 'right',
            //     width: 150,
            //     render: (text, record) => {
            //         return <React.Fragment>
            //             <Tooltip title="Edit Farmer">
            //                 <Button
            //                     shape="circle"
            //                     style={{ marginRight: 6 }}
            //                     size="small"
            //                     onClick={() => {
            //                         dispatch(
            //                             getPushPathWrapper('farmer.editFarmer', {
            //                                 id: record.id,
            //                                 companyId:record.companyId
            //                             })
            //                         )
            //                     }}
            //                     icon="edit"
            //                 />
            //             </Tooltip>

            //             <Tooltip title="Delete Farmer">
            //                 <Popconfirm
            //                     title="Are you sure delete this Farmer?"
            //                     onConfirm={() => {
            //                         this.deleteFarmer(record.id)
            //                     }}
            //                     onCancel={() => {
            //                     }}
            //                     okText="Yes"
            //                     cancelText="No">
            //                     <Button
            //                         shape="circle"
            //                         size="small"
            //                         type='danger'
            //                         icon="delete"
            //                     />
            //                 </Popconfirm>

            //             </Tooltip>

            //         </React.Fragment>

            //     }
            // }

        ]
        return (
            <PageHeaderWrapper
                title={'All Farmers'}>

                <Card bordered={true}>
                    <div style={{ marginBottom: '10px' }} className={styles.filter}>
                        <div>
                            <Form layout={'inline'}>
                                <Form.Item>
                                    {getFieldDecorator('company')(
                                        <Select
                                            style={{ width: '200px' }}
                                            placeholder='Company Filter'
                                            onChange={this.onSelectChangeCompany}
                                        >
                                            {this.companyChildrens}
                                        </Select>
                                    )}
                                </Form.Item>
                                <Form.Item>
                                    {getFieldDecorator('mcp')(
                                        <Select
                                            style={{ width: '200px' }}
                                            disabled={!this.state.selectedCompany}
                                            placeholder='MCP Filter'
                                            onChange={this.onSelectChange}
                                        >
                                            {this.mcpChildrens}
                                        </Select>
                                    )}
                                </Form.Item>
                            </Form>
                        </div>
                        <Button onClick={this.onReset}>Reset</Button>
                    </div>

                    <Table bordered
                        size='small'
                        columns={columns}
                        // onChange={this.handleTableChange}
                        dataSource={dataSource}
                        loading={loading}
                        pagination={pagination} />
                </Card>

            </PageHeaderWrapper>)

    }
}


const mapStateToProps = ({ global }) => ({
    categories: global.categories,
    currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AllFarmers)
