import React, { PureComponent } from 'react'
import styles from './styles.less'

import _ from 'lodash'

import moment from 'moment'
import { FormUtils as GetAllFormFields } from 'sz-react-utils-lite'
//import GetAllFormFields from '../_utils/formUtils';


import { Card, Form } from 'antd'
import Request, { API_URL } from '../../../request'
import { connect } from 'react-redux'
const defaultValues = {
    tear: false,
    autoTear: false,
    showTearButton: false,
    analyser: 'lactoscan',
    scale: 'simple'
}

@Form.create()
class HardwareSettings extends PureComponent {

    state = {
        cardLoading: false,
        loading: false,
        reset: false
    }

    componentDidMount() {
        this.apiRequest()
    }
    updateProps = () => {
        const { updateFormData, reset, submit, form, formFields } = this.props
        if (reset !== this.state.reset) {
            this.state.reset = reset
            form.setFieldsValue(defaultValues)
        }
        if (submit !== this.state.submit) {
            this.state.submit = submit
            updateFormData('hardwareSettings', form.getFieldsValue())
        }
        if (formFields && (JSON.stringify(formFields) != JSON.stringify(this.state.formFields))) {
            this.state.formFields = formFields
            form.setFieldsValue(formFields)
        }
    }
    apiRequest = async () => {
        const { form, formFields, company, updateFormData } = this.props
        if (company && !formFields) {
            this.setState({ cardLoading: true })
            let filter = { level: 'COMPANY', companyId: company }

            let data = await Request.getMachineSettings(filter)

            this.setState({ cardLoading: false })
            if (data && data.data.length) {
                let obj = {}
                form.setFieldsValue({ tear: true })
                _.forEach(data.data, (setting) => {
                    if (setting.type === 'printerParams') {
                        _.forEach(setting.value, (param, key) => {
                            obj[_.camelCase(`print ${key}`)] = param
                        })
                    }
                    if (setting.type === 'printerHeaderFooter') {
                        obj.printHeader = setting.value.header || ''
                        obj.printFooter = setting.value.footer || ''
                    }
                    else
                        obj[setting.type] = setting.value || setting.enabled

                })
                updateFormData('machineSettings', obj);
                form.setFieldsValue(obj)
            }
            else {
                form.setFieldsValue(defaultValues)
            }
        }
        else
            form.setFieldsValue(formFields || defaultValues)

    }

    render() {
        const { cardLoading } = this.state
        const { settingLevel, isCompany } = this.props
        const {
            form: { getFieldDecorator, getFieldValue, setFieldsValue },
            setInputFields
        } = this.props
        this.updateProps()


        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
                md: { span: 8 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
                md: { span: 12 }
            }
        }


        const inputType = {
            fields: [
                {
                    label: 'Bluetooth Settings',
                    key: 'analyser',
                    dataIndex: 'analyser',
                    type: 'select',
                    required: true,
                    options: [
                        {
                            display: 'Lacto Scan',
                            id: 'lactoscan',
                        },
                        {
                            display: 'Printer',
                            id: 'printer',
                        },
                        {
                            display: 'Essae',
                            id: 'essae',
                        },
                    ],
                    onChange: (analyser) => {
                        this.props.form.setFieldsValue({ analyser })
                    }
                },
                {
                    label: 'Scale Settings',
                    key: 'scale',
                    dataIndex: 'scale',
                    type: 'select',
                    required: true,
                    options: [
                        {
                            display: 'Simple',
                            id: 'simple',
                        },
                        {
                            display: 'Matiz',
                            id: 'matiz',
                        },
                    ],
                    onChange: (scale) => {
                        console.log('scale', scale)
                        this.props.form.setFieldsValue({ scale })
                    }
                },
                {
                    label: 'Scale Tear',
                    key: 'tear',
                    dataIndex: 'tear',
                    type: 'switch',
                    onChange: (tear) => {
                        setFieldsValue({ tear }, () => {
                            // setFieldsValue({ tearValue: 'T', showTearButton: false, autoTear: false })
                        })
                    }
                },
                {
                    label: 'Scale Tear Value',
                    key: 'tearValue',
                    defaultValue: false,
                    // dataIndex: 'tearValue',
                    hidden: !getFieldValue('tear')
                },
                {
                    label: 'Show Tear Button',
                    key: 'showTearButton',
                    dataIndex: 'showTearButton',
                    type: 'switch',
                    defaultValue: false,
                    hidden: !getFieldValue('tear'),
                    onChange: (showTearButton) => {
                        setFieldsValue({ showTearButton })
                    }
                },
                {
                    label: 'Auto Tear While Saving',
                    key: 'autoTear',
                    dataIndex: 'autoTear',
                    type: 'switch',
                    defaultValue: false,
                    hidden: !getFieldValue('tear'),
                    onChange: (autoTear) => {
                        setFieldsValue({ autoTear })
                    }
                },
            ]
        }
        return (
            <Card loading={cardLoading} className={styles.container}>
                <GetAllFormFields
                    apiurl={API_URL}
                    inputSchema={inputType}
                    formItemLayout={formItemLayout}
                    getFieldDecorator={getFieldDecorator}
                />
            </Card>

        )
    }
}

const mapStateToProps = ({ global, router }) => ({
    loading: global.buttonLoading,
    categories: global.categories,
    search: router.location.search,
    currentUser: global.currentUser,
    pathname: router.location.pathname

})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HardwareSettings)
