import React, { useEffect, useState, useRef } from 'react'
import {
    notification,
    Table,
    Button,
    Spin,
    Form,
    Drawer, Card, Tag,
    Select,
    Tooltip,
    Popconfirm,
    Switch
} from 'antd'
import { TableComp } from 'sz-react-utils-lite'
import _ from 'lodash'
import FormItem from '../../../components/FormItem'
import moment from 'moment'
import { dateFormat, timeStampFormat } from '../../../settings'
import Request, { API_URL } from '../../../request'
import { FormUtils as GetAllFormFields } from 'sz-react-utils-lite'
import { UpdateTestingUser, GetTestingUserById } from '../actions/testingUserAction'
import { useSelector, useDispatch, connect } from 'react-redux'
import { editTestingUserUrl } from '../api/testingUserUrl'
import { getUrlParams } from '../../../routes'


const AddTestingUser = (props) => {
    const dispatch = useDispatch()
    const [state, setState] = useState({
        edit: false
    })
    console.log(props.pathname)
    const data = getUrlParams('testingUser.editTestingUser', props.pathname)
    console.log(data)

    const { testingUserLoader, currentUser, testingUserEdit, testingUserCardLoader } = useSelector((state) => ({
        testingUserEdit: state.testingUser.testingUserEdit,
        testingUserLoader: state.testingUser.testingUserLoader,
        testingUserCardLoader: state.testingUser.testingUserCardLoader,
        currentUser: state.global.currentUser
    }))
    console.log(testingUserEdit)

    useEffect(() => {
        console.log(data)
        if (data && data.id) {
            dispatch(GetTestingUserById(data.id))
        }
    }, [])
    useEffect(() => {
        const { form } = props
        if (testingUserEdit && testingUserEdit.data) {
            form.setFieldsValue({
                name: testingUserEdit && testingUserEdit.data.name,
                mobile: testingUserEdit && testingUserEdit.data.mobile,
                enableDisable: testingUserEdit && testingUserEdit.data.enableDisable
            })
        }
    }, [testingUserEdit])



    const handleSubmit = (e, isEdit = false) => {
        e.preventDefault()
        const { form } = props

        form.validateFieldsAndScroll(async (err, valData) => {
            console.log(valData, err)
            if (!err) {

                // dispatch(showLoader())
                if (!currentUser) {
                    return notification.error({
                        message: 'Error Saving',
                        description: 'Try Again'
                    })
                }

                // if (isEdit) {
                //     valData.name = valData.ipAddress1
                //     valData.companyId = valData.companyId1
                //     delete valData.ipAddress1
                //     delete valData.companyId1
                // }


                // setBtnLoading(true)
                if (!isEdit) dispatch(UpdateTestingUser(data.id, valData))
                // form.resetFields()
                // else
                // dispatch(UpdateIpAction(record.id, valData))
                // setBtnLoading(false)
            } else {

                notification.warning({
                    message: 'Fill All Required Fields'
                })
            }
        })
    }






    const {
        form: { getFieldDecorator }, form
    } = props


    const submitFormLayout = {
        wrapperCol: {
            xs: { span: 24, offset: 0 },
            sm: { span: 10, offset: 7 },
            md: { span: 12, offset: 8 }
        }
    }
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
            md: { span: 8 }
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
            md: { span: 12 }
        }
    }
    const validateRule = {
        rules: [{ required: true, message: 'Required field' }]
    }
    const fIAll = {
        getFieldDecorator,
        validateRule
    }
    return (
        <Card title={'Edit Testing User'} loading={testingUserCardLoader}>
            <Form onSubmit={handleSubmit} style={{ marginTop: 8 }}>


                <FormItem
                    {...fIAll}
                    {...formItemLayout}
                    name='name'
                />
                <FormItem
                    {...fIAll}
                    {...formItemLayout}
                    label={'Mobile No.'}
                    inputType={'number'}
                    name='mobile'
                />
                <Form.Item {...formItemLayout} label={'Enable/Disable'}>
                    {getFieldDecorator('enableDisable')(
                        <Switch checked={form.getFieldValue('enableDisable')}></Switch>
                    )}
                </Form.Item>
                <Form.Item {...submitFormLayout} style={{ marginTop: 32 }}>
                    <Button
                        type="primary"
                        htmlType="submit"
                        loading={testingUserLoader}
                    >
                        SAVE
                            </Button>
                </Form.Item>
            </Form>
        </Card>





    )
}
const mapStateToProps = ({ global, router }) => ({
    search: router.location.search,
    pathname: router.location.pathname,
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}
const WrappedComponent = Form.create()(AddTestingUser)
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WrappedComponent)
// export default WrappedComponent