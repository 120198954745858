export default (
    state = {
        collectionCountLoader: false,
        collectionCountList: {},
    },
    action
) => {
    switch (action.type) {
        case "COLLECTION_COUNT_SHOW_LOADER":
            return { ...state, collectionCountLoader: true };
        case "COLLECTION_COUNT_HIDE_LOADER":
            return { ...state, collectionCountLoader: false };
        case "COLLECTION_COUNT_LIST":
            return { ...state, collectionCountList: action.payload };
        
        default:
            return state;
    }
};
