import React, { PureComponent } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
  Form,
  Input,
  Select,
  Button,
  Radio,
  Col,
  Row,
  Card,
  Switch,
  Cascader,
  InputNumber,
  DatePicker,
  Tabs,
  Icon,
  Upload,
  message
} from 'antd'
import { ChromePicker } from 'react-color'
import _ from 'lodash'
import moment from 'moment'
import { FormUtils as GetAllFormFields } from 'sz-react-utils-lite'
// import {FormUtils as GetAllFormFields} from '../../../components/_utils/formUtils'
import { API_URL } from '../../../request'

import styles from './styles.less'
import 'react-quill/dist/quill.snow.css'
import { notification } from 'antd/lib/index'
import { hideLoader, showLoader } from '../../../modules/actions'
import Request from '../../../request'
import { connect } from 'react-redux'
import { createMatchSelector } from 'connected-react-router'
// import mockData from '../MOCK_DATA.json'

const { Option } = Select
const { TabPane } = Tabs



@Form.create()
class FarmerSettings extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      cardLoading: false,
      data: {},
      formFields: null,
      reset: false
    }
  }
  componentDidMount() {
    this.apiRequest()
  }

  handleSubmit = e => {
    const { dispatch, form, company } = this.props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      console.log(valData, 'handleSubmit')
      dispatch(showLoader())

      let submitionData = valData
      const { rateChartAuthority } = this.state
      if (!this.state.edit) {
        submitionData = {
          ...valData,
          rateChartAuthority: rateChartAuthority
        }
      }
      const apiRequest = await Request.updateSetting(
        this.state.data.id,
        submitionData
      )
      console.log(apiRequest)
      dispatch(hideLoader())

      if (!apiRequest.error) {
        notification.success({
          message: 'Success',
          description: apiRequest.message
        })
        this.props.changeTab('3')
      } else {
        notification.error({
          message: 'Error',
          description: apiRequest.message
        })
      }
    })
  }

  handleChange = e => {
    this.setState({
      value: e.target.value,
    });
  };
  handleUpload = async (fileList) => {
    // const { fileList } = this.state;
   
    const formData = new FormData();
    fileList.forEach(file => {
      formData.append('files', file, `${file.name}`);
    });

    this.setState({
      uploading: true,
    });
    console.log(fileList, formData.getAll('files'))
    const data = await Request.upload(formData)
    console.log(data)
    this.setState({
      uploading: false,
      fileList: []
    });
    return data.files
  };
  apiRequest = async () => {
    const { currentUser, form, company } = this.props
    if (!company) return false
    this.setState({ cardLoading: true })
    let data = await Request.getSetting(company)
    this.setState({ cardLoading: false, data: data.data })
    form.setFieldsValue(data.data)
  }

  onSelectChange = (key, val) => {
    this.setState({ [key]: val })
  }
  updateProps = () => {
    const { updateFormData, reset, submit, form } = this.props
    console.log(submit, this.props)
    if (reset !== this.state.reset) {
      this.state.reset = reset
      this.state.data = {}
      form.resetFields()
    }
    if (submit !== this.state.submit) {
      this.state.submit = submit
      updateFormData('settingsData', form.getFieldsValue())
    }
  }
  handleChange = async (info) => {
    console.log("asdassa", info)
    const formData = new FormData();
    info.fileList.forEach(file => {
      console.log(file)
        formData.append('files', file, `${file.name}`);
    });
    console.log("aaaaaaaaaaaa",formData)

    if (info.file.status !== 'uploading') {
      this.setState({ fileList:  formData})
      let logo = await Request.upload(formData)
      console.log("asdasd", logo)
    }

    if (info.file.status === 'done') {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  }
  render() {
    const { company, reset, form } = this.props
    this.updateProps()
    const {
      form: { getFieldDecorator, getFieldValue, setFieldsValue }
    } = this.props

    const props = {
      action: `${API_URL}/upload/file`,
      multiple: false,
      onRemove: file => {
        this.setState(state => {
          const index = state.fileList.indexOf(file);
          const newFileList = state.fileList.slice();
          newFileList.splice(index, 1);
          return {
            fileList: newFileList,
          };
        });
      },
      beforeUpload: file => {
        this.setState(state => ({
          fileList: [...state.fileList, file],
        }));
        return false;
      },
      fileList,
    };
    const { data, cardLoading, requiredRule, fileList } = this.state

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
        md: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
        md: { span: 12 }
      }
    }

    const submitFormLayout = {
      wrapperCol: {
        xs: { span: 24, offset: 0 },
        sm: { span: 10, offset: 7 },
        md: { span: 12, offset: 8 }
      }
    }

    const rateChartOptions = [
      {
        value: 'AT COMPANY',
        label: 'AT COMPANY',
        children: [
          {
            value: 'COMPANY',
            label: 'COMPANY'
          },
          {
            value: 'MCP GROUP',
            label: 'MCP GROUP'
          },
          {
            value: 'MCP',
            label: 'MCP'
          }
        ]
      },
      {
        value: 'AT MCP',
        label: 'AT MCP'
      }
    ]

    return (
      <Card loading={cardLoading} className={styles.container}>
        <Form {...formItemLayout} onSubmit={this.handleSubmit}>
          <Form.Item label={'Collection Mode'}>
            {getFieldDecorator('collectionMode', {
              ...requiredRule,
              initialValue: 'AUTOMATIC'
            })(
              <Radio.Group buttonStyle="solid">
                <Radio.Button value="AUTOMATIC">Automatic</Radio.Button>
                <Radio.Button value="MANUAL">Manual</Radio.Button>
              </Radio.Group>
            )}
          </Form.Item>
          {getFieldValue('collectionMode') == 'MANUAL' && <>
            <Form.Item label={'Manual Scale'}>
              {getFieldDecorator('manualScale')(
                <Switch
                  checked={data && data.manualScale}
                  onChange={() => {
                    let newData = _.cloneDeep(data)
                    newData.manualScale = !data.manualScale
                    this.setState({ data: newData })
                  }}
                />
              )}
            </Form.Item>
            <Form.Item label={'Manual Analyzer'}>
              {getFieldDecorator('manualAnalyser')(
                <Switch
                  checked={data && data.manualAnalyser}
                  onChange={() => {
                    let newData = _.cloneDeep(data)
                    newData.manualAnalyser = !data.manualAnalyser
                    this.setState({ data: newData })
                  }}
                />
              )}
            </Form.Item>
          </>
          }
          <Form.Item label={'Date Format'}>
            {getFieldDecorator('dateFormat', {
              ...requiredRule,
              initialValue: 'DD-MM-YY'
            })(
              <Select
                style={{ width: '180px' }}
                placeholder="Choose Date Format">
                <Option value="DD-MM-YY">DD/MM/YY</Option>
                <Option value="MM-DD-YY">MM/DD/YY</Option>
              </Select>
            )}
          </Form.Item>
          <Form.Item label={'Allow Delete'}>
            {getFieldDecorator('allowDelete')(
              <Switch
                checked={data && data.allowDelete}
                onChange={() => {
                  let newData = _.cloneDeep(data)
                  newData.allowDelete = !data.allowDelete
                  this.setState({ data: newData })
                }}
              />
            )}
          </Form.Item>
          <Form.Item label={'Measurement'}>
            {getFieldDecorator('measurement', {
              ...requiredRule,
              initialValue: 'Lt'
            })(
              <Radio.Group buttonStyle="solid">
                <Radio.Button value="Kg">Kg</Radio.Button>
                <Radio.Button value="Lt">Litre</Radio.Button>
              </Radio.Group>
            )}
          </Form.Item>
          <Form.Item label={'Language'}>
            {getFieldDecorator('language', {
              ...requiredRule,
              initialValue: 'en'
            })(
              <Select style={{ width: '180px' }} placeholder="Choose language">
                <Option value="en">English</Option>
                <Option value="hi">Hindi</Option>
              </Select>
            )}
          </Form.Item>
          <Form.Item label={'Clr Settings'}>
            {getFieldDecorator('clrFormula', {
              ...requiredRule,
              initialValue: '4(Snf-0.20(Fat%)-0.29)'
            })(
              <Select
                style={{ width: '180px' }}
                placeholder="Choose Date Format">
                <Option value='4(Snf-0.20(Fat%)-0.29)'>4(Snf-0.20(Fat%)-0.29)</Option>
                <Option value='4(Snf-0.21(Fat%)-0.36)'>4(Snf-0.21(Fat%)-0.36)</Option>
                <Option value='4(Snf-0.25(Fat%)-0.44)'>4(Snf-0.25(Fat%)-0.44)</Option>
                <Option value='4(Snf-0.20(Fat%)-0.36)'>4(Snf-0.20(Fat%)-0.36)</Option>
                <Option value='4(Snf-0.20(Fat%)-0.66)'>4(Snf-0.20(Fat%)-0.66)</Option>
                <Option value='4(Snf-0.20(Fat%)-0.70)'>4(Snf-0.20(Fat%)-0.70)</Option>
                <Option value='4(Snf-0.25(Fat%)-0.35)'>4(Snf-0.25(Fat%)-0.35)</Option>
                <Option value='4(Snf-0.20(Fat%)-0.14)'>4(Snf-0.20(Fat%)-0.14)</Option>
              </Select>
            )}
          </Form.Item>
          <Form.Item label={'Auto Accept Time'}>
            {getFieldDecorator('autoAcceptTime', {
              ...requiredRule,
              initialValue: '10'
            })(
              <Select style={{ width: '180px' }} placeholder="Choose timer">
                <Option value="5">5</Option>
                <Option value="10">10</Option>
                <Option value="15">15</Option>
                <Option value="20">20</Option>
                <Option value="25">25</Option>
              </Select>
            )}
          </Form.Item>
          <Form.Item label={'Weight Stable Time'}>
            {getFieldDecorator('weightStableTime', {
              ...requiredRule,
              initialValue: '10'
            })(
              <Select style={{ width: '180px' }} placeholder="Choose timer">
                <Option value="5">5</Option>
                <Option value="10">10</Option>
                <Option value="15">15</Option>
                <Option value="20">20</Option>
                <Option value="25">25</Option>
              </Select>
            )}
          </Form.Item>
          {!this.props.isEdit && (
            <Form.Item label={'Rate Chart Authority'}>
              {(
                <Cascader
                  style={{ width: '220px' }}
                  options={rateChartOptions}
                  placeholder="Please select"
                  size="default"
                  disabled={true}
                  defaultValue={["AT COMPANY"]}
                  popupPlacement="bottomLeft"
                  onChange={val => this.setState({
                    rateChartAuthority: val[0]
                  }, () => {
                    console.log(this.state, val, "CASCAAAA")
                  })}
                />
              )}
            </Form.Item>
          )}
          {/* <Form.Item {...submitFormLayout}>
            <Button
              type="primary"
              htmlType="submit"
              icon={'save'}
              loading={this.props.loading}
              disabled={company ? false : true}>
              Save
            </Button>
          </Form.Item> */}
        </Form>
      </Card>
    )
  }
}

const mapStateToProps = ({ global, router }) => ({
  loading: global.buttonLoading,
  categories: global.categories,
  search: router.location.search,
  currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FarmerSettings)
