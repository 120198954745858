import React, { PureComponent } from 'react'
import PageHeaderWrapper from '../PageHeaderWrapper'
import styles from './styles.less'

import _ from 'lodash'

import moment from 'moment'
import { FormUtils as GetAllFormFields } from 'sz-react-utils-lite'
import { API_URL } from '../../request'
//import GetAllFormFields from '../_utils/formUtils';
import { getUrlParams } from '../../routes'


import { notification } from 'antd/lib'
import { hideLoader, showLoader } from '../../modules/actions'
import Request from '../../request'
import { connect } from 'react-redux'
const defaultValues = {
  tear: false,
  autoTear: false,
  showTearButton: false,
  analyser: 'lactoscan',
  scale: 'simple'
}
class HardwareSettings extends PureComponent {

  state = {
    cardLoading: false,
    allTemplates: [],
    key: moment(),
    loading: false
  }


  componentDidMount() {
    this.props.setDefaultSettings(this.apiRequest.bind(this))
    this.apiRequest()
  }

  apiRequest = async () => {
    const { form } = this.props
      form.setFieldsValue(defaultValues)
      this.forceUpdate()
  }

  render() {
    const { key, allTemplates } = this.state
    const { settingLevel, isCompany } = this.props
    const {
      form: { getFieldDecorator, getFieldValue, setFieldsValue },
      setInputFields
    } = this.props


    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
        md: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
        md: { span: 12 }
      }
    }


    const inputType = {
      fields: [
        {
          label: 'Bluetooth Settings',
          key: 'analyser',
          dataIndex: 'analyser',
          type: 'select',
          required: true,
          options: [
            {
              display: 'Lacto Scan',
              id: 'lactoscan',
            },
            {
              display: 'Printer',
              id: 'printer',
            },
            {
              display: 'Essae',
              id: 'essae',
            },
          ],
          onChange: (analyser) => {
            this.props.form.setFieldsValue({ analyser })
          }
        },
        {
          label: 'Scale Settings',
          key: 'scale',
          dataIndex: 'scale',
          type: 'select',
          required: true,
          options: [
            {
              display: 'Simple',
              id: 'simple',
            },
            {
              display: 'Matiz',
              id: 'matiz',
            },
          ],
          onChange: (scale) => {
            console.log('scale', scale)
            this.props.form.setFieldsValue({ scale })
          }
        },
        {
          label: 'Scale Tear',
          key: 'tear',
          dataIndex: 'tear',
          type: 'switch',
          onChange: (tear) => {
            setFieldsValue({ tear }, () => {
              // setFieldsValue({ tearValue: 'T', showTearButton: false, autoTear: false })
            })
          }
        },
        {
          label: 'Scale Tear Value',
          key: 'tearValue',
          defaultValue: false,
          // dataIndex: 'tearValue',
          hidden: !getFieldValue('tear')
        },
        {
          label: 'Show Tear Button',
          key: 'showTearButton',
          dataIndex: 'showTearButton',
          type: 'switch',
          defaultValue: false,
          hidden: !getFieldValue('tear'),
          onChange: (showTearButton) => {
            setFieldsValue({ showTearButton })
          }
        },
        {
          label: 'Auto Tear While Saving',
          key: 'autoTear',
          dataIndex: 'autoTear',
          type: 'switch',
          defaultValue: false,
          hidden: !getFieldValue('tear'),
          onChange: (autoTear) => {
            setFieldsValue({ autoTear })
          }
        },
      ]
    }
    setInputFields(inputType.fields.map(field => field.key), 6)
    return (
      <div className={styles.container}>
        {settingLevel === 'COMPANY' && !isCompany ?
          <h4 style={{ color: 'blue' }}>{'NOTE :This MCP has company level settings'}</h4>
          : <GetAllFormFields
            apiurl={API_URL}
            inputSchema={inputType}
            formItemLayout={formItemLayout}
            getFieldDecorator={getFieldDecorator}
          />}
      </div>

    )
  }
}

const mapStateToProps = ({ global, router }) => ({
  loading: global.buttonLoading,
  categories: global.categories,
  search: router.location.search,
  currentUser: global.currentUser,
  pathname: router.location.pathname

})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HardwareSettings)
