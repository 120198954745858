import React, { useEffect } from 'react'
import { Collapse, Descriptions, List, Tooltip } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'
import { ListSettingsAction } from '../action/debugActions'
const { Panel } = Collapse

const SettingCollapse = (props) => {

    const dispatch = useDispatch()
    useEffect(() => {
        if (props.mcpId)
            dispatch(ListSettingsAction(props.mcpId))
    }, [props.mcpId])

    const { settings } = useSelector((state) => ({
        settings: state.debug.settings.data || {}
    }))

    let skipKeys = ['id', 'deletedAt', 'parameterDecimal', 'companyId', 'mcpId', 'createdOn', 'updatedOn', 'mcpGroupId', 'level']
    return (
        <Collapse accordion>
            {_.flatMap(settings, (value, key) => (
                key !== 'machineSettings' ?
                    [<Panel header={_.startCase(key)} key={key} >
                        {key !== 'collectionExportSetting' &&
                            <Descriptions
                                bordered
                                size='small'
                                column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}

                            >
                                {
                                    _.map(value.data, (val, objKey) => (
                                        !skipKeys.includes(objKey) &&
                                        <Descriptions.Item span={3}
                                            label={
                                                <Tooltip title={_.startCase(objKey)}>
                                                    {_.truncate(_.startCase(objKey), { length: 15 })}
                                                </Tooltip>
                                            }
                                        >

                                            {typeof val === 'boolean' ? _.startCase(val.toString()) : val}
                                        </Descriptions.Item>
                                    ))
                                }
                            </Descriptions>
                        }
                        {key == 'collectionExportSetting' &&
                            <List
                                size="small"
                                header={<b>Export Fields</b>}
                                bordered
                                dataSource={value.data ? value.data.fields || [] : []}
                                renderItem={item => <List.Item>{item}</List.Item>}
                            />
                        }
                    </Panel>] :
                    [<Panel header='Hardware Settings' key='hardwareSetting'>
                        <Descriptions
                            bordered
                            size='small'
                            column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}

                        >
                            {
                                _.map(value.data, (val, index) => (
                                    val.type !== 'printer' && val.type !== 'printerParams' && val.type !== 'printerHeaderFooter' &&
                                    <Descriptions.Item span={3}
                                        label={
                                            <Tooltip title={_.startCase(val.type)}>
                                                {_.startCase(val.type)}
                                            </Tooltip>
                                        }
                                    >{val.type === 'tearValue' ? val.value || '' : val.value || _.startCase(val.enabled.toString())}</Descriptions.Item>
                                ))
                            }
                        </Descriptions>
                    </Panel>,
                    <Panel header={'Printer Settings'} key='printerSetting'>
                        <Descriptions
                            bordered
                            size='small'
                            column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}

                        >
                            {
                                _.map(value.data, (val, index) => (
                                    val.type === 'printer' ?
                                        <Descriptions.Item span={3} label={_.startCase(val.type)} >{val.value}</Descriptions.Item> :
                                        val.type === 'printerHeaderFooter' ?
                                            <>
                                                <Descriptions.Item span={3} label='Header' >{val.value.header}</Descriptions.Item>
                                                <Descriptions.Item span={3} label='Footer' >{val.value.footer}</Descriptions.Item>
                                            </> :
                                            val.type === 'printerParams' &&
                                            _.map(val.value, (paramVal, paramKey) => (
                                                <Descriptions.Item span={3} label={paramKey} >{_.startCase(paramVal.toString())}</Descriptions.Item>
                                            ))
                                ))
                            }
                        </Descriptions>
                    </Panel>]
            ))}
        </Collapse >
    )
}

export default SettingCollapse
