import React from 'react'
import { Route, Link, Redirect } from 'react-router-dom'

import styles from './login.less'
import {
  Form, Icon, Input, Button, Checkbox, notification, Drawer, Modal
} from 'antd'

import Request from '../../request'
import { showLoader, hideLoader } from '../../modules/actions'
import { apiUrl } from '../../settings'


import { push } from 'connected-react-router'
import { connect } from 'react-redux'

class NormalLoginForm extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      anchorFlag: true,
      loader: false
    }
  }
  componentDidMount() {
    this.props.dispatch(hideLoader())

  }
  handleSubmit = (e) => {
    const { dispatch } = this.props
    e.preventDefault()


    this.props.form.validateFields(async (err, values) => {
      if (!err) {

        // dispatch(userActions(values))

        dispatch(showLoader())

        let x = await Request.login(values)

        dispatch(hideLoader())

        if (!x.error) {
          if (x.user.userType != 'superAdmin' && x.user.userType != 'subAdmin') {
            notification.error({
              message: 'Invalid Login',
              description: x.message
            })
          }
          else {
            localStorage.setItem('token', x.token)
            localStorage.setItem('user', JSON.stringify(x.user))
            dispatch({
              type: 'SET_CURRENT_USER',
              user: x.user
            })

            setTimeout(() => {
              dispatch(push('/'))
            }, 300)
          }
        } else {
          notification.error({
            message: 'Invalid Login ',
            description: x.message
          })
        }
      }
    })

  }
  resetPassword = () => {
    console.log("12121")
    this.setState({ flag: true }, () => console.log(this.state))
  }
  handleCancel = () => {
    console.log("12121")
    this.setState({ flag: false }, () => console.log(this.state))
  }
  changeOldPassword = async () => {

    this.props.form.validateFields(async (err, values) => {
      this.setState({ loader: true })
      if (!values.email || !values.oldPassword || !values.newPassword) {
        notification.error({
          message: 'Enter Email or Password',
        })
      }
      console.log(values)
      let value = {
        oldPassword: values.oldPassword,
        password: values.newPassword
      }
      let data = await Request.resetPassword(values.email, value);
      if (data.error) {
        notification.error({
          message: 'Cannot reset password',
          description: data.message
        })
        this.setState({ loader: false })
      } else {
        notification.success({
          message: 'Pssword Changed',
          description: data.message
        })
        this.setState({
          flag: false,
          loader: false
        })

      }
    })
  }
  register = (e) => {
    //push to register page
    e.preventDefault()
    this.props.dispatch(push('/addTrialCompany'))

  }
  anchorDisable = (e) => {
    console.log("121")
    this.setState({
      anchorFlag: false
    })
  }
  render() {
    const { getFieldDecorator } = this.props.form
    const { loading } = this.props
    const { flag, anchorFlag, loader } = this.state

    return (
      <>
        {flag && console.log("dwfyd8")}
        <Modal
          visible={flag}
          onCancel={this.handleCancel}
          onOk={this.changeOldPassword}
          confirmLoading={loader}>

          <Form className={styles.main}>
            <Form.Item>
              {getFieldDecorator('oldPassword', {
              })(
                <Input prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Old password" />
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('newPassword', {
              })(
                <Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="password"
                  placeholder=" New Password" />
              )}
            </Form.Item>
          </Form>
        </Modal>)

        <Form onSubmit={this.handleSubmit} className={styles.main}>

          <Form.Item>
            {getFieldDecorator('email', {
              rules: [{ required: true, message: 'Please enter your email address!' }]
            })(
              <Input prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} onChange={() => { this.anchorDisable() }} placeholder="Email address" />
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('password', {
              rules: [{ required: true, message: 'Please input your Password!' }]
            })(
              <Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="password"
                placeholder="Password" />
            )}
          </Form.Item>
          <Button disabled={anchorFlag} style={{ float: 'right' }} onClick={() => { this.resetPassword() }}>Change Password</Button>
          <div className={styles.register}>
              <a style={{ textAlign: 'center' }} onClick={(e) => { this.register(e) }}>Register Trial Company!</a>
              {/* <a style={{ textAlign: 'center', whiteSpace: 'nowrap' }} onClick={(e) => { this.register(e) }}>Register Now!</a> */}
              </div>
          <Button type="primary" loading={loading} htmlType="submit" className={styles.button}>
            Log in
          </Button>
          
        </Form>

      </>
    )
  }
}

const WrappedNormalLoginForm = Form.create()(NormalLoginForm)


const mapStateToProps = ({ counter, global }) => ({
  count: counter.count,
  loading: global.buttonLoading
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WrappedNormalLoginForm)
