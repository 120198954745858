import React, { PureComponent } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
    Form,
    Select,
    Button,
    Card,
    Switch,
    Tabs,
    Input,
    Radio,
    Upload,
    message,
    Divider,
    DatePicker
} from 'antd'
import _ from 'lodash'
import moment from 'moment'
import 'react-quill/dist/quill.snow.css'
import { notification } from 'antd/lib/index'
import { hideLoader, showLoader } from '../../../modules/actions'
import Request from '../../../request'
import { connect } from 'react-redux'
import { getUrlParams } from '../../../routes'
import FormItem from '../../../components/FormItem'
import Setting from '../settings'
import styles from '../settings/styles.less'
import MilkScannerOptions from '../milkScannerOptions'
import AutoMilkTypeSetting from '../settings/autoMilkTypeSetting'
import CollectionExportSetting from '../settings/collectionExportSetting'
import HardwareSetting from '../settings/hardwareSettings'
import PrinterSetting from '../settings/printerSetting'
import FarmerTitle from '../settings/farmerTitle'
import { cognitoRegister } from '../../../utils'
const { Option } = Select
const { TabPane } = Tabs;

@Form.create()
class addCompany extends PureComponent {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            activeTab: '1',
            companyId: false,
            fileList: [],
            reset: false,
        }
    }

    componentDidMount() {
        this.props.form.setFieldsValue({ collectionSms: 'true' })
        let data = getUrlParams('company.updateCompany', this.props.pathname)
        if (data && data.id) {
            this.setState({
                loading: true,
                edit: true,
                id: data.id
            }, () => {
                this.setFormValues(data.id)
            })
        }
    }
    handleSubmit = e => {
        const { dispatch, form } = this.props
        e.preventDefault()
        form.validateFieldsAndScroll((err, valData) => {
            console.log(valData)
            if (!err) {
                this.setState({ isSubmit: true, btnLoading: true }, async () => {
                    if (this.state.settingsData) valData.settings = { ...this.state.settingsData }
                    if (this.state.scannerData) valData.milkScannerOptions = [...this.state.scannerData]
                    if (this.state.autoMilkSetting) valData.autoMilkSetting = { ...this.state.autoMilkSetting }
                    if (this.state.farmerTitle) valData.farmerTitle = { ...this.state.farmerTitle }
                    if (this.state.collectionExportSettings) valData.collectionExportSettings = { ...this.state.collectionExportSettings }
                    if (this.state.hardwareSettings || this.state.printerSettings) valData.machineSettings = this.createMachineSettings({ ...this.state.printerSettings, ...this.state.hardwareSettings })
                    // if (this.state.printerSettings) valData.printerSettings = { ...this.state.printerSettings }
                    if (this.state.logo) valData.logo = this.state.logo
                    if (this.state.printLogo) valData.printLogo = this.state.printLogo
                    console.log(valData, "VALLL")
                    // if(this.state.edit) {
                    //     valData.suspentionTime = moment(valData.suspentionTime)
                    //     console.log(valData)
                    // }

                    let x = this.state.edit ? await Request.updateCompany(this.state.id, valData) : await Request.addCompany(valData)
                    this.setState({ btnLoading: false })
                    // console.log(x)
                    if (!x.error) {
                        // Cognito Register
                        /* if (!this.state.edit) {
                            let cognitoUser = await cognitoRegister({ ...x.data.userObj, password: valData.password })
                            if (cognitoUser.error) {
                                notification.error({
                                    message: 'Error in adding user',
                                    description: cognitoUser.message
                                })
                                return false
                            }
                        } */
                        notification.success({
                            message: x.message
                        })
                        if (!this.state.edit) {
                            this.setState({ reset: !this.state.reset })
                            // this.changeTab('2')
                            this.props.form.resetFields()
                        }
                    }
                    else {
                        notification.error({
                            message: 'Error Saving',
                            description: x.message
                        })
                    }
                })
            }

        })

    }
    createMachineSettings = (valData) => {
        //create machine Settings
        let hardwareSettings = [
            {
                type: 'analyser',
                value: valData.analyser
            },
            {
                type: 'scale',
                value: valData.scale
            },
            {
                type: 'tear',
                enabled: valData.tear
            },
            {
                type: 'tearValue',
                value: valData.tearValue
            },
            {
                type: 'showTearButton',
                enabled: valData.showTearButton
            },
            {
                type: 'autoTear',
                enabled: valData.autoTear
            }
        ]
        let printerSettings = [
            {
                type: 'printer',
                value: valData.printer
            },
            {
                type: 'printerHeaderFooter',
                value: { header: valData.printHeader, footer: valData.printFooter }
            },
            {
                type: 'printerParams',
                value: {
                    farmerNameAndId: valData.printFarmerNameAndId,
                    farmerMobile: valData.printFarmerMobile,
                    quantity: valData.printQuantity,
                    fat: valData.printFat,
                    snf: valData.printSnf,
                    clr: valData.printClr,
                    density: valData.printDensity,
                    lactose: valData.printLactose,
                    salts: valData.printSalts,
                    protein: valData.printProtein,
                    temp: valData.printTemp,
                    addedWater: valData.printAddedWater,
                    freezingPoint: valData.printFreezingPoint,
                    milkType: valData.printMilkType,
                    collectionDate: valData.printCollectionDate,
                    collectionTime: valData.printCollectionTime,
                    rateChartName: valData.printRateChartName,
                    rate: valData.printRate,
                    totalAmount: valData.printTotalAmount,
                    status: valData.printStatus,
                    stage: valData.printStage,
                    timeOfPrint: valData.printTimeOfPrint,
                    incentive: valData.printIncentive,
                }
            }
        ]
        return [...hardwareSettings, ...printerSettings]
    }
    handleReset = () => {
        this.props.form.resetFields()
        this.setState({ reset: true })
    }
    setFormValues = async (id) => {
        this.setState({ loading: true })
        let data = await Request.getCompany(id)
        this.setState({ loading: false, companyId: data.data.id, data: data.data })
        if (data.error || !data.data) {
            notification.error({
                message: 'Something Went Wrong'
            })
            return false;
        }
        console.log(data.data)
        let obj = {
            name: data.data.name,
            contactPerson: data.data.contactPerson,
            email: data.data.email,
            address: data.data.address,
            state: data.data.state,
            city: data.data.city,
            pincode: data.data.pincode,
            mobile: data.data.mobile,
            tinNo: data.data.tinNo,
            gstNo: data.data.gstNo,
            maxMcp: data.data.maxMcp,
            smsSenderId: data.data.smsSenderId,
            maxSmsCredit: data.data.maxSmsCredit,
            collectionSms: data.data.collectionSms,
            suspentionTime: moment(data.data.suspentionTime)

        }

        if (data.data.logo) {
            this.setState({ logo: `data:image/jpeg;base64,${data.data.logo}` })
        }
        if (data.data.printLogo) {
            this.setState({ printLogo: `data:image/jpeg;base64,${data.data.printLogo}` })
        }
        this.props.form.setFieldsValue(obj)
    }
    getFormValues = (key, valData) => {
        this.state[key] = valData
        this.setState({ isSubmit: false })
        console.log(valData)
    }
    changeTab = (key) => {
        console.log(key)
        this.setState({ activeTab: key })
    }
    beforeUpload(file) {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!')
        }
        const isLt2M = file.size / 1024 / 1024 < 2
        if (!isLt2M) {
            message.error('Image must smaller than 2MB!')
        }
        return isJpgOrPng && isLt2M
    }
    getBase64(img, callback) {
        const reader = new FileReader()
        reader.addEventListener('load', () => callback(reader.result))
        reader.readAsDataURL(img)
    }
    setLogo = (logo, key) => {
        let blob = this.b64toBlob(logo)
        this.setState({ [key]: logo })
    }
    b64toBlob(dataURI) {

        var byteString = atob(dataURI.split(',')[1]);
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);

        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: 'image/jpeg' });
    }
    render() {
        const {
            form: { getFieldDecorator, getFieldValue, setFields }
        } = this.props
        const { loading: cardLoading,
            edit,
            companyId,
            activeTab,
            btnLoading,
            reset,
            isSubmit,
            logo,
            printLogo,
            autoMilkType,
            collectionExportSettings,
            machineSettings
        } = this.state
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
                md: { span: 8 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
                md: { span: 12 }
            }
        }

        const submitFormLayout = {
            wrapperCol: {
                xs: { span: 24, offset: 0 },
                sm: { span: 10, offset: 7 },
                md: { span: 12, offset: 8 }
            }
        }
        const validateRule = {
            rules: [{ required: true, message: 'Required field' }]
        }
        const fIAll = {
            getFieldDecorator,
            validateRule
        }
        const fIAll1 = {
            getFieldDecorator
        }
        const props = {
            name: 'logo',
            // action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
            headers: {
                authorization: 'authorization-text',
            },
            multiple: false,
            showUploadList: false,
            component: 'div',
            beforeUpload: this.beforeUpload,
            onChange: (info) => {
                this.getBase64(info.file.originFileObj, (logo) => this.setLogo(logo, 'logo'))

            },
        };
        const props1 = {
            name: 'printLogo',
            // action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
            headers: {
                authorization: 'authorization-text',
            },
            multiple: false,
            showUploadList: false,
            component: 'div',
            beforeUpload: this.beforeUpload,
            onChange: (info) => {
                this.getBase64(info.file.originFileObj, (logo) => this.setLogo(logo, 'printLogo'))
            },
        };
        return (
            <PageHeaderWrapper
                title={this.state.edit ? 'Update Company' : 'Add Company'}
            >

                <Card id={'primaryDetails'} loading={cardLoading} title="Primary Detaitls" bordered={true}>
                    <Form name='form' onSubmit={this.handleSubmit} >

                        <Tabs type="card" onTabClick={(key) => this.setState({ activeTab: key })} activeKey={activeTab}>
                            <TabPane tab='General Details' key='1' forceRender={true}>
                                <div className={styles.container}>
                                    <FormItem
                                        {...fIAll}
                                        {...formItemLayout}
                                        name='name'
                                        label='Company Name'
                                    />
                                    <FormItem
                                        {...fIAll}
                                        {...formItemLayout}
                                        name='contactPerson'
                                        label='Contact Person'
                                    />
                                    <FormItem
                                        {...fIAll1}
                                        {...formItemLayout}
                                        name='tinNo'
                                        label='Tin No.'
                                    />
                                    <FormItem
                                        {...fIAll1}
                                        {...formItemLayout}
                                        name='gstNo'
                                        label='GST No.'
                                    />
                                    <FormItem
                                        {...fIAll}
                                        {...formItemLayout}
                                        name='address'
                                    />
                                    <FormItem
                                        {...fIAll}
                                        {...formItemLayout}
                                        name='state'
                                    />
                                    <FormItem
                                        {...fIAll}
                                        {...formItemLayout}
                                        name='city'
                                    />
                                    <FormItem
                                        {...fIAll}
                                        {...formItemLayout}
                                        inputType='NUMBER'
                                        name='pincode'
                                    />
                                    <FormItem
                                        {...fIAll}
                                        {...formItemLayout}
                                        name='mobile'
                                        label='Mobile No.'
                                    />
                                    <FormItem
                                        {...fIAll}
                                        {...formItemLayout}
                                        inputType='NUMBER'
                                        name='maxMcp'
                                        label='Maximum MCPs'
                                    />
                                    <FormItem
                                        {...fIAll}
                                        {...formItemLayout}
                                        inputType='NUMBER'
                                        name='maxSmsCredit'
                                        label='Maximum SMS Credit'
                                    />

                                    <FormItem
                                        {...fIAll}
                                        {...formItemLayout}
                                        name='smsSenderId'
                                        label='SMS Sender ID'
                                        defaultValue='HTAMCU'
                                    />

                                    <div>
                                        <Form.Item {...formItemLayout} label='Logo'>
                                            <Upload {...props}>
                                                <Button icon='upload'>
                                                    Click to Upload
                                        </Button>
                                            </Upload>
                                            {logo && <Card
                                                className={styles.logoCard}
                                                bodyStyle={{ display: 'none' }}
                                                cover={
                                                    <img
                                                        width={100}
                                                        height={100}
                                                        alt="logo"
                                                        src={logo}
                                                    />
                                                }></Card>}
                                        </Form.Item>

                                    </div>
                                    <div>
                                        <Form.Item {...formItemLayout} label='Print Logo'>
                                            <Upload {...props1}>
                                                <Button icon='upload'>
                                                    Click to Upload
                                        </Button>
                                            </Upload>
                                            {printLogo && <Card
                                                className={styles.logoCard}
                                                bodyStyle={{ display: 'none' }}
                                                cover={
                                                    <img
                                                        width={100}
                                                        height={100}
                                                        alt="printLogo"
                                                        src={printLogo}
                                                    />
                                                }></Card>}
                                        </Form.Item>

                                    </div>
                                    <Form.Item {...formItemLayout} label={'Collection Sms'}>
                                        {getFieldDecorator('collectionSms')(
                                            <Radio.Group buttonStyle="solid">
                                                <Radio.Button value={'true'}>YES</Radio.Button>
                                                <Radio.Button value={'false'}>NO</Radio.Button>
                                            </Radio.Group>
                                        )}
                                    </Form.Item>
                                    {this.state.edit && <Form.Item {...formItemLayout} label='Suspention Time'>{getFieldDecorator('suspentionTime', {
                                        // rules: [{ required: true }],
                                        // initialValue: moment()
                                    })(
                                        <DatePicker  />
                                    )}</Form.Item>}

                                    <Divider />
                                    <FormItem
                                        {...fIAll}
                                        {...formItemLayout}
                                        name='email'
                                        label='E-mail'
                                    />
                                    {!edit && <Form.Item {...formItemLayout} label='Password'>{
                                        getFieldDecorator('password', {
                                            rules: [{
                                                required: !edit,
                                                message: 'Password required'
                                            }]
                                        })(
                                            <Input type='password' />
                                        )
                                    }</Form.Item>}
                                </div>
                            </TabPane>
                            <TabPane tab='General Settings' key='2'>
                                <Setting updateFormData={this.getFormValues} submit={isSubmit} isEdit={edit} company={companyId} reset={reset} changeTab={this.changeTab} />
                            </TabPane>
                            <TabPane tab='Milk Scanner Options' key='3' >
                                <MilkScannerOptions updateFormData={this.getFormValues} submit={isSubmit} reset={reset} isEdit={edit} company={companyId} />
                            </TabPane>
                            <TabPane tab='Farmer Title' key='4' >
                                <FarmerTitle updateFormData={this.getFormValues} submit={isSubmit} reset={reset} isEdit={edit} company={companyId} />
                            </TabPane>
                            <TabPane tab='Auto Milk Type Settings' key='5' >
                                <AutoMilkTypeSetting updateFormData={this.getFormValues} submit={isSubmit} reset={reset} isEdit={edit} company={companyId} />
                            </TabPane>
                            <TabPane tab='Collection Export Settings' key='6' >
                                <CollectionExportSetting updateFormData={this.getFormValues} submit={isSubmit} reset={reset} isEdit={edit} company={companyId} />
                            </TabPane>
                            <TabPane tab='Hardware Settings' key='7' >
                                <HardwareSetting updateFormData={this.getFormValues} submit={isSubmit} reset={reset} isEdit={edit} company={companyId} formFields={machineSettings} />
                            </TabPane>
                            <TabPane tab='Printer Settings' key='8' >
                                <PrinterSetting updateFormData={this.getFormValues} submit={isSubmit} reset={reset} isEdit={edit} company={companyId} formFields={machineSettings} />
                            </TabPane>
                        </Tabs>

                        <Form.Item className={styles.wrapper}>
                            <Button type='primary' htmlType='submit' loading={btnLoading} >{edit ? 'update' : 'Add'}</Button>
                        </Form.Item>
                    </Form>
                </Card>
            </PageHeaderWrapper>
        )
    }
}

const mapStateToProps = ({ global, router }) => ({
    loading: global.buttonLoading,
    categories: global.categories,
    search: router.location.search,
    pathname: router.location.pathname,
    currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(addCompany)
