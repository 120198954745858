import axios from "axios";
import { listTestingUserUrl } from "../api/collectionCountApi";
import { notification } from 'antd'
import { useSelector } from "react-redux";
import { getToken } from "../../../request";


export const CollectionCountList = (inputData) => async (dispatch) => {
    dispatch({ type: 'COLLECTION_COUNT_SHOW_LOADER' });
    await axios
        .get(listTestingUserUrl(), { params: { ...inputData }, ...getToken() })
        .then(function ({ data }) {
            if (data.error) {
                notification.error({
                    message: data.message,
                })
            }
            else
                dispatch({
                    type: 'COLLECTION_COUNT_LIST',
                    payload: data,
                });
            dispatch({ type: 'COLLECTION_COUNT_HIDE_LOADER' });

        })
        .catch(function (error) {
            // toast.error(JSON.stringify(error));
            dispatch({ type: 'COLLECTION_COUNT_HIDE_LOADER' });
        });
};