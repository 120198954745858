import React, { Component } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { getPushPathWrapper } from '../../../routes'
import { TableComp } from 'sz-react-utils-lite'
import {
    Table,
    Badge,
    Card,
    Popconfirm,
    notification,
    Switch,
    Tag,
    Input, Button, Icon,
    Tooltip,
    Drawer,
    Form
} from 'antd'
import Request from '../../../request'
import Color from 'color'
import _ from 'lodash'
import Highlighter from 'react-highlight-words'
import { connect } from 'react-redux'
import memoizeOne from 'memoize-one'
import update from 'immutability-helper'
import styles from './style.less'
import { hideLoader, showLoader } from '../../../modules/actions';
import { Auth } from 'aws-amplify'
import async from 'async'
import capitalize from 'capitalize'
import { cognitoRegister } from '../../../utils'

@Form.create()
class listCompanies extends Component {


    state = {
        tableLoading: false,
        loading: false,
        total: 0
    }


    componentDidMount() {
    }
    registerAllUser = () => {
        return new Promise(async resolve => {
            this.setState({ loading: true })
            let data = await Request.listUsers()
            console.log(data)
            if (!data.error) {
                async.eachLimit(data.data, 10, async (user, done) => {
                    if (!user.companyId)
                        return done()
                    if (user.companyId.id)
                        user.companyId = user.companyId.id
                    let cognitoUser = await cognitoRegister({ ...user, password: '123456' })
                    if (cognitoUser.error) {
                        notification.error({
                            message: 'Error in adding user',
                            description: cognitoUser.message
                        })
                    }
                    done()
                }, () => {
                    this.setState({ loading: false })
                })
            }
        })
    }


    apiRequest = (params, columns) => {

        return new Promise(async resolve => {

            const { selectedCompany } = this.state
            const { currentUser } = this.props

            this.setState({
                dataSearchParams: params
            })
            let filters = {
                ...params,
                details: true,
                regExFilters: ['name', 'email', 'mobile']

            }
            if (selectedCompany) {
                filters.companyId = selectedCompany
            }
            let data = await Request.listUsers(filters)
            console.log(data)
            if (data.error) {
                return resolve({
                    total: 0
                })
            }

            this.setState({
                total: data.total
            })

            resolve(data)
        })
    }
    handleSearch = (selectedKeys, confirm) => {
        confirm();
        this.setState({ searchText: selectedKeys[0] });
    };
    handleSubmit = (e) => {
        const { dispatch, form } = this.props
        e.preventDefault()
        form.validateFieldsAndScroll(async (err, valData) => {
            console.log(valData, this.state.selectedUser.id)
            if (!err) {
                dispatch(showLoader())
                let x = await Request.updateUser(this.state.selectedUser.id, valData)

                if (!x.error) {
                    notification.success({
                        message: x.message
                    })
                    this.setState({
                        childrenDrawer: false,
                        visibles: false
                    })
                    dispatch(hideLoader())
                    this.props.form.resetFields()

                }
                else {
                    dispatch(hideLoader())
                    notification.error({
                        message: 'Error Saving',
                        description: x.message
                    })
                }
            }
        })
    }
    onChildrenDrawerClose = () => {
        this.setState({
            childrenDrawer: false,
        });
    };
    showChildrenDrawer = (data) => {
        this.props.form.setFieldsValue({ email: data.email })
        this.setState({
            selectedUser: data,
            childrenDrawer: true,
        });
    };
    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };
    deleteUser = async (id) => {
        this.setState({ loading: true })
        const response = await Request.deleteUser(id)

        if (!response.error) {
            notification.success({
                message: capitalize.words(response.message)
            })
            this.table.current.reload(true)
        } else {
            notification.error({
                message: 'Error Saving',
                description: capitalize.words(response.message)
            })
        }
    }
    editUser = async (id) => {
        this.props.dispatch(getPushPathWrapper('user.updateUser', { id }))
    }
    render() {
        const { tableLoading, total, loading } = this.state
        const {
            form: { getFieldDecorator }
        } = this.props
        const columns = [
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
                searchTextName: 'name'
            },
            {
                title: 'Company',
                dataIndex: 'companyId.name',
                key: 'company',
                // searchTextName:'companyId.name'
                // ...this.getColumnSearchProps('name'),
            },
            {
                title: 'Address',
                dataIndex: 'address',
                key: 'address',
            },
            {
                title: 'E-mail',
                dataIndex: 'email',
                key: 'email',
                searchTextName: 'email'

                // ...this.getColumnSearchProps('discount'),

            },
            {
                title: 'Mobile',
                dataIndex: 'mobile',
                key: 'mobile',
                searchTextName: 'mobile'

                // ...this.getColumnSearchProps('discount')
            },
            {
                title: 'User Type',
                dataIndex: 'userType',
                key: 'userType',
                // ...this.getColumnSearchProps('discount')
            },
            {
                title: 'Action',
                dataIndex: 'id',
                key: 'action',
                width: 150,
                render: (val, data) => {
                    return (
                        <div>
                            <Button shape="circle" icon="edit" id={'edit'} className={styles.iconBtn} onClick={() => this.editUser(val)} />
                            <Button shape="circle" icon="delete" loading={loading} type='danger' id={'delete'} className={styles.iconBtn} onClick={() => this.deleteUser(val)} />
                            <Tooltip title="change password">
                                <Button shape="circle" icon="eye" loading={loading} id={'delete'} className={styles.iconBtn} onClick={() => this.showChildrenDrawer(data)} />
                            </Tooltip>
                        </div>
                    )
                }
            }
        ];
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
                md: { span: 8 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
                md: { span: 12 }
            }
        }
        const submitFormLayout = {
            wrapperCol: {
                xs: { span: 24, offset: 0 },
                sm: { span: 10, offset: 7 },
                md: { span: 12, offset: 8 }
            }
        }
        return (
            <PageHeaderWrapper
                title={`All Users (${total})`}>
                <Card id={'listUsers'}>
                    {/* <div style={{textAlign: 'right'}}>
                        <Popconfirm
                            title="Are you sure to add users in AWS?"
                            onConfirm={this.registerAllUser}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button loading={loading}>Add all user in AWS Cognito</Button>
                        </Popconfirm>
                    </div> */}
                    <TableComp ref={this.table}
                        columns={columns}
                        apiRequest={params => this.apiRequest(params, columns)}
                        pagination={{
                            showSizeChanger: true,
                            defaultPageSize: 20,
                            pageSizeOptions: ['10', '20', '50', '100', '1000']
                        }}
                    />
                </Card>
                <Drawer
                    title="RESET PASSWORD"
                    width={420}
                    closable={false}
                    onClose={this.onChildrenDrawerClose}
                    visible={this.state.childrenDrawer}
                >
                    <Form
                        {...formItemLayout}
                        onSubmit={this.handleSubmit}
                        hideRequiredMark
                        style={{ marginTop: 8 }}>

                        <Form.Item label='email'>{
                            getFieldDecorator('email')(
                                <Input type='email'
                                    disabled={true}
                                />
                            )
                        }</Form.Item>
                        <Form.Item label='Password'>{
                            getFieldDecorator('password', {
                                rules: [{ required: true }]
                            })(
                                <Input type='password' />
                            )
                        }</Form.Item>
                        <Form.Item {...submitFormLayout} style={{ marginTop: 32 }}>
                            <Button
                                id={'submits'}
                                type="primary"
                                htmlType="submit"
                                loading={this.props.loading}>
                                RESET
                                </Button>
                        </Form.Item>
                    </Form>
                </Drawer>

            </PageHeaderWrapper>)

    }
}


const mapStateToProps = ({ global }) => ({
    categories: global.categories,
    currentUser: global.currentUser,
    loading: global.buttonLoading
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(listCompanies)
