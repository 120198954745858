import React, { PureComponent } from 'react'
import styles from './styles.less'
import { Form, Card } from 'antd'
import _ from 'lodash'

import moment from 'moment'
import { FormUtils as GetAllFormFields } from 'sz-react-utils-lite'
import Request, { API_URL } from '../../../request'
import { connect } from 'react-redux'

@Form.create()
class FarmerTitle extends PureComponent {

    state = {
        cardLoading: false,
        reset: false
    }


    componentDidMount() {
        this.apiRequest()
    }

    apiRequest = async () => {
        const { currentUser, form, company } = this.props
        if (company) {
            this.setState({ cardLoading: true })
            let filter = { level: 'COMPANY', companyId: company }
            let data = await Request.getFarmerTitleSetting(filter)
            this.setState({ cardLoading: false })
            // let data = await Request.getSetting(currentUser.companyId)
            if (data && data.data) {
                form.setFieldsValue({
                    title: data.data.title
                })
            } else {
                form.setFieldsValue({
                    title: 'FARMER'
                })
            }
        }
        else {
            form.setFieldsValue({
                title: 'FARMER'
            })
        }

    }
    updateProps = () => {
        const { updateFormData, reset, submit, form } = this.props
        if (reset !== this.state.reset) {
            this.state.reset = reset
            form.setFieldsValue({ title: 'FARMER' })
        }
        if (submit !== this.state.submit) {
            this.state.submit = submit
            updateFormData('farmerTitle', form.getFieldsValue())
        }
    }
    render() {
        const { cardLoading } = this.state
        const {
            form: { getFieldDecorator, getFieldValue, setFieldsValue }
        } = this.props
        this.updateProps()


        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
                md: { span: 8 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
                md: { span: 12 }
            }
        }


        const inputType = {
            fields: [
                {
                    label: 'Farmer Title',
                    key: 'title',
                    required: true
                }
            ]
        }


        return (
            <Card loading={cardLoading} className={styles.container}>

                <GetAllFormFields
                    apiurl={API_URL}
                    inputSchema={inputType}
                    formItemLayout={formItemLayout}
                    getFieldDecorator={getFieldDecorator}
                />
            </Card>

        )
    }
}

const mapStateToProps = ({ global, router }) => ({
    loading: global.buttonLoading,
    categories: global.categories,
    search: router.location.search,
    currentUser: global.currentUser,
    pathname: router.location.pathname

})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FarmerTitle)
