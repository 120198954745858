export default (
    state = {
        testingResultLoader: false,
        testingResultList: {},
    },
    action
) => {
    switch (action.type) {
        case "TESTING_RESULT_SHOW_LOADER":
            return { ...state, testingResultLoader: true };
        case "TESTING_RESULT_HIDE_LOADER":
            return { ...state, testingResultLoader: false };
        case "TESTING_RESULT_LIST":
            return { ...state, testingResultList: action.payload };
        
        default:
            return state;
    }
};
