export default (
    state = {
        ipLoader: false,
        ipBtnLoader: false,
        ipList: {data:[]},
        ipResp: {},
    },
    action
) => {
    switch (action.type) {
        case "IP_SHOW_LOADER":
            return { ...state, ipLoader: true };
        case "IP_HIDE_LOADER":
            return { ...state, ipLoader: false };
        case "IP_BTN_SHOW_LOADER":
            return { ...state, ipBtnLoader: true };
        case "IP_BTN_HIDE_LOADER":
            return { ...state, ipBtnLoader: false };
        case "IP_LIST":
            return { ...state, ipList: action.payload };
        case "IP_RESPONSE":
            return { ...state, ipResp: action.payload };
        default:
            return state;
    }
};
