import React, { Component, useState, useEffect, useRef } from 'react'
import FormItem from '../../../components/FormItem'
import { TableComp } from 'sz-react-utils-lite'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import Highlighter from 'react-highlight-words'

import { Tabs, Button, Card, Select, Drawer, Input, Tooltip, Tag } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import {  timeStampFormat } from '../../../settings'
import _ from 'lodash'
import { useSelector } from 'react-redux'
import styles from './styles.less'
import Request from '../../../request'
import JSONPretty from 'react-json-pretty'
import JSONPrettyMon from 'react-json-pretty/dist/monikai'
import moment from 'moment'

const { TabPane } = Tabs
const { Option } = Select

// @Form.create()
const AllSyncLogs = (props) => {
  const table = useRef(null)
  const serverTable = useRef(null)
  const [mcps, setMcps] = useState([])
  const [logsList, setLogsList] = useState({data:[],loader:false})
  const [serverLogsList, setServerLogsList] = useState({data:[],loader:false})
  const [state, setState] = useState({
    dataSearchParams: {},
    serverDataSearchParams: {},
    pageSize:20,
    serverErrPageSize:20,
    // results:20
  })
  const [errorResp, setErrorResp] = useState([])
  const [visible, setVisible] = useState(false)

  const {
    currentUser,
  } = useSelector((state) => ({
    currentUser: state.global.currentUser,
  }))
 
  const getMcps = async (mcpGroupId) => {
    let filter = {  }
    if (currentUser.userType === 'manager')
      filter.mcpGroupId = currentUser.mcpGroups
    let { data, error } = await Request.getAllMcp(filter)
    if (!error) {
      setMcps(data)
    }
  }

  useEffect(() => {
    getMcps()
  }, [])

  useEffect(() => {
    apiRequest()
  }, [state.mcp])

  useEffect(() => {
    serverErrApiRequest()
  }, [state.mcpId])

  const apiRequest = (params = {}) => {
    return new Promise(async (resolve) => {
      const { level, mcp, isFilter, mcpGroup, pageSize } = state;
      if (isFilter) {
        params.page = params ? params.page || 1 : 1
        setState({ ...state, isFilter: false })
      }
      if (mcp) {
        params.mcpId = mcp
      }
      if (mcpGroup) {
        params.mcpGroupId = mcpGroup
      }
      if (level) {
        params.level = level
      }
      if (currentUser && currentUser.companyId) {
        params.companyId = currentUser.companyId
        if (currentUser.userType === 'manager' && !mcp)
          params.mcpId = mcps.map((val) => val.id)
      }

      if (currentUser.userType === 'manager' && mcps.length === 0) {
        return resolve({ total: 0, data: [] })
      }
      params.results = params && params.pageSize? params.pageSize : pageSize || 20;
      setState({
        ...state,
        dataSearchParams: params,
      })
      let filter = { ...params, companyId: currentUser.companyId }
    setLogsList({...logsList,data:[],loader:true})
      let data = await Request.listSyncLogs(filter);
      setLogsList({...data,loader:false})
    })
  }
  


  const serverErrApiRequest = (params = {}) => {
    return new Promise(async (resolve) => {
      const { level, mcpId, isFilter, mcpGroup,serverErrPageSize } = state
      if (isFilter) {
        params.page = params? params.page || 1 : 1;
        setState({ ...state, isFilter: false })
      }
      if (mcpId) {
        params.mcpId = mcpId
      }

      if (currentUser && currentUser.companyId) {
        params.companyId = currentUser.companyId
        if (currentUser.userType === 'manager' && !mcpId)
          params.mcpId = mcps.map((val) => val.id)
      }

      if (currentUser.userType === 'manager' && mcps.length === 0) {
        return resolve({ total: 0, data: [] })
      }
      setState({
        ...state,
        serverDataSearchParams: params,
      })
      params.results = params? params.serverErrPageSize: serverErrPageSize || 20;
      let filter = { ...params, companyId: currentUser.companyId }
    setServerLogsList({
        ...serverLogsList,
        data:[],
        loader:true
    })
    let data  = await Request.listServerSyncLogs(filter)
    setServerLogsList({
        ...data,
        loader:false
    })
    })
  }

  const getColumnSearchProps = (dataIndex, index) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          id={`input-${dataIndex}`}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          id={`search-${dataIndex}`}
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}>
          Search
        </Button>
        <Button
          id={`reset-${dataIndex}`}
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        id={`filter-menu-${dataIndex}`}
        style={{ color: filtered ? '#1890ff' : undefined }}
      />
    ),
    onFilter: (value, record) => {
      return index
        ? record[dataIndex][index]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ),
  })

  const handleSearch = (selectedKeys, confirm) => {
    confirm()
    setState({ ...state, searchText: selectedKeys[0] })
  }

  const handleReset = (clearFilters) => {
    clearFilters()
    setState({ ...state, searchText: '' })
  }
  const showDrawer = (resp) => {
    setErrorResp(resp)
    setVisible(true)
  }
  const columns = [
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      ...getColumnSearchProps('type'),
      render: (val, record) => {
        return <Tag>{val}</Tag>
      },
    },

    {
      title: 'MCP Name',
      dataIndex: 'mcpId.name',
      key: 'mcpId.name',
    },
    {
      title: 'Last Sync',
      dataIndex: 'lastSync',
      key: 'lastSync',
      render: (val, record) => {
        return <Tag>{moment(val).format(timeStampFormat)}</Tag>
      },
    },
    {
      title: 'Actions',
      dataIndex: 'action',
      key: 'action',
      render: (val, record) => {
        return (
          <Tooltip title="View Error Response">
            <Button
              style={{ marginRight: '5px' }}
              icon={'read'}
              shape={'circle'}
              onClick={() => {
                showDrawer(record.errors)
              }}
            />
          </Tooltip>
        )
      },
    },
  ]

  const serverColumns = [
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      ...getColumnSearchProps('type'),
      render: (val, record) => {
        return <Tag>{val}</Tag>
      },
    },

    {
      title: 'MCP Name',
      dataIndex: 'mcpId.name',
      key: 'mcpId.name',
    },
    {
      title: 'Last Sync',
      dataIndex: 'lastSync',
      key: 'lastSync',
      render: (val, record) => {
        return <Tag>{moment(val).format(timeStampFormat)}</Tag>
      },
    },
    {
      title: 'Actions',
      dataIndex: 'action',
      key: 'action',
      render: (val, record) => {
        return (
          <>
            <Tooltip title="View Error Response">
              <Button
                style={{ marginRight: '5px' }}
                icon={'read'}
                shape={'circle'}
                onClick={() => {
                  showDrawer(record.syncError)
                }}
              />
            </Tooltip>
            <Tooltip title="View Payload">
              <Button
                style={{ marginRight: '5px' }}
                icon={'book'}
                shape={'circle'}
                onClick={() => {
                  showDrawer(record.payload)
                }}
              />
            </Tooltip>
          </>
        )
      },
    },
  ]

  const handlePagination = (page, pageSize) => {
    setState({
      ...state,
      dataSearchParams: { ...state.dataSearchParams, page: page, pageSize },
    })
    apiRequest({ page: page, pageSize:pageSize })
  }

  const handleServerLogsPagination = (page) => {
    setState({
      ...state,
      serverDataSearchParams: { ...state.serverDataSearchParams, page: page },
    })
    serverErrApiRequest({ page: page })
  }

  return (
    <PageHeaderWrapper title={`Sync Logs`}>
    <div style={{ backgroundColor: 'white', padding: 20 }}>
      <Tabs defaultActiveKey="1">
        <TabPane tab={`App Sync Error Logs (${logsList.total || 0})`} key="1" style={{ padding: 20 }}>
          <div className={styles.filters}>
            <div>
              <Select
                style={{ marginRight: '10px' }}
                allowClear
                mode="multiple"
                id={'mcpfilter'}
                placeholder="Select MCP"
                style={{ width: 200 }}
                value={state.mcp || []}
                filterOption={(input, option) => {
                  return (
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  )
                }}
                onChange={(mcp) => {
                  setState({ ...state, mcp, isFilter: true })
                }}>
                {mcps.map((mcp) => (
                  <Option value={mcp.id} key={mcp.id}>
                    {mcp.name}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
          <TableComp
            ref={table}
            id={'allSyncLogs'}
            columns={columns}
            dataSource={logsList.data}
            loading={logsList.loader}
            pagination={{
              current: state.dataSearchParams.page || 1,
              showSizeChanger: false,
              onChange: handlePagination,
              position: 'both',
              defaultPageSize: 20,
              total: logsList.total || 0,
            }}
          />
          <Drawer
            title="Error Response"
            forceRender={true}
            placement="right"
            closable={true}
            onClose={() => {
              setVisible(false)
            }}
            visible={visible}
            width={800}>
            <div style={{ marginLeft: 20, marginRight: 20, marginTop: 20 }}>
                {/* {JSON.stringify(errorResp, undefined, 14)} */}
              <JSONPretty
                data={JSON.stringify(errorResp, undefined, 14)}
                theme={JSONPrettyMon}></JSONPretty>
            </div>
          </Drawer>
        </TabPane>
        <TabPane tab={`Server Sync Error Logs (${serverLogsList.total || 0})`} key="2" style={{ padding: 20 }}>
          <div className={styles.filters}>
            <div>
              <Select
                style={{ marginRight: '10px' }}
                allowClear
                mode="multiple"
                id={'mcpFilter2'}
                placeholder="Select MCP"
                style={{ width: 200 }}
                value={state.mcpId || []}
                filterOption={(input, option) => {
                  return (
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  )
                }}
                onChange={(mcpId) => {
                  setState({ ...state, mcpId, isServerFilter: true })
                }}>
                {mcps.map((mcp) => (
                  <Option value={mcp.id} key={mcp.id}>
                    {mcp.name}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
          <TableComp
            ref={serverTable}
            id={'errSyncLogs'}
            columns={serverColumns}
            dataSource={serverLogsList.data}
            loading={serverLogsList.loader}
            pagination={{
              current: state.serverDataSearchParams.page || 1,
              showSizeChanger: false,
              onChange: handleServerLogsPagination,
              position: 'both',
              defaultPageSize: 20,
              total: serverLogsList.total || 0,
            }}
          />
          <Drawer
            title="Error Payload"
            forceRender={true}
            placement="right"
            closable={true}
            onClose={() => {
              setVisible(false)
            }}
            visible={visible}
            width={800}>
            <div style={{ marginLeft: 20, marginRight: 20, marginTop: 20 }}>
              <JSONPretty
                data={JSON.stringify(errorResp, undefined, 14)}
                theme={JSONPrettyMon}></JSONPretty>
            </div>
          </Drawer>
        </TabPane>
      </Tabs>
    </div>
    </PageHeaderWrapper>
 
  )
}

export default AllSyncLogs
