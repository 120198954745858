import { combineReducers } from 'redux'
import counter from './counter'
import theme from './theme'
import global from './global'
import packages from '../elements/packages/reducers/packageReducers'
import debug from '../elements/debug/reducers/debugReducers'
import ip from '../elements/whitelistIps/reducers/ipReducers'
import testingUser from '../elements/testingUser/reducer/testingReducer'
import testingResults from '../elements/TestingResults/reducers/testingResultsReducer'
import register from '../elements/register/reducer/registerReducer'
import trialCompany from '../elements/TrialCompanies/reducer/trialCompaniesReducer'
import collectionCount from '../elements/collectionCount/reducers/collectionCountReducer'




import { createBrowserHistory as createHistory } from 'history'
import { connectRouter } from 'connected-react-router'
export const history = createHistory()
export default combineReducers({
  counter,
  theme,
  global,
  packages,
  debug,
  ip,
  testingUser,
  testingResults,
  trialCompany,
  collectionCount,
  register,
  router: connectRouter(history)
})
