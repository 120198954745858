import React, { useEffect, useState, useRef } from 'react'
import {
    notification,
    Table,
    Button,
    Spin,
    Form,
    Drawer, Card, Tag,
    Select,
    Tooltip,
    Popconfirm,
} from 'antd'
import { TableComp } from 'sz-react-utils-lite'
import _ from 'lodash'
import moment from 'moment'
import { dateFormat, timeStampFormat } from '../../../settings'
import Request, { API_URL } from '../../../request'
import { FormUtils as GetAllFormFields } from 'sz-react-utils-lite'
import { AddIpAction, UpdateIpAction, DeleteIpAction, ListIps } from '../action/ipsAction'
import { useSelector, useDispatch } from 'react-redux'

function ValidateIPaddress(ipaddress) {
    if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipaddress)) {
        return (true)
    }
    return (false)
}
const WhitelistIp = (props) => {

    const dispatch = useDispatch()
    const [visible, setVisible] = useState(true)
    const [record, setRecord] = useState(null)
    const [pagination, setPagination] = useState({})
    const [companies, setCompanies] = useState([])

    const { ipLoader, currentUser, ipResp, ipBtnLoader, ipList } = useSelector((state) => ({
        ipResp: state.ip.ipResp,
        ipLoader: state.ip.ipLoader,
        ipBtnLoader: state.ip.ipBtnLoader,
        ipList: state.ip.ipList,
        currentUser: state.global.currentUser
    }))
    useEffect(() => {
        getCompanies()
        setVisible(false)
        dispatch({ type: 'IP_HIDE_LOADER' });
        apiRequest()
        dispatch({ type: 'IP_RESPONSE', payload: {} })
        dispatch({ type: 'IP_LIST', payload: { data: [] } })
    }, [])

    useEffect(() => {
        if (ipResp && ipResp.success) {
            reset()
            apiRequest()
            dispatch({ type: 'IP_RESPONSE', payload: {} })
        }
    }, [ipResp])


    const reset = () => {
        props.form.resetFields()
        setRecord(null)
        setVisible(false)
    }
    const handleSubmit = (e, isEdit = false) => {
        e.preventDefault()
        const { form } = props

        form.validateFieldsAndScroll(async (err, valData) => {

            if (!err) {

                // dispatch(showLoader())
                if (!currentUser) {
                    return notification.error({
                        message: 'Error Saving',
                        description: 'Try Again'
                    })
                }

                if (isEdit) {
                    valData.ipAddress = valData.ipAddress1
                    valData.companyId = valData.companyId1
                    delete valData.ipAddress1
                    delete valData.companyId1
                }

                if (!valData.companyId || valData.companyId == '') {
                    return notification.error({
                        message: 'Error',
                        description: 'Please select company first'
                    })
                }
                if (!valData.ipAddress || valData.ipAddress.trim() == '' || !ValidateIPaddress(valData.ipAddress)) {
                    return notification.error({
                        message: 'Error',
                        description: 'Please input valid IP Address'
                    })
                }
                // setBtnLoading(true)
                if (!isEdit)
                    dispatch(AddIpAction(valData))
                else
                    dispatch(UpdateIpAction(record.id, valData))
                // setBtnLoading(false)
            } else {

                notification.warning({
                    message: 'Fill All Required Fields'
                })
            }
        })
    }

    const apiRequest = (params = {}) => {
        return new Promise(async (resolve) => {

            let filter = { ...params }
            dispatch(ListIps())
        })
    }

    const deleteIp = (record) => {
        // this.setState({ loader: true })
        dispatch(DeleteIpAction(record.id))

    }

    const getCompanies = async () => {
        let { data: allCompany } = await Request.listCompanies()
        setCompanies(allCompany || [])

    }
    const openDrawer = (record) => {
        const { form } = props
        setRecord(record)
        setVisible(true)
        form.setFieldsValue({ ipAddress1: record.ipAddress, companyId1: record.companyId.id })
    }

    const {
        form: { getFieldDecorator }
    } = props

    const inputTypes = (isEdit) => ({
        fields: [
            {
                label: 'Select Company',
                key: isEdit ? 'companyId1' : 'companyId',
                type: 'select',
                keyAccessor: x => x.id,
                valueAccessor: x => `${x.name} (${x.email})`,
                options: companies,
                onChange: companyId => {
                    isEdit ?
                        props.form.setFieldsValue({ companyId1: companyId })
                        : props.form.setFieldsValue({ companyId })
                },
                showSearch: true
            },
            {
                label: 'IP Address',
                key: isEdit ? 'ipAddress1' : 'ipAddress',
                type: 'text',
            }
        ]
    })
    const columns = [
        {
            title: 'IP Address',
            dataIndex: 'ipAddress',
            key: 'ipAddress'
        },
        {
            title: 'Company Name',
            dataIndex: 'companyId',
            key: 'companyId',
            render: (val) => (val && val.name)
        },
        {
            title: 'created On',
            dataIndex: 'createdOn',
            key: 'createdOn',
            render: (val, record) => (<Tooltip title={moment(val).format(timeStampFormat)}>
                <span>{moment(val).fromNow()}</span>
            </Tooltip>),
        },
        {
            title: 'updated On',
            dataIndex: 'updatedOn',
            key: 'updatedOn',
            render: (val, record) => (<Tooltip title={moment(val).format(timeStampFormat)}>
                <span>{moment(val).fromNow()}</span>
            </Tooltip>),
        },
        {
            title: 'Actions',
            dataIndex: 'action',
            key: 'action',
            render: (val, record) => (<>
                <Button icon='edit' shape='circle' onClick={() => openDrawer(record)} />
                <Popconfirm
                    title="Are you sure delete this task?"
                    onConfirm={() => deleteIp(record)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button style={{ marginLeft: '10px' }} icon='delete' type='danger' shape='circle' />
                </Popconfirm>
            </>
            ),
        },
    ]
    const submitFormLayout = {
        wrapperCol: {
            xs: { span: 24, offset: 0 },
            sm: { span: 10, offset: 7 },
            md: { span: 12, offset: 8 }
        }
    }


    return (
        <div style={{ display: 'flex' }}>
            <Card style={{ flex: 1 }}>
                <Form onSubmit={handleSubmit} style={{ marginTop: 8 }}>
                    <h2>WHITELIST AN IP</h2>
                    <br />

                    <GetAllFormFields
                        apiurl={API_URL}
                        inputSchema={inputTypes()}
                        getFieldDecorator={getFieldDecorator}
                    />
                    <Form.Item {...submitFormLayout} style={{ marginTop: 32 }}>
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={record ? false : ipBtnLoader}
                        >
                            SAVE
                            </Button>
                    </Form.Item>
                </Form>
            </Card>
            <Card style={{ flex: 1.5 }}
            >
                <h2>ALL WHITELISTED IPs</h2>
                <br />
                <Table
                    size='small'
                    loading={ipLoader}
                    columns={columns}
                    dataSource={ipList.data}
                    pagination={{
                        ...pagination,
                        defaultPageSize: 10,
                        pageSizeOptions: ['10', '25', '50', '100', '500'],
                        showSizeChanger: false
                    }}
                />
            </Card>

            <Drawer
                title={`Edit IP`}
                placement="right"
                closable={false}
                onClose={() => {
                    setVisible(false)
                    setRecord(null)
                    //reset this form fields if updated
                }}
                width="400"
                visible={visible}
            >
                <Card>
                    <Form onSubmit={(e) => handleSubmit(e, true)} style={{ marginTop: 8 }}>
                        <GetAllFormFields
                            apiurl={API_URL}
                            inputSchema={inputTypes(true)}
                            getFieldDecorator={getFieldDecorator}
                        />
                        <Form.Item {...submitFormLayout} style={{ marginTop: 32 }}>
                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={ipBtnLoader}
                            >
                                Update
                                </Button>
                        </Form.Item>
                    </Form>
                </Card>
            </Drawer>
        </div>

    )
}

const WrappedAnnouncement = Form.create()(WhitelistIp)

export default WrappedAnnouncement
