import axios from "axios";
import { listTestingUserUrl } from "../apis/testingUserApi";
import { notification } from 'antd'
import { useSelector } from "react-redux";
import { getToken } from "../../../request";


export const ListTestingUser = (inputData) => async (dispatch) => {
    dispatch({ type: 'TESTING_RESULT_SHOW_LOADER' });
    await axios
        .get(listTestingUserUrl(), { params: { ...inputData }, ...getToken() })
        .then(function ({ data }) {
            if (data.error) {
                notification.error({
                    message: data.message,
                })
            }
            else
                dispatch({
                    type: 'TESTING_RESULT_LIST',
                    payload: data,
                });
            dispatch({ type: 'TESTING_RESULT_HIDE_LOADER' });

        })
        .catch(function (error) {
            // toast.error(JSON.stringify(error));
            dispatch({ type: 'TESTING_RESULT_HIDE_LOADER' });
        });
};