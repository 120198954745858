import React, { PureComponent } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
    Form,
    Select,
    Input,
    Divider,
    Card,
    Descriptions,
    Button,
    Avatar,
    Comment,
    Upload,
    Icon
} from 'antd'
import _ from 'lodash'
import 'react-quill/dist/quill.snow.css'
import { notification } from 'antd/lib/index'
import { hideLoader, showLoader } from '../../../modules/actions'
import Request from '../../../request'
import { connect } from 'react-redux'
import moment from 'moment'
import { getUrlParams } from '../../../routes'
import capitalize from 'capitalize'
import styles from './style.less'
import { comment } from 'postcss-selector-parser'
const { Option } = Select
const { TextArea } = Input

const CommentList = ({ comments }) => {
    comments.sort((a, b) => (a.datetime > b.datetime) ? 1 : -1)
    return <div className={styles.commentList}>
        {comments.map((comment, i) => {
            const attachments = comment.attachments || []
            console.log(comment, attachments)
            return (<>
                <div className={styles.commentWrapper}>
                    <div className={styles.author}>
                        <div className={styles.user}>
                            <Avatar className={styles.avatar} icon='user' />
                            {comment.author}
                        </div>
                        <div>{comment.datetime}</div>
                    </div>
                    <div className={styles.comment}>
                        {comment.content}
                        {attachments.length > 0 && <div>
                            <Divider orientation='left'>Attachments</Divider>
                            {
                                attachments.map(file => {
                                    return <img width={100} height={100} src={file.location} />
                                })
                            }
                        </div>}
                    </div>
                </div>
                {(i != comments.length - 1) && <Divider className={styles.divider} />}

            </>)
        }
        )}
    </div>
};

class Editor extends PureComponent {
    state = {
        fileList: [],
        uploading: false,
    };
    handleSubmit = async () => {
        const files = await this.props.onUpload(this.state.fileList)
        if (files) this.props.onSubmit(files)
    }
    render() {
        const { onChange, submitting, value, disabled, hidden } = this.props
        const { uploading, fileList } = this.state;
        const props = {
            onRemove: file => {
                this.setState(state => {
                    const index = state.fileList.indexOf(file);
                    const newFileList = state.fileList.slice();
                    newFileList.splice(index, 1);
                    return {
                        fileList: newFileList,
                    };
                });
            },
            beforeUpload: file => {
                this.setState(state => ({
                    fileList: [...state.fileList, file],
                }));
                return false;
            },
            fileList,
        };
        return (<div hidden={!hidden}>
            <Form.Item label='Reply'>
                <TextArea disabled={disabled} rows={10} placeholder='Type your comment here...' onChange={onChange} value={value} />
            </Form.Item>
            <Form.Item>
                <Button  className={styles.commentBtn} disabled={disabled} htmlType="submit" loading={submitting || uploading} onClick={this.handleSubmit} type="primary">
                    {fileList.length > 0 ? uploading ? 'Uploading' : 'Upload' : 'Add Comment'}
                </Button>
                <Upload {...props}>
                    <Button disabled={disabled}>
                        <Icon type="upload" /> Attach File
              </Button>
                </Upload>
            </Form.Item>
        </div>
        );
    }
}


@Form.create()
class TicketDetails extends PureComponent {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            showCommentBox: false,
            comments: [],
            submitting: false,
            value: '',
            reply: false,
            fileList: [],
            uploading: false,
        }
    }

    componentDidMount() {
        let data = getUrlParams('ticketDetails', this.props.pathname)
        if (data && data.id) {
            this.setState({
                loading: true,
                id: data.id
            }, () => {
                this.setFormValues(data.id)
            })
        }
    }
    setFormValues = async (id) => {
        this.setState({ loading: true })
        let data = await Request.getTicket(id)
        this.setState({ loading: false })
        if (data.error || !data.data) {
            notification.error({
                message: 'Something Went Wrong'
            })
            return false;
        }
        console.log(data)
        let oldComments = []
        if (data.data.comments) {
            oldComments = data.data.comments.comments ? data.data.comments.comments : []
        }
        this.setState({ ticket: data.data, comments: oldComments, status: data.data.status, description: data.data.description })
    }
    handleUpload = async (fileList) => {
        // const { fileList } = this.state;
        if (!this.state.value) {
            return notification.error({ message: 'Please add comment with attachments' })
        }
        const formData = new FormData();
        fileList.forEach(file => {
            formData.append('files', file, `${file.name}`);
        });

        this.setState({
            uploading: true,
        });
        console.log(fileList, formData.getAll('files'))
        const data = await Request.upload(formData)
        console.log(data)
        this.setState({
            uploading: false,
            fileList: []
        });
        return data.files
    };
    commentSubmit = async (files) => {
        console.log(files)
        if (!this.state.value) {
            return;
        }

        this.setState({
            submitting: true,
        });
        let comment = {
            author: this.props.currentUser.name,
            content: this.state.value,
            datetime: moment().toString(),
            attachments: [
                // {
                //     fieldname: "files",
                //     originalname: "Screenshot (15).png",
                //     encoding: "7bit",
                //     mimetype: "image/png",
                //     size: 114364, bucket: "mim-static",
                //     key: "/filesca1fb32c-62e6-43c6-9e4c-55fb4c808f63.jpg",
                //     acl: "public-read",
                //     contentType: "application/octet-stream",
                //     contentDisposition: null,
                //     storageClass: "STANDARD",
                //     serverSideEncryption: null,
                //     metadata: { fieldName: "filesca31e95c-9982-4518-9617-77bbb0aa3fdf.jpg" },
                //     location: "https://mim-static.s3.us-west-2.amazonaws.com//filesca1fb32c-62e6-43c6-9e4c-55fb4c808f63.jpg",
                //     etag: "a2079a3de076e8eb042ad2a09c49595f"
                // }
            ]
        }
        console.log(comment)
        this.setState({
            submitting: false,
            value: '',
            comments: [
                comment,
                ...this.state.comments,
            ],
        }, () => {
            Request.updateTicket(this.state.ticket.id, { comments: { comments: [...this.state.comments] } })
        });
    };
    handleChange = e => {
        this.setState({
            value: e.target.value,
        });
    };

    handleDescription = (e) => {
        this.setState({ description: e.target.value })
    }
    addComment = () => {
        this.setState({ showCommentBox: !this.state.showCommentBox })
    }
    resolve = async () => {
        let oldComments = []
        _.forEach(this.state.comments, (comment) => {
            oldComments.push(JSON.stringify(comment))
        })
        const data = await Request.updateTicket(this.state.ticket.id, { status: 'Resolved' })
        if (!data.error) {
            notification.success({
                message: 'Ticket updated successfull'
            })
            let newData = _.clone(this.state.ticket)
            newData.status = data.data.status
            this.setState({ ticket: newData, reply: false })
        }
        else {
            notification.error({
                message: 'Something Went Wrong'
            })
        }
    }
    render() {
        const { loading: cardLoading, ticket, comments, submitting, value, reply } = this.state


        return (<>
            <div className={styles.title} style={{margin: 0}}>
                <div className={styles.resolve}>
                    <h1><b>Ticket Details</b></h1>
                    <Button type='primary' disabled={ticket && ticket.status === 'Resolved'} onClick={this.resolve}>Resolve</Button>
                </div>
            </div>
            <Card loading={cardLoading}>
                {ticket &&
                    <div className={styles.wrapper}>
                        <div className={styles.descriptionLeft}>
                            <Descriptions layout='vertical' size='small' className={styles.antDescriptions}>
                                <Descriptions.Item span={3} label={<span className={styles.label}>Subject</span>}><div className={styles.info}>{ticket.subject}</div></Descriptions.Item>
                                <Descriptions.Item span={3} label={<span className={styles.label}>Ticket Id</span>}><div className={styles.info}>{ticket.id}</div></Descriptions.Item>
                                <Descriptions.Item span={3} label={<span className={styles.label}>Raised By</span>}><div className={styles.info}>{ticket.raisedBy}</div></Descriptions.Item>
                                <Descriptions.Item span={3} label={<span className={styles.label}>Device Id</span>}><div className={styles.info}>{ticket.device && ticket.device.id}</div></Descriptions.Item>
                                <Descriptions.Item span={3} label={<span className={styles.label}>mcp</span>}><div className={styles.info}>{ticket.device && `${ticket.device.mcp.mcpName} (${ticket.device.mcp.id})`}</div></Descriptions.Item>
                                <Descriptions.Item span={3} label={<span className={styles.label}>mcp</span>}><div className={styles.info}>{ticket.company && `${ticket.company.name} (${ticket.company.id})`}</div></Descriptions.Item>
                            </Descriptions>
                        </div>
                        <div className={styles.descriptionRight}>
                            <Descriptions layout='vertical' size='small' className={styles.antDescriptions}>
                                <Descriptions.Item span={3} label={<span className={styles.label}>Status</span>}><div className={styles.info}>{ticket.status}</div></Descriptions.Item>
                                <Descriptions.Item span={3} label={<span className={styles.label}>Severity</span>}><div className={styles.info}>{ticket.severity}</div></Descriptions.Item>
                                <Descriptions.Item span={3} label={<span className={styles.label}>Created</span>}><div className={styles.info}>{ticket.createdAt}</div></Descriptions.Item>
                                <Descriptions.Item span={3} label={<span className={styles.label}>Updated</span>}><div className={styles.info}>{moment(ticket.updatedAt).fromNow()}</div></Descriptions.Item>
                            </Descriptions>
                        </div>
                    </div>
                }
            </Card>
            <Comment
                className={styles.antComment}
                avatar={<div style={{ margin: 0 }}></div>}
                content={
                    <Editor
                        hidden={reply}
                        disabled={ticket && ticket.status === 'Resolved'}
                        onChange={this.handleChange}
                        onSubmit={this.commentSubmit}
                        onUpload={this.handleUpload}
                        submitting={submitting}
                        value={value}
                    />
                }
            />
            <div className={styles.title} style={{ margin: 0 }}>
                <div className={styles.resolve}>
                    <h1><b>Description</b></h1>
                    <Button type='primary' disabled={ticket && ticket.status === 'Resolved'} onClick={() => this.setState({ reply: true })}>Reply</Button>
                </div>
            </div>
            {ticket && <Card>
                {ticket.description}</Card>}
            <div className={styles.title}>
                <div className={styles.resolve}>
                    <h1><b>Discussion</b></h1>
                </div>
            </div>
            <div>
                {comments.length > 0 && <CommentList comments={comments} />}
            </div>
        </>
        )
    }
}

const mapStateToProps = ({ global, router }) => ({
    loading: global.buttonLoading,
    categories: global.categories,
    search: router.location.search,
    pathname: router.location.pathname,
    currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TicketDetails)
