import axios from "axios";
import { addTestingUserUrl, listTestingUserUrl, editTestingUserUrl, deleteTestingUser } from "../api/testingUserUrl";
import { notification } from 'antd'
import { useSelector } from "react-redux";
import { getToken } from "../../../request";

export const AddTestingUserAction = (valData) => async (dispatch) => {
    dispatch({ type: 'TESTING_USER_SHOW_LOADER' });

    await axios
        .post(addTestingUserUrl(), valData, getToken())
        .then(function ({ data: x }) {
            dispatch({ type: 'TESTING_USER_HIDE_LOADER' });


            if (!x.error) {
                dispatch({ type: 'TESTING_USER_SUCCESS_CHECKER' });

                notification.success({
                    message: x.message

                })
                // dispatch({ type: 'TESTING_USER_RESPONSE', payload: x })
            } else {
                dispatch({ type: 'TESTING_USER_ERROR_CHECKER' });

                notification.error({
                    message: 'Error Saving',
                    description: x.message
                })
            }
        })
        .catch(function (error) {
            // toast.error(JSON.stringify(error));
            dispatch({ type: 'TESTING_USER_HIDE_LOADER' });
            dispatch({ type: 'TESTING_USER_ERROR_CHECKER' });

        });
};
export const ListTestingUser = (inputData) => async (dispatch) => {
    dispatch({ type: 'TESTING_USER_SHOW_LOADER' });
    await axios
        .get(listTestingUserUrl(), { params: { ...inputData }, ...getToken() })
        .then(function ({ data }) {
            dispatch({ type: 'TESTING_USER_HIDE_LOADER' });
            if (data.error) {
                notification.error({
                    message: data.message,
                })
            }
            else
                dispatch({
                    type: 'TESTING_USER_LIST',
                    payload: data,
                });
        })
        .catch(function (error) {
            // toast.error(JSON.stringify(error));
            dispatch({ type: 'TESTING_USER_HIDE_LOADER' });
        });
};
export const UpdateTestingUser = (id, inputData) => async (dispatch) => {
    dispatch({ type: 'TESTING_USER_SHOW_LOADER' });
    await axios
        .put(editTestingUserUrl(id), inputData, getToken())
        .then(function ({ data }) {
            dispatch({ type: 'TESTING_USER_HIDE_LOADER' });

            if (data.error) {
                notification.error({
                    message: data.message,
                })
            }else {
                notification.success({
                    message:data.message,
                })
            }
            
        })
        .catch(function (error) {
            console.log('error', error);
            notification.error({
                message: JSON.stringify(error)
            })
            // toast.error(JSON.stringify(error));
            dispatch({ type: 'TESTING_USER_HIDE_LOADER' });
        });
};

export const GetTestingUserById = (id) => async (dispatch) => {
    dispatch({ type: 'TESTING_USER_SHOW_LOADER' });
    dispatch({ type: 'TESTING_USER_CARD_SHOW_LOADER' });

    await axios
        .get(editTestingUserUrl(id), getToken())
        .then(function (data) {
            console.log(data)
            dispatch({ type: 'TESTING_USER_HIDE_LOADER' });
            dispatch({ type: 'TESTING_USER_CARD_HIDE_LOADER' });

            dispatch({
                type: 'TESTING_USER_EDIT_RESPONSE',
                payload: data.data
            });
        })
        .catch(function (error) {
            console.log('error', error);
            notification.error({
                message: JSON.stringify(error)
            })
            // toast.error(JSON.stringify(error));
            dispatch({ type: 'TESTING_USER_HIDE_LOADER' });
            dispatch({ type: 'TESTING_USER_CARD_HIDE_LOADER' });

        });
};
export const deleteTestingUserById = (id) => async (dispatch) => {
    dispatch({ type: 'TESTING_USER_SHOW_LOADER' });
    await axios
        .delete(deleteTestingUser(id), getToken())
        .then(function (data) {
            dispatch({ type: 'TESTING_USER_LOADER' });
            notification.success({
                message: 'Successfully deleted Testing User .'
            })
        })
        .catch(function (error) {
            console.log('error', error);
            // toast.error(JSON.stringify(error));
            notification.error({
                message: JSON.stringify(error)
            })
            dispatch({ type: 'TESTING_USER_HIDE_LOADER' });
        });
};