import axios from 'axios'

import { apiUrl } from './settings'

export const API_URL = apiUrl

let authAxios = axios.create({
    baseURL: apiUrl
})

export const getToken = () => {
    return ({ 'headers': { 'Authorization': 'Bearer ' + localStorage.getItem('token'), type: 'SUPER_BACK_OFFICE' } })
}

class Request {

    error = (err) => {
        try {
            if (err.response.status === 401) {
                localStorage.clear()
            }
        } catch (e) {
        }
    }

    constructor() {

    }

    login(data) {
        return new Promise((next, error) => {
            authAxios.get('https://api.ipify.org/?format=json')
                .then((d) => {
                    authAxios.post('/loginSuperWithPassword', {...data, ipAddress: d.data.ip})
                        .then((d) => {
                            next(d.data)
                        })
                        .catch((err) => {
                            next({ error: true, err })
                            this.error(err)
                        })
                })
                .catch((err) => {

                    authAxios.post('/loginSuperWithPassword', {...data, ipAddress: "0.0.0.0"})
                      .then((d) => {
                          next(d.data)
                      })
                      .catch((err) => {
                          next({ error: true, err })
                          this.error(err)
                      })


                    // next({ error: true, err })
                    // this.error(err)
                })

        })
    }

    addUser(data = {}) {
        return new Promise((next, error) => {
            authAxios.post(`/user`, data, getToken())
                .then((d) => {
                    console.log(d)
                    next(d.data)
                })
                .catch((err) => {
                    next({ error: true, err })
                    this.error(err)
                })

        })
    }

    listUsers(data = {}) {
        return new Promise((next, error) => {
            authAxios.get(`/user`, { params: { ...data }, ...getToken() })
                .then((d) => {
                    console.log(d)
                    next(d.data)
                })
                .catch((err) => {
                    next({ error: true, err })
                    this.error(err)
                })

        })
    }

    deleteUser(id) {
        return new Promise((next, error) => {
            authAxios.delete(`/user/${id}`, getToken())
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({ error: true, err })
                    this.error(err)
                })

        })
    }

    getUser(id) {
        return new Promise((next, error) => {
            authAxios.get(`/user/${id}`, getToken())
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({ error: true, err })
                    this.error(err)
                })

        })
    }

    updateUser(id, data = {}) {
        return new Promise((next, error) => {
            authAxios.put(`/user/${id}`, { ...data }, getToken())
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({ error: true, err })
                    this.error(err)
                })

        })
    }

    addCompany(data) {
        return new Promise((next, error) => {
            authAxios.post('/company', data, getToken())
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({ error: true, err })
                    this.error(err)
                })

        })
    }

    addCategory(data) {
        console.log("szcxsdcsad", data)
        return new Promise((next, error) => {
            authAxios.post('/category', data, getToken())
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({ error: true, err })
                    this.error(err)
                })

        })
    }
    listCategory(data = {}) {
        return new Promise((next, error) => {
            authAxios.get(`/category`, { params: { ...data }, ...getToken() })
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({ error: true, err })
                    this.error(err)
                })

        })
    }

    updateCompany(id, data = {}) {
        return new Promise((next, error) => {
            authAxios.put(`/company/${id}`, { ...data }, getToken())
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({ error: true, err })
                    this.error(err)
                })

        })
    }

    deleteCompany(id) {
        return new Promise((next, error) => {
            authAxios.delete(`/company/${id}`, getToken())
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({ error: true, err })
                    this.error(err)
                })

        })
    }
    deleteRate(id) {
        return new Promise((next, error) => {
            authAxios.delete(`/rate/${id}`, getToken())
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({ error: true, err })
                    this.error(err)
                })

        })
    }

    addRateChartDetails(data) {
        return new Promise((next, error) => {
            authAxios.post('/rateChartDetails', data, getToken())
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({ error: true, err })
                    this.error(err)
                })

        })
    }

    getRateChartDetails(data) {
        return new Promise((next, error) => {
            authAxios.get('/rateChartDetails', { params: data, ...getToken() })
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({ error: true, err })
                    this.error(err)
                })

        })
    }

    updateRateChartDetails(id, data = {}) {
        return new Promise((next, error) => {
            authAxios.put(`/rateChartDetails/${id}`, { ...data }, getToken())
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({ error: true, err })
                    this.error(err)
                })

        })
    }

    updateRate(id, data = {}) {
        return new Promise((next, error) => {
            authAxios.put(`/rate/${id}`, { ...data }, getToken())
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({ error: true, err })
                    this.error(err)
                })

        })
    }

    restoreCompany(id) {
        return new Promise((next, error) => {
            authAxios.patch(`/company/${id}`, getToken())
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({ error: true, err })
                    this.error(err)
                })

        })
    }

    getCompany(id) {
        return new Promise((next, error) => {
            authAxios.get(`/company/${id}`, getToken())
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({ error: true, err })
                    this.error(err)
                })

        })
    }
    addDevice(data) {
        return new Promise((next, error) => {
            authAxios.post('/device', data, getToken())
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    addRate(data) {
        return new Promise((next, error) => {
            authAxios.post('/rate', data, getToken())
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    listCompanies(data = {}) {
        return new Promise((next, error) => {
            authAxios.get(`/company`, { params: { ...data }, ...getToken() })
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({ error: true, err })
                    this.error(err)
                })

        })
    }
    listSuspendedCompanies(data = {}) {
        return new Promise((next, error) => {
            authAxios.get(`/suspended/company`, { params: { ...data }, ...getToken() })
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({ error: true, err })
                    this.error(err)
                })

        })
    }
    listRate(data = {}) {
        return new Promise((next, error) => {
            console.log(data)
            authAxios.get(`/rate`, { params: { ...data }, ...getToken() })
                .then((d) => {
                    console.log("12121", d)
                    next(d.data)
                })
                .catch((err) => {
                    next({ error: true, err })
                    this.error(err)
                })

        })
    }
    upload(data = {}) {
        return new Promise((next, error) => {
            authAxios.post(`/upload/file`, data, getToken())
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({ error: true, err })
                    this.error(err)
                })

        })
    }

    updateFarmer(id, data) {
        return new Promise(next => {
            authAxios
                .put('/farmer/' + id, data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }

    addFarmer(data) {
        return new Promise(next => {
            authAxios
                .post('/farmer', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }

    getFarmerById(id, data) {
        return new Promise(next => {
            authAxios
                .get('/farmer/' + id, { params: { ...data }, ...getToken() })
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }

    getAllFarmers(data) {
        return new Promise((next) => {
            authAxios
                .get('/farmer', { params: { ...data }, ...getToken() })
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({ error: true, err })
                    this.error(err)
                })

        })
    }

    deleteFarmer(id) {
        return new Promise(next => {
            authAxios
                .delete(`/farmer/` + id, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }

    restoreFarmer(id) {
        return new Promise(next => {
            authAxios
                .patch(`/farmer/` + id, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }

    addMcp(data) {
        return new Promise(next => {
            authAxios
                .post('/mcp', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }

    getAllmcp(data) {
        return new Promise((next) => {
            authAxios
                .get('/mcp', { params: { ...data }, ...getToken() })
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({ error: true, err })
                    this.error(err)
                })

        })
    }

    getmcpById(id) {
        return new Promise(next => {
            authAxios
                .get('/mcp/' + id, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }

    updateMcp(data) {
        return new Promise(next => {
            authAxios
                .put('/mcp/' + data._id, data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    getCompleteMcpById(id) {
        return new Promise(next => {
            authAxios
                .get('/getCompleteMcp/' + id, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    addSetting(data) {
        return new Promise(next => {
            authAxios
                .post('/setting/', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    getSetting(id) {
        return new Promise(next => {
            authAxios
                .get('/setting/' + id, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    updateSetting(id, data) {
        return new Promise(next => {
            authAxios
                .put('/setting/' + id, data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    updateMilkAnalyserOptn(data) {
        return new Promise(next => {
            authAxios
                .post('/editMilkScannerOptions', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    getMilkAnalyserOptn(data) {
        return new Promise(next => {
            authAxios
                .get('/milkScannerOptions', { params: data, ...getToken() })
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    addMcpGroup(data) {
        return new Promise((next) => {
            authAxios
                .post('/mcpGroup', { ...data }, getToken())
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({ error: true, err })
                    this.error(err)
                })

        })
    }
    updateMcpGroup(id, data) {
        return new Promise((next) => {
            authAxios
                .put('/mcpGroup/' + id, { ...data }, getToken())
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({ error: true, err })
                    this.error(err)
                })

        })
    }
    getAllGroup(data) {
        return new Promise((next) => {
            authAxios
                .get('/mcpGroup', { params: { ...data }, ...getToken() })
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({ error: true, err })
                    this.error(err)
                })

        })
    }
    getCollections(data = {}) {
        return new Promise((next) => {
            authAxios
                .get('/collection', { params: { ...data }, ...getToken() })
                .then((d) => {
                    console.log(d.data)
                    next(d.data)
                })
                .catch((err) => {
                    next({ error: true, err })
                    this.error(err)
                })

        })
    }
    getAllMcp(data) {
        return new Promise((next) => {
            authAxios
                .get('/mcp', { params: { ...data }, ...getToken() })
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({ error: true, err })
                    this.error(err)
                })

        })
    }

    deleteMcpGroup(id) {
        return new Promise(next => {
            authAxios
                .delete(`/mcpGroup/` + id, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    getMcpGroupById(id) {
        return new Promise(next => {
            authAxios
                .get(`/mcpGroup/` + id, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    restoreMcpGroup(id) {
        return new Promise(next => {
            authAxios
                .patch(`/mcpGroup/` + id, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    getPayments(data = {}) {
        return new Promise((next) => {
            authAxios
                .get('/payment', { params: { ...data } }, getToken())
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({ error: true, err })
                    this.error(err)
                })

        })
    }
    //settings routes
    getAutoMilkType(data) {
        return new Promise(next => {
            authAxios
                .get('/getAutoMilkType', { params: { ...data }, ...getToken() })
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }

    getCollectionExportSettings(data) {
        return new Promise(next => {
            authAxios
                .get('/getCollectionExportSetting', { params: { ...data }, ...getToken() })
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }

    getMachineSettings(data) {
        return new Promise(next => {
            authAxios
                .get(`/getMachineSettings`, { params: { ...data }, ...getToken() })
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }

    getFarmerTitleSetting(data) {
        return new Promise(next => {
            authAxios
                .get(`/getFarmerTitleSetting`, { params: { ...data }, ...getToken() })
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }

    addSmsPacks(data = {}) {
        return new Promise((next, error) => {
            authAxios.post(`/smsPacks`, data, getToken())
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({ error: true, err })
                    this.error(err)
                })

        })
    }

    listSmsPacks(data = {}) {
        return new Promise((next, error) => {
            authAxios.get(`/smsPacks`, { params: { ...data }, ...getToken() })
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({ error: true, err })
                    this.error(err)
                })

        })
    }

    deleteSmsPack(id) {
        return new Promise((next, error) => {
            authAxios.delete(`/smsPack/${id}`, getToken())
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({ error: true, err })
                    this.error(err)
                })

        })
    }

    getSmsPack(id) {
        return new Promise((next, error) => {
            authAxios.get(`/smsPack/${id}`, getToken())
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({ error: true, err })
                    this.error(err)
                })

        })
    }

    updateSmsPack(id, data = {}) {
        return new Promise((next, error) => {
            authAxios.put(`/smsPack/${id}`, { ...data }, getToken())
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({ error: true, err })
                    this.error(err)
                })

        })
    }

    deletePack(id) {
        return new Promise((next, error) => {
            authAxios.delete(`/smsPack/${id}`, getToken())
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({ error: true, err })
                    this.error(err)
                })

        })
    }
    countCompanies(data = {}) {
        return new Promise((next, error) => {
            authAxios.get(`/companyCount`, { params: { ...data }, ...getToken() })
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({ error: true, err })
                    this.error(err)
                })

        })
    }
    addHelpDeskSetting(data) {
        return new Promise((next, error) => {
            authAxios.post('/helpDesk', data, getToken())
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({ error: true, err })
                    this.error(err)
                })

        })
    }
    deleteHelpDesk(id) {
        return new Promise(next => {
            authAxios
                .delete(`/helpDesk/` + id, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    getHelpDeskById(id) {
        return new Promise(next => {
            authAxios
                .get(`/helpDesk/` + id, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    getAllHelpDesk() {
        return new Promise(next => {
            authAxios
                .get(`/helpDesk`, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    updateHelpDesk(id, data) {
        return new Promise((next) => {
            authAxios
                .put('/helpDesk/' + id, { ...data }, getToken())
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({ error: true, err })
                    this.error(err)
                })

        })
    }
    listSyncLogs(data) {
        return new Promise(next => {
            authAxios
                .get(`/syncLogs`, { params: { ...data }, ...getToken() })
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    listServerSyncLogs(data) {
        return new Promise(next => {
            authAxios
                .get(`/serverSyncLogs`, { params: { ...data }, ...getToken() })
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }

    fetchOnlineMcps(data) {
        return new Promise(next => {
            authAxios
                .get('/fetch/onlineMcps',{params:{...data}, ...getToken()})
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    exportCollectionsCsv(data = {}) {
        return new Promise((next) => {
          authAxios
            .get('/downloadCollectionsCsv', { params: { ...data }, ...getToken() })
            .then((d) => {
              if (d.data) {
                let linkValue = API_URL + `/${d.data.fileName}`
                window.open(linkValue, 'download')
              }
              next(d.data)
            })
            .catch((err) => {
              next({ error: true, err })
              this.error(err)
            })
        })
      }


}

export default new Request()
