import React, { useEffect, useState, useRef } from 'react'
import {
    notification,
    Table,
    Button,
    Spin,
    Form,
    Drawer, Card, Tag,
    Select,
    Popconfirm
} from 'antd'
import { TableComp } from 'sz-react-utils-lite'
import _ from 'lodash'
import moment from 'moment'
import { dateFormat } from '../../../settings'
import Request, { API_URL } from '../../../request'
import { FormUtils as GetAllFormFields } from 'sz-react-utils-lite'
import { AddPackageAction, UpdatePackageAction, DeletePackageAction, ListPackage } from '../action/packageAction'
import { useSelector, useDispatch } from 'react-redux'


const AddPackage = (props) => {

    const dispatch = useDispatch()
    const table = useRef(null)
    const [btnLoading, setBtnLoading] = useState(false)
    const [visible, setVisible] = useState(true)
    const [record, setRecord] = useState(null)
    const [pagination, setPagination] = useState({})

    const { packageLoader, currentUser, packageResp, packageBtnLoader, packageList } = useSelector((state) => ({
        packageResp: state.packages.packageResp,
        packageLoader: state.packages.packageLoader,
        packageBtnLoader: state.packages.packageBtnLoader,
        packageList: state.packages.packageList,
        currentUser: state.global.currentUser
    }))
    useEffect(() => {
        setVisible(false)
        dispatch({ type: 'PACKAGE_HIDE_LOADER' });
        apiRequest()
        dispatch({ type: 'PACKAGE_RESPONSE', payload: {} })
        dispatch({ type: 'PACKAGE_LIST', payload: { data: [] } })
    }, [])

    useEffect(() => {
        if (packageResp && packageResp.success) {
            reset()
            apiRequest()
            dispatch({ type: 'PACKAGE_RESPONSE', payload: {} })
        }
    }, [packageResp])


    const reset = () => {
        props.form.resetFields()
        setRecord(null)
        setVisible(false)
    }
    const handleSubmit = (e, isEdit = false) => {
        e.preventDefault()
        const { form } = props

        form.validateFieldsAndScroll(async (err, valData) => {

            if (!err) {

                // dispatch(showLoader())
                if (!currentUser) {
                    return notification.error({
                        message: 'Error Saving',
                        description: 'Try Again'
                    })
                }

                if (isEdit) {
                    valData.text = valData.text1
                    valData.amount = valData.amount1
                    valData.credits = valData.credits1
                    delete valData.text1
                    delete valData.amount1
                    delete valData.credits1
                }

                if (!valData.text || valData.text == '') {
                    return notification.error({
                        message: 'Error',
                        description: 'Please input valid text for package'
                    })
                }
                if (!valData.amount || valData.amount <= 0) {
                    return notification.error({
                        message: 'Error',
                        description: 'Choose valid package amount'
                    })
                }
                if (!valData.credits || valData.credits <= 0) {
                    return notification.error({
                        message: 'Error',
                        description: 'Choose valid sms pack credits'
                    })
                }

                // setBtnLoading(true)
                if (!isEdit)
                    dispatch(AddPackageAction(valData))
                else
                    dispatch(UpdatePackageAction(record.id, valData))
                // setBtnLoading(false)
            } else {

                notification.warning({
                    message: 'Fill All Required Fields'
                })
            }
        })
    }

    const apiRequest = (params = {}) => {
        return new Promise(async (resolve) => {

            let filter = { ...params }
            dispatch(ListPackage())
        })
    }

    const deleteFarmer = (record) => {
        // this.setState({ loader: true })
        dispatch(DeletePackageAction(record.id))

    }

    const openDrawer = (record) => {
        const { form } = props
        setRecord(record)
        setVisible(true)
        form.setFieldsValue({ text1: record.text, amount1: record.amount, credits1: record.credits })
    }

    const {
        form: { getFieldDecorator }
    } = props

    const inputTypes = (isEdit) => ({
        fields: [
            {
                label: 'Text',
                key: isEdit ? 'text1' : 'text',
                type: 'textarea'
            },
            {
                label: 'Amount',
                key: isEdit ? 'amount1' : 'amount',
                type: 'number',
            },
            {
                label: 'Credits',
                key: isEdit ? 'credits1' : 'credits',
                type: 'number',
            }
        ]
    })
    const columns = [
        {
            title: 'Text',
            dataIndex: 'text',
            key: 'text'
        },
        {
            title: 'amount',
            dataIndex: 'amount',
            key: 'amount'
        },
        {
            title: 'SMS Credits',
            dataIndex: 'credits',
            key: 'credits'
        },
        {
            title: 'created On',
            dataIndex: 'createdOn',
            key: 'createdOn',
            render: (val, record) => (moment(val).format(dateFormat)),
        },
        {
            title: 'Actions',
            dataIndex: 'action',
            key: 'action',
            render: (val, record) => (<>
                <Button icon='edit' shape='circle' onClick={() => openDrawer(record)} />
                <Popconfirm 
                title='Are you sure you want to delete this package'
                onConfirm={()=>deleteFarmer(record)}
                okText="Yes"
                cancelText="No"
                >
                <Button style={{ marginLeft: '10px' }} icon='delete' type='danger' shape='circle'/>
                </Popconfirm>
            </>
            ),
        },
    ]
    const submitFormLayout = {
        wrapperCol: {
            xs: { span: 24, offset: 0 },
            sm: { span: 10, offset: 7 },
            md: { span: 12, offset: 8 }
        }
    }


    return (
        <div style={{ display: 'flex' }}>
            <Card style={{ flex: 1 }}>
                <Form onSubmit={handleSubmit} style={{ marginTop: 8 }}>
                    <h2>SMS Credit Packs</h2>
                    <br />

                    <GetAllFormFields
                        apiurl={API_URL}
                        inputSchema={inputTypes()}
                        getFieldDecorator={getFieldDecorator}
                    />
                    <Form.Item {...submitFormLayout} style={{ marginTop: 32 }}>
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={record ? false : packageBtnLoader}
                        >
                            SAVE
                            </Button>
                    </Form.Item>
                </Form>
            </Card>
            <Card style={{ flex: 1.5 }}
            >
                <h2>ALL SMS Packs</h2>
                <br />
                <Table
                    size='small'
                    loading={packageLoader}
                    columns={columns}
                    dataSource={packageList.data}
                    pagination={{
                        ...pagination,
                        defaultPageSize: 10,
                        pageSizeOptions: ['10', '25', '50', '100', '500'],
                        showSizeChanger: false
                    }}
                />
            </Card>

            <Drawer
                title={`Edit SMS Pack`}
                placement="right"
                closable={false}
                onClose={() => {
                    setVisible(false)
                    setRecord(null)
                    //reset this form fields if updated
                }}
                width="400"
                visible={visible}
            >
                <Card>
                    <Form onSubmit={(e) => handleSubmit(e, true)} style={{ marginTop: 8 }}>
                        <GetAllFormFields
                            apiurl={API_URL}
                            inputSchema={inputTypes(true)}
                            getFieldDecorator={getFieldDecorator}
                        />
                        <Form.Item {...submitFormLayout} style={{ marginTop: 32 }}>
                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={packageBtnLoader}
                            >
                                Update
                                </Button>
                        </Form.Item>
                    </Form>
                </Card>
            </Drawer>
        </div>

    )
}

const WrappedAnnouncement = Form.create()(AddPackage)

export default WrappedAnnouncement
