import React, { Component } from 'react'
import PageHeaderWrapper from '../../components/PageHeaderWrapper'
import { TableComp } from 'sz-react-utils-lite'
import moment from 'moment'
import {
  Table,
  Pagination,
  Card,
  Tooltip,
  Tag,
  Popconfirm,
  notification,
  Select,
  Form,
  Input,
  Button,
  Icon,
  DatePicker,
  Spin
} from 'antd'
import Request from '../../request'
import Color from 'color'
import _ from 'lodash'
import Highlighter from 'react-highlight-words'
import styles from './styles.less'
import { connect } from 'react-redux'
import memoizeOne from 'memoize-one'
import update from 'immutability-helper'
import { getUrlPushWrapper, getPushPathWrapper } from '../../routes'
import { timeStampFormat } from '../../settings'
import csvDownload from '../../assets/download-csv.png'


const { RangePicker } = DatePicker

@Form.create()
class AllFarmers extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      total: 0,
      pagination: {},
      collectionDate: [moment().startOf('year'), moment()],
      exportCsvLoader:false
    }
    this.table = React.createRef()
  }
  deleteFarmer = async (id) => {
    this.setState({ loading: true })
    let x = await Request.deleteFarmer(id)
    this.setState({ loading: false })

    this.apiRequest()
    if (!x.error) {
      notification.success({
        message: x.message,
        btn: (
          <Button type="primary" onClick={() => this.undo(id)}>
            Undo
          </Button>
        ),
        key: `${id}-close`,
      })
    } else {
      notification.error({
        message: x.message,
        duration: 20,
        key: `${id}-close`,
      })
    }
  }
  undo = async (id) => {
    const response = await Request.restoreFarmer(id)
    notification.destroy()
    this.apiRequest()
  }
  apiRequest = (params, columns) => {
    return new Promise(async (resolve) => {
      const { selectedCompany, selectedMcp, selectedMcpGroup, collectionDate } =
        this.state
      const { currentUser } = this.props

      this.setState({
        dataSearchParams: params,
      })
      if (selectedCompany) params.companyId = selectedCompany
      if (selectedMcp) params.mcpId = selectedMcp
      if (collectionDate && collectionDate.length) {
        params.collectionDate = {
          $gte: collectionDate[0].startOf('day').format('YYYY-MM-DD HH:mm:ss'),
          $lt: collectionDate[1].endOf('day').format('YYYY-MM-DD HH:mm:ss'),
        }
      }
      let data = await Request.getCollections({
        ...params,
        company: true,
      })
      if (data.error) {
        return resolve({
          total: 0,
        })
      }

      this.setState({
        total: data.total,
      })

      resolve(data)
    })
  }

  mcpChildrens = []

  getMcpGroup = async () => {
    console.log(this.state.selectedCompany)
    let { data } = await Request.getAllGroup({
      companyId: this.state.selectedCompany,
    })
    console.log(data)
    this.mcpGroupChildrens = []
    if (data) {
      _.forEach(data, (mcc) => {
        this.mcpGroupChildrens.push(
          <Select.Option value={mcc.id} key={mcc.id}>
            {mcc.groupName}
          </Select.Option>
        )
      })
    }
  }

  getMcp = async () => {
    console.log(this.state.selectedCompany)
    console.log(this.state.selectedMcpGroup)
    let { data } = await Request.getAllmcp({
      companyId: this.state.selectedCompany,
      mcpGroupId: this.state.selectedMcpGroup,
    })
    console.log(data)
    this.mcpChildrens = []
    if (data) {
      _.forEach(data, (mcc) => {
        this.mcpChildrens.push(
          <Select.Option value={mcc.id} key={mcc.id}>
            {mcc.name}
          </Select.Option>
        )
      })
      this.forceUpdate()
    }
  }
  companyChildrens = []
  getCompanies = async () => {
    let { data } = await Request.listCompanies()
    if (data) {
      _.forEach(data, (company) => {
        this.companyChildrens.push(
          <Select.Option value={company.id} key={company.id}>
            {company.name}
          </Select.Option>
        )
      })
    }
  }
  componentDidMount() {
    this.getCompanies()
  }
  handleSearch = (selectedKeys, confirm) => {
    confirm()
    this.setState({ searchText: selectedKeys[0] })
  }
  handleReset = (clearFilters) => {
    clearFilters()
    this.setState({ searchText: '' })
  }
  onSelectChangeMcpGroup = (val) => {
    this.state.selectedMcpGroup = val
    this.mcpChildrens = []
    this.getMcp()
    this.props.form.resetFields('mcp')
  }
  onSelectChange = (val) => {
    this.state.selectedMcp = val
    this.table.current.reload()
  }
  onSelectChangeCompany = (val) => {
    this.state.selectedCompany = val
    this.getMcpGroup()
    this.table.current.reload()
    this.props.form.resetFields('mcpGroup')
  }
  onReset = () => {
    this.props.form.resetFields()
    this.state.selectedMcp = null
    this.state.selectedMcpGroup = null
    this.state.selectedCompany = null
    // this.table = React.createRef()
    this.state.collectionDate = [moment().startOf('year'), moment()]
    this.table.current.reload()
  }

  downloadCsvReport = async () => {
    return new Promise(async (resolve) => {
      const { dataSearchParams } = this.state

      this.setState({ ...this.state, exportCsvLoader: true })
      let data = await Request.exportCollectionsCsv({
        ...dataSearchParams,
        results: 100000000000000,
        count: 1000000000000000,
        regExFilters: ['farmerId', 'farmerName'],
        isSuperAdmin: true
      })
      this.setState({ ...this.state, exportCsvLoader: false })
      resolve(data)
    })
  }
  render() {
    const {
      submitting,
      dispatch,
      form: { getFieldDecorator },
    } = this.props
    const { dataSource, loading, pagination, total,exportCsvLoader } = this.state

    const columns = [
      {
        title: 'Collection Date',
        key: 'collectionDate',
        width: 140,
        dataIndex: 'collectionDate',
        sorter: true,

        fixed: 'left',
        // sorter: (a, b) => moment(a) - moment(b),
        render: (date) => {
          return <div>{moment(date).format('L')}</div>
        },
        // ...this.getColumnDateSearchProps('collectionDate')
      },
      {
        title: 'Farmer Id',
        key: 'farmerId',
        dataIndex: 'farmerId',
        sorter: true,
      },
      {
        title: 'Farmer Name',
        key: 'farmerName',
        dataIndex: 'farmerName',
      },
      {
        title: 'MCP',
        key: 'mcpId',
        dataIndex: 'mcpName',
      },
      {
        title: 'Company',
        key: 'company',
        dataIndex: 'companyName',
      },
      {
        title: 'Status',
        key: 'status',
        dataIndex: 'status',
        render: (val, record) => {
          return {
            children: (
              <Tag color={val.toUpperCase() == 'ACCEPTED' ? 'green' : 'red'}>
                {val}
              </Tag>
            ),
          }
        },
      },
      {
        title: 'Stage',
        key: 'stage',
        dataIndex: 'stage',
        render: (val) => <Tag color="blue">{val}</Tag>,
      },
      {
        title: 'FAT',
        key: 'fat',
        dataIndex: 'fat',
      },
      {
        title: 'SNF',
        key: 'snf',
        dataIndex: 'snf',
      },
      {
        title: 'Quantity',
        key: 'quantity',
        dataIndex: 'quantity',
        width: 90,
      },
      {
        title: 'Rate',
        key: 'rate',
        dataIndex: 'rate',
        // sorter: true,
        // ...this.getColumnSearchProps('weight')
      },
      {
        title: 'Total Amount',
        key: 'totalAmount',
        dataIndex: 'totalAmount',
        width: 150,
        // sorter: true,
        // ...this.getColumnSearchProps('weight')
      },
      {
        title: 'Time Stamp',
        key: 'timeStamp',
        dataIndex: 'createdOn',
        width: 150,
        fixed: 'right',
        render: (date) => {
          return date && moment(date).fromNow()
        },
        // sorter: true,
        // ...this.getColumnSearchProps('weight')
      },
    ]
    return (
      <PageHeaderWrapper title={`All Collections (${total})`}>
        <Card bordered={true}
         extra={
          <div className={styles.title}>
            <Spin size="small" spinning={exportCsvLoader}>
              <input
                type="image"
                title="Download CSV"
                className={styles.exportImg}
                src={csvDownload}
                onClick={this.downloadCsvReport}
                disabled={exportCsvLoader}
              />
            </Spin>
          </div>
        }
        >

          <div style={{ marginBottom: '10px' }} className={styles.filter}>
            <div style={{ display: 'flex' }}>
              <RangePicker
                allowClear={false}
                id={'rangePicker'}
                style={{ marginRight: '15px' }}
                defaultValue={this.state.collectionDate}
                value={this.state.collectionDate}
                onChange={(date) => {
                  this.setState({ collectionDate: date, isFilter: true }, () =>
                    this.table.current.reload()
                  )
                }}
              />

              <Form layout={'inline'} style={{ marginTop: -4 }}>
                <Form.Item>
                  {getFieldDecorator('company')(
                    <Select
                      style={{ width: '200px' }}
                      placeholder="Company Filter"
                      onChange={this.onSelectChangeCompany}>
                      {this.companyChildrens}
                    </Select>
                  )}
                </Form.Item>
                <Form.Item>
                  {getFieldDecorator('mcpGroup')(
                    <Select
                      style={{ width: '200px' }}
                      disabled={!this.state.selectedCompany}
                      placeholder="MCP Group Filter"
                      onChange={this.onSelectChangeMcpGroup}>
                      {this.mcpGroupChildrens}
                    </Select>
                  )}
                </Form.Item>
                <Form.Item>
                  {console.log('aaaaaaaaaaaaaaaaaaaa', this.mcpChildrens)}
                  {getFieldDecorator('mcp')(
                    <Select
                      style={{ width: '200px' }}
                      disabled={!this.state.selectedMcpGroup}
                      placeholder="MCP Filter"
                      onChange={this.onSelectChange}>
                      {this.mcpChildrens}
                    </Select>
                  )}
                </Form.Item>
              </Form>
            </div>
            <Button onClick={this.onReset}>Reset</Button>
          </div>
          <TableComp
            id={'allCollection'}
            columns={columns}
            ref={this.table}
            apiRequest={(params) => this.apiRequest(params, columns)}
            extraProps={{
              scroll: { x: true },
            }}
            pagination={{
              showSizeChanger: true,
              defaultPageSize: 20,
              pageSizeOptions: ['10', '20', '50', '100', '1000'],
            }}
          />
        </Card>
      </PageHeaderWrapper>
    )
  }
}

const mapStateToProps = ({ global }) => ({
  categories: global.categories,
  currentUser: global.currentUser,
})
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AllFarmers)
