import React, { Component } from 'react'
import { Tag } from 'antd'
import Request from '../../request'
import styles from './style.less'
import { TableComp } from 'sz-react-utils-lite'
import moment from 'moment'
class Dashboard extends Component {
  constructor(props) {
    super(props)
  }
  state = {
    companiesCount: 0,
  }
  componentDidMount() {
    this.apiRequest()
  }

  getOnlineMcps = async () => {
    return new Promise(async (resolve) => {
      let data = await Request.fetchOnlineMcps()
      if (data && !data.error) {
        this.setState({
          ...this.state,
          totalOnline: data.total,
        })
        return resolve({
          data: data.data,
          total: data.total,
        })
      } else {
        return resolve({
          data: [],
          total: 0,
        })
      }
    })
  }

  apiRequest = async () => {
    Request.countCompanies().then((data) => {
      let companiesCount = data.total
      this.setState({ companiesCount })
    })
    /* Request.listDevices()
      .then(data => {
        devices = data.data
        this.setState({ devices })
      })
    Request.listTickets()
      .then(data => {
        tickets = data.data
        this.setState({ tickets })
      }) */
  }
  render() {
    const { tickets, devices, companiesCount, totalOnline } = this.state
    const columns = [
      {
        title: 'MCP Name',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Online Since',
        dataIndex: 'lastSeen',
        key: 'lastSeen',
        render: (val) => (
          <Tag color="green">{moment(val).fromNow()}</Tag>
        ),
      },
    ]
    return (
      <div>
        <div className={styles.wrapper}>
          <div className={styles.description}>
            <div className={styles.descriptionItem}>
              <span className={styles.label}>
                <b>Total Companies: -</b>
              </span>
              <div>{companiesCount}</div>
            </div>
            <div className={styles.descriptionItem}>
              <span className={styles.label}>
                <b>Total Devices: -</b>
              </span>
              <div>{devices ? devices.length : 0}</div>
            </div>
            <div className={styles.descriptionItem}>
              <span className={styles.label}>
                <b>Tickets: -</b>
              </span>
              <div>{tickets ? tickets.length : 0}</div>
            </div>
          </div>
        </div>

        <div className={styles.onlineWrapper}>
          <div className={styles.onlineMcps}>
            <p className={styles.tableHeading}>
              Online Mcps: {totalOnline || 0}
            </p>
            <TableComp
              // ref={this.table}
              columns={columns}
              apiRequest={(params) => this.getOnlineMcps(params, columns)}
              pagination={{
                showSizeChanger: false,
              }}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default Dashboard
