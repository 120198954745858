import { apiUrl } from "../../../settings";

export const addIpUrl = () => {
    return apiUrl + '/whitelistIp';
};
export const updateIpUrl = (id) => {
    return apiUrl + '/whitelistIp/' + id;
};
export const deleteIpUrl = (id) => {
    return apiUrl + '/whitelistIp/' + id;
};

export const listIpUrl = () => {
    return apiUrl + '/whitelistIp';
};