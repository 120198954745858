import React, { PureComponent } from 'react'
import PageHeaderWrapper from '../PageHeaderWrapper'
import styles from './styles.less'
import { Spin } from 'antd'
import _ from 'lodash'

import moment from 'moment'
import { FormUtils as GetAllFormFields } from 'sz-react-utils-lite'
import { API_URL } from '../../request'
//import GetAllFormFields from '../_utils/formUtils';
import { getUrlParams } from '../../routes'


import { notification } from 'antd/lib'
import { hideLoader, showLoader } from '../../modules/actions'
import Request from '../../request'
import { connect } from 'react-redux'

class FarmerTitle extends PureComponent {

  state = {
    cardLoading: false,
    allTemplates: [],
    key: moment(),
    loading: false
  }


  componentDidMount() {
    this.props.setDefaultSettings(this.apiRequest.bind(this))
    this.apiRequest()
  }

  apiRequest = async () => {
    let id = getUrlParams('mcp.editMcc', this.props.pathname)
    const { currentUser, form, companyId, settingLevel } = this.props
    this.setState({ loading: true })
    let filter = { level: settingLevel, companyId }
    if (settingLevel == 'MCP' && id && id.id)
      filter.mcpId = id.id
    else
      filter.level = 'COMPANY'
    let data = await Request.getFarmerTitleSetting(filter)
    this.setState({ loading: false })
    // let data = await Request.getSetting(currentUser.companyId)
    if (data && data.data) {
      form.setFieldsValue({
        farmerTitle: data.data.title
      })
    } else {
      form.setFieldsValue({
        farmerTitle: 'FARMER'
      })
    }

  }

  render() {
    const { key, allTemplates } = this.state
    const { submitting } = this.props
    const {
      form: { getFieldDecorator, getFieldValue, setFieldsValue },
      setInputFields,
      settingLevel,
      isCompany
    } = this.props


    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
        md: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
        md: { span: 12 }
      }
    }


    const inputType = {
      fields: [
        {
          label: 'Farmer Title',
          key: 'farmerTitle',
          // dataIndex: 'farmerTitle',
          required: true,
        },
      ]
    }
    setInputFields(inputType.fields.map(field => field.key), 4)


    return (
      <div className={styles.container}>
        {settingLevel === 'COMPANY' && !isCompany ?
          <h4 style={{ color: 'blue' }}>{'NOTE :This MCP has company level settings'}</h4>
          : <Spin tip={'Loading...'} spinning={this.state.loading}>
            <GetAllFormFields
              apiurl={API_URL}
              inputSchema={inputType}
              formItemLayout={formItemLayout}
              getFieldDecorator={getFieldDecorator}
            />
          </Spin>}
      </div>

    )
  }
}

const mapStateToProps = ({ global, router }) => ({
  loading: global.buttonLoading,
  categories: global.categories,
  search: router.location.search,
  currentUser: global.currentUser,
  pathname: router.location.pathname

})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FarmerTitle)
