import { Auth } from 'aws-amplify'
export const cognitoRegister = async (valData) => {
    let attributes = {
        email: valData.email,
        "custom:userType": valData.userType,
        "custom:name": valData.name,
        "custom:mobile": valData.mobile.toString(),
        "custom:companyId": valData.companyId,
        "custom:id": valData.id
    }
    if (valData.suspentionTime) {
        attributes["custom:suspentionTime"] = valData.suspentionTime
    }
    if (valData.mcpGroups) {
        attributes["custom:mcpGroups"] = valData.mcpGroups.join(',')
    }
    if (!valData.mcpGroups)
        valData.mcpGroups = []
    try {
        const newUser = await Auth.signUp({
            username: valData.email,
            password: valData.password,
            attributes
        });
        return ({ error: false, success: true, data: newUser })
    } catch (e) {
        return ({ error: true, success: false, err: e, message: e.message })
    }
}