import React, { Component } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { getPushPathWrapper } from '../../../routes'
import { TableComp } from 'sz-react-utils-lite'
import { timeStampFormat, qrCodeUrl } from '../../../settings'
import moment from 'moment'
import {
    Table,
    Badge,
    Card,
    Tooltip,
    Drawer,
    notification,
    Switch,
    Form,
    Tag,
    Input, Button, Icon
} from 'antd'
import Request from '../../../request'
import _ from 'lodash'
import Highlighter from 'react-highlight-words'
import { connect } from 'react-redux'
import memoizeOne from 'memoize-one'
import update from 'immutability-helper'
import styles from './style.less'
import { hideLoader, showLoader } from '../../../modules/actions'
import AddUser from '../../user/add'

import capitalize from 'capitalize'
var async = require("async")
@Form.create()
class listCompanies extends Component {

    columns = [
        {
            title: 'Company Name',
            dataIndex: 'name',
            key: 'name',
            fixed: 'left',
            width: 150,
            // ...this.getColumnSearchProps('name')

            searchTextName: 'name'
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
            align: 'center',
        },
        {
            title: 'E-mail',
            dataIndex: 'email',
            key: 'email',
            align: 'center',
            searchTextName: 'email'

            // ...this.getColumnSearchProps('email')

        },
        {
            title: 'Mobile',
            dataIndex: 'mobile',
            key: 'mobile',
            align: 'center',
            searchTextName: 'mobile'

            // ...this.getColumnSearchProps('mobile')

        },
        {
            title: 'Users',
            key: 'users',
            dataIndex: 'users',
            render: (val, record) => {
                return (
                    <Button onClick={() => { this.showDrawer(record.users || []) }}>
                        {record.users ? record.users.length : 0} Users
                    </Button>
                )
            }
        },
        {
            title: 'Total MCP Groups',
            dataIndex: 'totalMcpGroups',
            key: 'totalMcpGroups',
            align: 'center',
            // ...this.getColumnSearchProps('discount')
        },
        {
            title: 'Total MCPs',
            dataIndex: 'totalMcps',
            key: 'totalMcps',
            align: 'center',
            sorter: true
            // ...this.getColumnSearchProps('discount')
        },
        {
            title: 'Total Farmers',
            dataIndex: 'totalFarmers',
            key: 'totalFarmers',
            align: 'center',
            sorter: true
            // ...this.getColumnSearchProps('discount')
        },
        {
            title: 'Total Collections',
            dataIndex: 'totalCollections',
            key: 'totalCollections',
            align: 'center',
            sorter: true
            // ...this.getColumnSearchProps('discount')
        },
        {
            title: 'Gst No.',
            dataIndex: 'gstNo',
            key: 'gstNo',
            align: 'center',
            // ...this.getColumnSearchProps('branchArray')
        },
        {
            title: 'Created On',
            dataIndex: 'createdOn',
            key: 'createdOn',
            align: 'center',
            sorter: true,
            render:(val, record) =>{
                return <Tag>{moment(val).format(timeStampFormat)}</Tag>
            }
            // ...this.getColumnSearchProps('branchArray')
        },
        {
            title: 'Suspension Time',
            dataIndex: 'suspentionTime',
            key: 'suspentionTime',
            align: 'center',
            sorter: true,
            render:(val, record) =>{
                return <Tag>{moment(val).format(timeStampFormat)}</Tag>
            }
            // ...this.getColumnSearchProps('branchArray')
        },
        {
            title: 'Action',
            dataIndex: 'id',
            key: 'action',
            align: 'center',
            fixed: 'right',
            width: 150,
            render: (val) => {
                return (
                    <div>
                        <Button shape="circle" icon="user" id={'addUser'} className={styles.iconBtn} onClick={() => this.toogleUserDrawer(val)} />
                        <Button shape="circle" icon="edit" id={'edit'} className={styles.iconBtn} onClick={() => this.editCompany(val)} />
                        <Button shape="circle" icon="delete" loading={this.state.deleting} type='danger' id={'delete'} className={styles.iconBtn} onClick={() => this.deleteCompany(val)} />
                    </div>
                )
            }
        }
    ];

    state = {
        tableLoading: false,
        loading: false,
        dataSource: [],
        total: 0,
        userData: '',
        userDrawer: {
            showDrawer: false,
            companyId: ''
        }
    }

    constructor(props) {
        super(props)
        this.table = React.createRef()
        if (props.currentUser.userType === 'subAdmin') {
            this.columns.splice(-1, 1)
        }
    }
    
    async componentDidMount() {
        let data1 = await Request.listSuspendedCompanies({
            // details: true,
            // regExFilters: ['name', 'email', 'mobile']
        })
        console.log(data1)
    }
    apiRequest = (params) => {

        return new Promise(async resolve => {

            const { collectionDate, mcp, collectionTime } = this.state
            const { currentUser } = this.props
            this.setState({ userDrawer: { companyId: '', showDrawer: false } })
            this.setState({
                dataSearchParams: params
            })
            console.log(params)
            let data = await Request.listCompanies({
                ...params,
                details: true,
                regExFilters: ['name', 'email', 'mobile']
            })
            if (data.error) {
                return resolve({
                    total: 0
                })
            }

            this.setState({
                total: data.total
            })
            console.log(data)
            resolve(data)
        })
    }
    handleSubmit = (e) => {
        const { dispatch, form } = this.props
        e.preventDefault()
        form.validateFieldsAndScroll(async (err, valData) => {
            if (!err) {
                dispatch(showLoader())
                let x = await Request.updateUser(this.state.selectedUser.id, valData)

                if (!x.error) {
                    notification.success({
                        message: x.message
                    })
                    this.setState({
                        childrenDrawer: false,
                        visibles: false
                    })
                    dispatch(hideLoader())
                    this.props.form.resetFields()

                }
                else {
                    dispatch(hideLoader())
                    notification.error({
                        message: 'Error Saving',
                        description: x.message
                    })
                }
            }
        })
    }
    handleChange = (pagination, filters, sorter) => {
        console.log('Various parameters', pagination, filters, sorter);
        this.setState({
            filteredInfo: filters,
        });
        this.apiRequest({
            results: pagination.pageSize,
            page: pagination.current,
            sortField: sorter.field,
            sortOrder: sorter.order,
            filters
        })
    };

    showChildrenDrawer = (data) => {
        this.props.form.setFieldsValue({ email: data.email })
        this.setState({
            selectedUser: data,
            childrenDrawer: true,
        });
    };
    onChildrenDrawerClose = () => {
        this.setState({
            childrenDrawer: false,
        });
    };
    showDrawer = (data) => {
        this.setState({
            userData: data,
            visibles: true,
        });
    };
    toogleUserDrawer = (compId) => {
        let { userDrawer: { showDrawer, companyId } } = this.state
        showDrawer = !showDrawer
        companyId = compId
        this.setState({ userDrawer: { showDrawer, companyId } })
    }
    onClose1 = () => {
        this.setState({
            visibles: false,
        })
    };
    handleSearch = (selectedKeys, confirm) => {
        confirm();
        this.setState({ searchText: selectedKeys[0] });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };
    deleteCompany = async (id) => {
        this.setState({ deleting: true })
        const response = await Request.deleteCompany(id)
        this.setState({ deleting: false })

        if (!response.error) {
            notification.success({
                message: capitalize.words(response.message),
                btn: <Button type='primary' onClick={() => this.undo(id)}>Undo</Button>
            })
            this.table.current.reload(true)
        } else {
            notification.error({
                message: 'Error Saving',
                description: capitalize.words(response.message)
            })
        }
    }
    undo = async (id) => {
        Request.restoreCompany(id)
            .then(resp => {
                this.table.current.reload(true)
            })
        notification.destroy()
    }
    editCompany = async (id) => {
        this.props.dispatch(getPushPathWrapper('company.updateCompany', { id }))
    }
    render() {
        const { tableLoading, deleting, loading, userData, selectedUser, userDrawer, total } = this.state
        const {
            form: { getFieldDecorator }
        } = this.props
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
                md: { span: 8 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
                md: { span: 12 }
            }
        }
        const submitFormLayout = {
            wrapperCol: {
                xs: { span: 24, offset: 0 },
                sm: { span: 10, offset: 7 },
                md: { span: 12, offset: 8 }
            }
        }

        return (
            <PageHeaderWrapper
                title={`All Companies (${total})`}>
                <Card id={'listCompanies'} loading={loading}>
                    <TableComp
                        id={'allCompany'}
                        columns={this.columns}
                        ref={this.table}
                        apiRequest={params => this.apiRequest(params)}
                        extraProps={{
                            scroll: { x: true },
                            rowKey: (row) => row.id
                        }}
                        pagination={{
                            showSizeChanger: true,
                            defaultPageSize: 20,
                            pageSizeOptions: ['10', '20', '50', '100', '1000']
                        }}
                    />
                </Card>
                <Drawer
                    title={`Total Users (${userData.length})`}
                    width={520}
                    closable={false}
                    onClose={this.onClose1}
                    visible={this.state.visibles}
                >
                    {userData && userData.map(data => {
                        return <Card>

                            <b>Name:</b> {data.name} <br />
                            <b>Email Id:</b> {data.email}
                            <Button style={{ float: 'right' }} onClick={() => { this.showChildrenDrawer(data) }}>Reset Password</Button>
                        </Card>
                    })}
                    <Drawer
                        title="RESET PASSWORD"
                        width={420}
                        closable={false}
                        onClose={this.onChildrenDrawerClose}
                        visible={this.state.childrenDrawer}
                    >
                        <Form
                            {...formItemLayout}
                            onSubmit={this.handleSubmit}
                            hideRequiredMark
                            style={{ marginTop: 8 }}>

                            <Form.Item label='email'>{
                                getFieldDecorator('email')(
                                    <Input type='email'
                                        disabled={true}
                                    />
                                )
                            }</Form.Item>
                            <Form.Item label='Password'>{
                                getFieldDecorator('password', {
                                    rules: [{ required: true }]
                                })(
                                    <Input type='password' />
                                )
                            }</Form.Item>
                            <Form.Item {...submitFormLayout} style={{ marginTop: 32 }}>
                                <Button
                                    id={'submits'}
                                    type="primary"
                                    htmlType="submit"
                                    loading={this.props.loading}>
                                    RESET
                                </Button>
                            </Form.Item>
                        </Form>
                    </Drawer>
                    <div
                        style={{
                            position: 'absolute',
                            bottom: 0,
                            width: '100%',
                            borderTop: '1px solid #e8e8e8',
                            padding: '10px 16px',
                            textAlign: 'right',
                            left: 0,
                            background: '#fff',
                            borderRadius: '0 0 4px 4px',
                        }}
                    >
                        <Button onClick={this.onClose1} type="primary">
                            Back
                            </Button>
                    </div>
                </Drawer>
                <Drawer
                    // title="Add User"
                    placement={'right'}
                    width={500}
                    closable={false}
                    onClose={() => { this.toogleUserDrawer('') }}
                    visible={userDrawer.showDrawer}
                >
                    {this.table.current && <AddUser company={userDrawer.companyId} afterAdd={this.table.current.reload} />}
                </Drawer>

            </PageHeaderWrapper >)

    }
}


const mapStateToProps = ({ global }) => ({
    categories: global.categories,
    currentUser: global.currentUser,
    loading: global.buttonLoading

})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(listCompanies)
