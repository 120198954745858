import React, { PureComponent } from 'react'
import PageHeaderWrapper from '../../../../components/PageHeaderWrapper'
import {
    Form,
    Select,
    Button,
    Card,
    InputNumber,
    Input,
} from 'antd'
import _ from 'lodash'
import 'react-quill/dist/quill.snow.css'
import { notification } from 'antd/lib/index'
import { hideLoader, showLoader } from '../../../../modules/actions'
import Request from '../../../../request'
import { connect } from 'react-redux'
import { getUrlParams } from '../../../../routes'
import FormItem from '../../../../components/FormItem'
const { Option } = Select

@Form.create()
class addMcpUser extends PureComponent {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            companies: []
        }
    }

    componentDidMount() {
        this.getChildrens()
        let data = getUrlParams('mcpGroup.editMcpGroup', this.props.pathname)
        if (data && data.id) {
            this.setState({
                loading: true,
                edit: true,
                id: data.id
            }, () => {
                this.setFormValues(data.id)
            })
        }
    }
    getChildrens = async () => {
        let data = await Request.listCompanies()
        if (!data.error) {
            this.setState({ companies: data.data })
        }
    }
    handleSubmit = e => {
        const { dispatch, form } = this.props
        e.preventDefault()
        form.validateFieldsAndScroll(async (err, valData) => {
            if (!err) {
                dispatch(showLoader())
                console.log(valData)
                let x = this.state.edit ? await Request.updateMcpGroup(this.state.id, valData) : await Request.addMcpGroup(valData)
                dispatch(hideLoader())
                console.log(x)
                if (!x.error) {
                    notification.success({
                        message: x.message
                    })
                    !this.state.edit && this.props.form.resetFields()
                }
                else {
                    notification.error({
                        message: 'Error Saving',
                        description: x.message
                    })
                }
            }

        })
    }
    setFormValues = async (id) => {
        this.setState({ loading: true })
        let data = await Request.getMcpGroupById(id)
        this.setState({ loading: false })
        if (data.error || !data.data) {
            notification.error({
                message: 'Something Went Wrong'
            })
            return false;
        }
        console.log(data)
        let obj = {
            groupName: data.data.groupName,
            companyId: data.data.companyId && data.data.companyId.id
        }
        this.props.form.setFieldsValue(obj)
    }

    render() {
        const {
            form: { getFieldDecorator, getFieldValue, setFields },
            loading
        } = this.props
        const { loading: cardLoading, edit, companies } = this.state
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
                md: { span: 8 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
                md: { span: 12 }
            }
        }

        const submitFormLayout = {
            wrapperCol: {
                xs: { span: 24, offset: 0 },
                sm: { span: 10, offset: 7 },
                md: { span: 12, offset: 8 }
            }
        }
        const validateRule = {
            rules: [{ required: true, message: 'Required field' }]
        }
        const fIAll = {
            getFieldDecorator,
            validateRule
        }
        const fIAll1 = {
            getFieldDecorator
        }
        return (
            <Card id={'primaryDetails'} title='MCP Group' loading={cardLoading}>
                <Form {...formItemLayout} onSubmit={this.handleSubmit} >
                    <Form.Item label='Company'>{
                        getFieldDecorator('companyId', {
                            rules: [{ required: true }]
                        })(
                            <Select>{
                                companies.map(val => (
                                    < Option value={val.id} > {val.name}</Option>
                                ))
                            }</Select>
                        )
                    }</Form.Item>
                    <FormItem
                        {...fIAll}
                        {...formItemLayout}
                        name='groupName'
                    />
                    <Form.Item {...submitFormLayout}>
                        <Button type='primary' htmlType='submit' loading={loading} >{edit ? 'update' : 'Add'}</Button>
                    </Form.Item>
                </Form>
            </Card>

        )
    }
}

const mapStateToProps = ({ global, router }) => ({
    loading: global.buttonLoading,
    categories: global.categories,
    search: router.location.search,
    pathname: router.location.pathname,
    currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(addMcpUser)
