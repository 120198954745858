export default {}

export const pageTitle = 'Mobile AMCU '
export const subTitle = 'Powered by Scizers IOT Pvt Ltd'
// Production Server
// export const apiUrl = 'https://api.amcu.in'
// export const socketUrl = 'https://api.amcu.in'



let apiUrlXX = 'http://localhost:8349'
let socketUrlXX = 'http://localhost:8349'
//  let apiUrlXX = 'https://api.amcu.in'
//  let socketUrlXX = 'https://api.amcu.in'

if (process.env.NODE_ENV == 'production') {
  apiUrlXX = 'https://api.amcu.in'
  socketUrlXX = 'https://api.amcu.in'
}

export const apiUrl = apiUrlXX
export const socketUrl = socketUrlXX


export const timeStampFormat='DD/MM/YYYY HH:mm:ss'
export const dateFormat='DD/MM/YYYY'
export const cognito = {
    'region': 'us-west-2',
    'userPoolId': 'us-west-2_21q20Cq3g',
    'userPoolWebClientId': '6duq5gq7n6nm1k1lgh1m7cllad'
}
