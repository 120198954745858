import axios from "axios";
import { addPackageUrl, listPackageUrl, updatePackageUrl } from "../api/packageUrl";
import { notification } from 'antd'
import { useSelector } from "react-redux";
import { getToken } from "../../../request";

export const AddPackageAction = (valData) => async (dispatch) => {
    dispatch({ type: 'PACKAGE_BTN_SHOW_LOADER' });

    await axios
        .post(addPackageUrl(), valData, getToken())
        .then(function ({ data: x }) {
            dispatch({ type: 'PACKAGE_BTN_HIDE_LOADER' });

            if (!x.error) {
                notification.success({
                    message: x.message

                })
                dispatch({ type: 'PACKAGE_RESPONSE', payload: x })
            } else {
                notification.error({
                    message: 'Error Saving',
                    description: x.message
                })
            }
        })
        .catch(function (error) {
            // toast.error(JSON.stringify(error));
            dispatch({ type: 'PACKAGE_BTN_HIDE_LOADER' });
        });
};
export const UpdatePackageAction = (id, valData) => async (dispatch) => {
    dispatch({ type: 'PACKAGE_BTN_SHOW_LOADER' });

    await axios
        .put(updatePackageUrl(id), valData, getToken())
        .then(function ({ data: x }) {
            dispatch({ type: 'PACKAGE_BTN_HIDE_LOADER' });

            if (!x.error) {
                notification.success({
                    message: x.message

                })
                dispatch({ type: 'PACKAGE_RESPONSE', payload: x })
            } else {
                notification.error({
                    message: 'Error Saving',
                    description: x.message
                })
            }
        })
        .catch(function (error) {
            // toast.error(JSON.stringify(error));
            dispatch({ type: 'PACKAGE_BTN_HIDE_LOADER' });
        });
};
export const DeletePackageAction = (id) => async (dispatch) => {
    dispatch({ type: 'PACKAGE_SHOW_LOADER' });

    await axios
        .delete(updatePackageUrl(id), getToken())
        .then(function ({ data: x }) {
            dispatch({ type: 'PACKAGE_HIDE_LOADER' });

            if (!x.error) {
                notification.success({
                    message: x.message

                })
                dispatch({ type: 'PACKAGE_RESPONSE', payload: x })
            } else {
                notification.error({
                    message: 'Error Saving',
                    description: x.message
                })
            }
        })
        .catch(function (error) {
            // toast.error(JSON.stringify(error));
            dispatch({ type: 'PACKAGE_HIDE_LOADER' });
        });
};
export const ListPackage = (inputData) => async (dispatch) => {
    dispatch({ type: 'PACKAGE_SHOW_LOADER' });
    await axios
        .get(listPackageUrl(), { params: { ...inputData }, ...getToken() })
        .then(function ({ data }) {
            dispatch({ type: 'PACKAGE_HIDE_LOADER' });
            if (data.error) {
                notification.error({
                    message: data.message,
                })
            }
            else
                dispatch({
                    type: 'PACKAGE_LIST',
                    payload: data,
                });
        })
        .catch(function (error) {
            // toast.error(JSON.stringify(error));
            dispatch({ type: 'PACKAGE_HIDE_LOADER' });
        });
};