import React, { PureComponent } from 'react'
import PageHeaderWrapper from '../../components/PageHeaderWrapper'
import Highlighter from 'react-highlight-words'
import {
    Icon,
    Select,
    Button,
    Input,
    Table,
    Card,
    Tag,
    Descriptions,
    DatePicker,
    Switch,
    Form
} from 'antd'
import _ from 'lodash'
import moment, { relativeTimeRounding } from 'moment'
import { notification } from 'antd/lib'
import { hideLoader, showLoader } from '../../modules/actions'
import Request from '../../request'
import { connect } from 'react-redux'
import { getPushPathWrapper } from '../../routes'
import styles from './styles.less'
import Barcode from 'react-barcode'
import { apiUrl } from '../../settings'
const { RangePicker } = DatePicker
const { Option } = Select

@Form.create()
class AllPayments extends React.Component {
    state = {
        dataSource: [],
        pagination: {},
        mcps: [],
        searchText: '',
        tableLoading: false,
        loading: false,
        dateFilters: {},
        liveReload: true
    }
    componentDidMount() {
        this.getCompanies()
        this.apiRequest()
    }
    companyChildrens = []
    getCompanies = async () => {
        let { data } = await Request.listCompanies()
        if (data) {
            _.forEach(data, (company) => {
                this.companyChildrens.push(
                    <Select.Option value={company.id} key={company.id}>{company.name}</Select.Option>
                )
            })
        }
    }
    getMcps = async () => {
        let mcps = await Request.getAllMcp({ companyId: this.state.selectedCompany })
        if (!mcps.error) {
            this.setState({ mcps: mcps.data })
        }
    }
    apiRequest = async (params = {}) => {
        this.setState({ tableLoading: true })
        let data = await Request.getPayments({
            companyId: this.props.currentUser.companyId,
            ...params.filters,
            ...params
        })
        const pagination = { ...this.state.pagination }
        this.setState({
            loading: false,
            dataSource: data.data || [],
            pagination,
            tableLoading: false
        })
    }
    getColumnSearchProps = (dataIndex, index) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters
        }) => (
                <div style={{ padding: 8 }}>
                    <Input
                        className={`search-value-${dataIndex}`}
                        ref={node => {
                            this.searchInput = node
                        }}
                        placeholder={`Search ${index || dataIndex}`}
                        value={selectedKeys[0]}
                        onChange={e =>
                            setSelectedKeys(e.target.value ? [e.target.value] : [])
                        }
                        onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                    />
                    <Button
                        className={`search-${dataIndex}`}
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm)}
                        icon="search"
                        size="small"
                        style={{ width: 90, marginRight: 8 }}>
                        Search
        </Button>
                    <Button
                        className={`reset-${dataIndex}`}
                        onClick={() => this.handleReset(clearFilters)}
                        size="small"
                        style={{ width: 90 }}>
                        Reset
        </Button>
                </div>
            ),
        filterIcon: filtered => (
            <Icon
                className={`filter-menu-${dataIndex}`}
                type="search"
                style={{ color: filtered ? '#1890ff' : undefined }}
            />
        ),
        onFilter: (value, record) =>
            index
                ? record[dataIndex][index]
                    .toString()
                    .toLowerCase()
                    .includes(value.toLowerCase())
                : record[dataIndex]
                    .toString()
                    .toLowerCase()
                    .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select())
            }
        },
        render: text => (
            <Highlighter
                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                searchWords={[this.state.searchText]}
                autoEscape
                textToHighlight={text && text.toString()}
            />
        )
    })
    getColumnDateSearchProps = dataIndex => ({
        filterDropdown: pro => {
            let { setSelectedKeys, selectedKeys, confirm, clearFilters } = pro
            return (
                <div
                    style={{
                        padding: '8px',
                        borderRadius: '4px',
                        backgroundColor: '#ffffff',
                        boxShadow: '0 2px 8px rgba(0, 0, 0, .15)'
                    }}>
                    <RangePicker
                        className={`search-value-${dataIndex}`}
                        style={{ width: 250, marginBottom: 8, display: 'block' }}
                        ref={node => {
                            this.searchInput = node
                        }}
                        onChange={date => {
                            console.log(date[0])
                            setSelectedKeys({
                                $gte: date[0].startOf('day').toDate(),
                                $lt: date[1].endOf('day').toDate()
                            })
                        }}
                    />

                    <div style={{ flex: 1, justifyContent: 'flex-end' }}>
                        <Button
                            className={`search-${dataIndex}`}
                            type="primary"
                            onClick={() => {
                                let dateFilters = _.clone(this.state.dateFilters)

                                dateFilters[dataIndex] = true

                                this.setState(
                                    {
                                        dateFilters
                                    },
                                    () => {
                                        console.log(dateFilters)
                                        this.handleSearch(selectedKeys, confirm)
                                        confirm()
                                    }
                                )
                            }}
                            icon="search"
                            size="small"
                            style={{ width: 90, marginRight: 8 }}>
                            Search
            </Button>
                        <Button
                            className={`reset-${dataIndex}`}
                            onClick={() => {
                                let dateFilters = _.clone(this.state.dateFilters)

                                dateFilters[dataIndex] = false

                                this.setState({
                                    dateFilters
                                })
                                clearFilters()
                            }}
                            size="small"
                            style={{ width: 90 }}>
                            Reset
            </Button>
                    </div>
                </div>
            )
        },
        filterIcon: x => {
            let { dateFilters } = this.state
            let filtered = dateFilters && dateFilters[dataIndex]
            return (
                <Icon
                    className={`filter-menu-${dataIndex}`}
                    type="search"
                    style={{ color: filtered ? '#1890ff' : undefined }}
                />
            )
        },
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.focus())
            }
        }
    })
    handleSearch = (selectedKeys, confirm) => {
        console.log(selectedKeys, confirm)
        confirm()
        this.setState({ searchText: selectedKeys[0] })
    }
    handleReset = clearFilters => {
        clearFilters()
        this.setState({ searchText: '' })
    }
    handleTableChange = (pagination, filters, sorter) => {
        console.log(filters, sorter)
        _.forEach(filters, (val, key) => {
            if (filters[key].length == 0) delete filters[key]
        })
        const pager = { ...this.state.pagination }
        pager.current = pagination.current
        this.setState({
            pagination: pager,
            filteredInfo: { ...filters }
        })
        this.apiRequest({
            sortField: sorter.field,
            sortOrder: sorter.order,
            filters
        })
    }
    onSelectChangeCompany = (val) => {
        this.state.selectedCompany = val
        this.getMcps()
        this.apiRequest({ companyId: val }, true)
        this.props.form.resetFields('mcpId')
    }
    onSelectChange = (val) => {
        this.state.selectedMcp = val
        this.apiRequest({ companyId: this.state.selectedCompany, mcpId: val }, true)
    }
    onReset = () => {
        this.props.form.resetFields()
        this.state.selectedMcp = null
        this.state.selectedCompany = null
        this.apiRequest()
    }
    render() {
        const columns = [
            {
                title: 'Payment Date',
                key: 'paymentDate',
                width: 140,
                dataIndex: 'paymentDate',
                // sorter: (a, b) => moment(a) - moment(b),
                render: date => {
                    return <div>{moment(date).format('L')}</div>
                },
                ...this.getColumnDateSearchProps('paymentDate')
            },
            {
                title: 'Farmer Id',
                key: 'farmerId',
                dataIndex: 'farmerId.farmerId',
                sorter: true,
                ...this.getColumnSearchProps('farmerId')
            },
            {
                title: 'Farmer Name',
                key: 'farmerName',
                dataIndex: 'farmerName',
                ...this.getColumnSearchProps('farmerName')
            },
            {
                title: 'Amount',
                key: 'amount',
                dataIndex: 'paidAmount',
                ...this.getColumnSearchProps('paidAmount')
            }
        ]
        const { tableLoading, loading, dataSource, pagination, mcps } = this.state
        const { form: { getFieldDecorator, resetFields } } = this.props

        return (
            <Card
                bordered={true}
                id={'list'}
                title={
                    <div>Payments : {dataSource.length}</div>}>
                <>
                    <div style={{ marginBottom: '10px' }} className={styles.filter}>
                        <div>
                            <Form layout={'inline'}>
                                <Form.Item>
                                    {getFieldDecorator('companyId')(
                                        <Select
                                            style={{ width: '200px' }}
                                            placeholder='Company Filter'
                                            onChange={this.onSelectChangeCompany}
                                        >
                                            {this.companyChildrens}
                                        </Select>
                                    )}
                                </Form.Item>
                                <Form.Item>
                                    {getFieldDecorator('mcpId')(
                                        <Select
                                            style={{ width: '200px' }}
                                            disabled={!this.state.selectedCompany}
                                            placeholder='MCP Filter'
                                            onChange={this.onSelectChange}
                                        >
                                            {mcps.map(mcp => <Option value={mcp.id} key={mcp.id}>{mcp.name}</Option>)}
                                        </Select>
                                    )}
                                </Form.Item>
                            </Form>
                        </div>
                        <Button onClick={this.onReset}>Reset</Button>
                    </div>
                    <Table
                        size="small"
                        bordered
                        id={'payment-table'}
                        rowKey={record => record.id}
                        columns={columns}
                        pagination={{
                            ...this.state.pagination,
                            defaultPageSize: 1000,
                            pageSizeOptions: ['20', '50', '100', '1000'],
                            showSizeChanger: true,
                            ...this.props.pagination
                        }}
                        rowClassName={record => {
                            return record.edit ? styles.edited : null
                        }}
                        dataSource={dataSource}
                        onChange={this.handleTableChange}
                        loading={tableLoading}
                    />
                </>
            </Card>
        )
    }
}

const mapStateToProps = ({ global }) => ({
    categories: global.categories,
    currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AllPayments)
