import React, { PureComponent } from 'react'
import _ from 'lodash'
import { Card, Form } from 'antd'
import styles from './styles.less'
import { FormUtils as GetAllFormFields } from 'sz-react-utils-lite'
import { API_URL } from '../../../request'

import Request from '../../../request'
import { connect } from 'react-redux'

@Form.create()
class AutoMilkType extends PureComponent {

    state = {
        cardLoading: false,
        loading: false,
        reset: false
    }
    // }
    apiRequest = async () => {
        const { currentUser, form, company } = this.props
        if (company) {

            this.setState({ cardLoading: true })
            let filter = { level: 'COMPANY', companyId: company }
            let data = await Request.getAutoMilkType(filter)
            this.setState({ cardLoading: false })
            if (data && data.data) {
                const settingObj = data.data
                form.setFieldsValue({
                    autoMilkType: settingObj.autoMilkType
                }, () => {
                    form.setFieldsValue({
                        fat: settingObj.fat
                    })
                })
            }
            else {
                form.setFieldsValue({ autoMilkType: false })
            }
        }
        else {
            form.setFieldsValue({ autoMilkType: false })
        }

    }

    componentDidMount() {
        // this.getTemplates()
        this.apiRequest()

    }
    updateProps = () => {
        const { updateFormData, reset, submit, form } = this.props
        if (reset !== this.state.reset) {
            this.state.reset = reset
            form.setFieldsValue({ autoMilkType: false })
        }
        if (submit !== this.state.submit) {
            this.state.submit = submit
            updateFormData('autoMilkSetting', form.getFieldsValue())
        }
    }

    render() {
        const { cardLoading } = this.state
        const {
            form: { getFieldDecorator, getFieldValue, setFieldsValue }
        } = this.props
        this.updateProps()


        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
                md: { span: 8 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
                md: { span: 12 }
            }
        }


        const inputType = {
            fields: [
                {
                    label: 'Auto Milk Type',
                    key: 'autoMilkType',
                    dataIndex: 'autoMilkType',
                    type: 'switch',
                    required: true,
                    onChange: (autoMilkType) => {
                        setFieldsValue({ autoMilkType })
                    }

                },
                {
                    label: 'Maximum FAT for Cow Milk',
                    key: 'fat',
                    dataIndex: 'fat',
                    required: true,
                    hidden: !getFieldValue('autoMilkType'),
                    type: 'number',
                    onChange: (fat) => {
                        setFieldsValue({ fat })
                    }
                }
            ]
        }

        return (
            <Card loading={cardLoading} className={styles.container}>
                <GetAllFormFields
                    apiurl={API_URL}
                    inputSchema={inputType}
                    formItemLayout={formItemLayout}
                    getFieldDecorator={getFieldDecorator}
                />
            </Card>

        )
    }
}

const mapStateToProps = ({ global, router }) => ({
    loading: global.buttonLoading,
    categories: global.categories,
    search: router.location.search,
    currentUser: global.currentUser,
    pathname: router.location.pathname

})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AutoMilkType)
