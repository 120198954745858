import React, { PureComponent } from 'react'
import styles from './styles.less'
import { Card, Form } from 'antd'
import _ from 'lodash'

import moment from 'moment'
import { FormUtils as GetAllFormFields } from 'sz-react-utils-lite'
import Request, { API_URL } from '../../../request'

import { connect } from 'react-redux'
const defaultValue = {
    printer: 'On Board Printer',
    printHeader: 'Hindustan thermostatics',
    printFooter: 'Mobile AMCU ( www.amcu.in )',
    printFarmerNameAndId: true,
    printQuantity: true,
    printFat: true,
    printSnf: true,
    printMilkType: true,
    printCollectionDate: true,
    printCollectionTime: true,
    printRate: true,
    printTotalAmount: true,
    printStatus: true,
    printTimeOfPrint: true,
    printIncentive: true,
    printFarmerMobile: false,
    printClr: false,
    printDensity: false,
    printLactose: false,
    printSalts: false,
    printProtein: false,
    printTemp: false,
    printAddedWater: false,
    printFreezingPoint: false,
    printRateChartName: false,
    printStage: false,
}

@Form.create()
class PrinterSettings extends PureComponent {

    state = {
        cardLoading: false,
        loading: false,
        reset: false
    }


    componentDidMount() {
        this.apiRequest()
    }
    updateProps = () => {
        const { updateFormData, reset, submit, form } = this.props
        if (reset !== this.state.reset) {
            this.state.reset = reset
            console.log('innnnn')
            form.setFieldsValue(defaultValue)
        }
        if (submit !== this.state.submit) {
            this.state.submit = submit
            updateFormData('printerSettings', form.getFieldsValue())
        }
    }
    apiRequest = async () => {
        const { currentUser, form, formFields, company, updateFormData } = this.props
        if (company && !formFields) {
            this.setState({ cardLoading: true })
            let filter = { level: 'COMPANY', companyId: company }

            let data = await Request.getMachineSettings(filter)

            this.setState({ cardLoading: false })
            if (data && data.data.length) {
                let obj = {}
                form.setFieldsValue({ tear: true })
                _.forEach(data.data, (setting) => {
                    if (setting.type === 'printerParams') {
                        _.forEach(setting.value, (param, key) => {
                            obj[_.camelCase(`print ${key}`)] = param
                        })
                    }
                    if (setting.type === 'printerHeaderFooter') {
                        obj.printHeader = setting.value.header || ''
                        obj.printFooter = setting.value.footer || ''
                    }
                    else
                        obj[setting.type] = setting.value || setting.enabled

                })
                updateFormData('machineSettings', obj);
                form.setFieldsValue(obj)
            }
            else {
                form.setFieldsValue(defaultValue)
            }
        }
        else {
            form.setFieldsValue(formFields || defaultValue)
        }
        // dispatch(hideLoader())
    }

    render() {
        const { cardLoading } = this.state
        const {
            form: { getFieldDecorator, getFieldValue, setFieldsValue }
        } = this.props
        this.updateProps()

        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
                md: { span: 8 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
                md: { span: 12 }
            }
        }


        const inputType = {
            fields: [
                {
                    label: 'Printer Type',
                    key: 'printer',
                    dataIndex: 'printer',
                    type: 'select',
                    required: true,

                    options: [
                        { display: 'On Board Printer', id: 'onBoardRD' },
                        { display: 'Sunmi Inner Printer', id: 'sunmi' },
                        { display: 'Bluetooth Printer 58mm', id: 'bluetooth58mm' }],

                    onChange: (printer) => {
                        this.props.form.setFieldsValue({ printer })
                    }
                },
                {
                    label: 'Header',
                    key: 'printHeader',
                    // dataIndex: 'farmerTitle',
                },
                {
                    label: 'Footer',
                    key: 'printFooter',
                    // dataIndex: 'farmerTitle',
                },
                {
                    label: 'Farmer Name AND Id',
                    key: 'printFarmerNameAndId',
                    dataIndex: 'printFarmerName',
                    type: 'switch',
                    onChange: (printFarmerNameAndId) => {
                        setFieldsValue({ printFarmerNameAndId })
                    }
                },
                {
                    label: 'Farmer Mobile',
                    key: 'printFarmerMobile',
                    dataIndex: 'printFarmerMobile',
                    type: 'switch',
                    onChange: (printFarmerMobile) => {
                        setFieldsValue({ printFarmerMobile })
                    }
                },
                {
                    label: 'Quantity',
                    key: 'printQuantity',
                    dataIndex: 'printQuantity',
                    type: 'switch',
                    onChange: (printQuantity) => {
                        setFieldsValue({ printQuantity })
                    }
                },
                {
                    label: 'Fat',
                    key: 'printFat',
                    dataIndex: 'printFat',
                    type: 'switch',
                    onChange: (printFat) => {
                        setFieldsValue({ printFat })
                    }
                },
                {
                    label: 'SNF',
                    key: 'printSnf',
                    dataIndex: 'printSnf',
                    type: 'switch',
                    onChange: (printSnf) => {
                        setFieldsValue({ printSnf })
                    }
                },
                {
                    label: 'CLR',
                    key: 'printClr',
                    dataIndex: 'printClr',
                    type: 'switch',
                    onChange: (printClr) => {
                        setFieldsValue({ printClr })
                    }
                },
                {
                    label: 'Density',
                    key: 'printDensity',
                    dataIndex: 'printDensity',
                    type: 'switch',
                    onChange: (printDensity) => {
                        setFieldsValue({ printDensity })
                    }
                },
                {
                    label: 'Lactose',
                    key: 'printLactose',
                    dataIndex: 'printLactose',
                    type: 'switch',
                    onChange: (printLactose) => {
                        setFieldsValue({ printLactose })
                    }
                },
                {
                    label: 'Salts',
                    key: 'printSalts',
                    dataIndex: 'printSalts',
                    type: 'switch',
                    onChange: (printSalts) => {
                        setFieldsValue({ printSalts })
                    }
                },
                {
                    label: 'Protein',
                    key: 'printProtein',
                    dataIndex: 'printProtein',
                    type: 'switch',
                    onChange: (printProtein) => {
                        setFieldsValue({ printProtein })
                    }
                },
                {
                    label: 'Temp',
                    key: 'printTemp',
                    dataIndex: 'printTemp',
                    type: 'switch',
                    onChange: (printTemp) => {
                        setFieldsValue({ printTemp })
                    }
                },
                {
                    label: 'Added Water',
                    key: 'printAddedWater',
                    dataIndex: 'printAddedWater',
                    type: 'switch',
                    onChange: (printAddedWater) => {
                        setFieldsValue({ printAddedWater })
                    }
                },
                {
                    label: 'Freezing Point',
                    key: 'printFreezingPoint',
                    dataIndex: 'printFreezingPoint',
                    type: 'switch',
                    onChange: (printFreezingPoint) => {
                        setFieldsValue({ printFreezingPoint })
                    }
                },
                {
                    label: 'Milk Type',
                    key: 'printMilkType',
                    dataIndex: 'printMilkType',
                    type: 'switch',
                    onChange: (printMilkType) => {
                        setFieldsValue({ printMilkType })
                    }
                },
                {
                    label: 'Collection Date',
                    key: 'printCollectionDate',
                    dataIndex: 'printCollectionDate',
                    type: 'switch',
                    onChange: (printCollectionDate) => {
                        setFieldsValue({ printCollectionDate })
                    }
                },
                {
                    label: 'Collection Time',
                    key: 'printCollectionTime',
                    dataIndex: 'printCollectionTime',
                    type: 'switch',
                    onChange: (printCollectionTime) => {
                        setFieldsValue({ printCollectionTime })
                    }
                },
                {
                    label: 'Rate Chart Name',
                    key: 'printRateChartName',
                    dataIndex: 'printRateChartName',
                    type: 'switch',
                    onChange: (printRateChartName) => {
                        setFieldsValue({ printRateChartName })
                    }
                },
                {
                    label: 'Rate',
                    key: 'printRate',
                    dataIndex: 'printRate',
                    type: 'switch',
                    onChange: (printRate) => {
                        setFieldsValue({ printRate })
                    }
                },
                {
                    label: 'Total Amount',
                    key: 'printTotalAmount',
                    dataIndex: 'printTotalAmount',
                    type: 'switch',
                    onChange: (printTotalAmount) => {
                        setFieldsValue({ printTotalAmount })
                    }
                },
                {
                    label: 'Status',
                    key: 'printStatus',
                    dataIndex: 'printStatus',
                    type: 'switch',
                    onChange: (printStatus) => {
                        setFieldsValue({ printStatus })
                    }
                },
                {
                    label: 'Stage',
                    key: 'printStage',
                    dataIndex: 'printStage',
                    type: 'switch',
                    onChange: (printStage) => {
                        setFieldsValue({ printStage })
                    }
                },
                {
                    label: 'Time Of Print',
                    key: 'printTimeOfPrint',
                    dataIndex: 'printTimeOfPrint',
                    type: 'switch',
                    onChange: (printTimeOfPrint) => {
                        setFieldsValue({ printTimeOfPrint })
                    }
                },
                {
                    label: 'Incentive',
                    key: 'printIncentive',
                    dataIndex: 'printIncentive',
                    type: 'switch',
                    onChange: (printIncentive) => {
                        setFieldsValue({ printIncentive })
                    }
                },
            ]
        }

        return (
            <Card loading={cardLoading} className={styles.container}>
                <GetAllFormFields
                    apiurl={API_URL}
                    inputSchema={inputType}
                    formItemLayout={formItemLayout}
                    getFieldDecorator={getFieldDecorator}
                />
            </Card>

        )
    }
}

const mapStateToProps = ({ global, router }) => ({
    loading: global.buttonLoading,
    categories: global.categories,
    search: router.location.search,
    currentUser: global.currentUser,
    pathname: router.location.pathname

})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PrinterSettings)
