import { push } from 'connected-react-router'
import _ from 'lodash'
import Path from 'path-parser'

import Dashboard from './containers/dashboard'
import AddCompany from './containers/company/add'
import AllCompany from './containers/company/all'

import AllDevice from './containers/devices/all'
import InfoDevice from './containers/devices/info'

import Payments from './containers/payment'

import Transactions from './containers/transactions'

import AddFarmer from './containers/farmer/add'
import AllFarmers from './containers/farmer/all'

import AddMcc from './containers/Mcp/add/index'
import AllMcc from './containers/Mcp/all'
import AddMcpGroup from './containers/Mcp/mcpGroup/add'
import AllMcpGroup from './containers/Mcp/mcpGroup/all'

import AddUser from './containers/user/add'
import AddHelpSetting from './containers/helpDesk/add/index'
import ListHelpDesk from './containers/helpDesk/list/index'
import AllUser from './containers/user/all'
import AddTestingUser from './elements/testingUser/views/add'
import AllTestingUser from './elements/testingUser/views/all'
import AllTestingResults from './elements/TestingResults/views/list'
import AllTrialCompanies from './elements/TrialCompanies/views/list'
import AllSuspendedCompanies from './elements/SuspendedCompanies/views/list'
import AllCollectionCounts from './elements/collectionCount/views/list'
import EditTestingUser from './elements/testingUser/views/edit'




import Collections from './containers/collections'


import AllTickets from './containers/tickets/all'
import AddPackages from './elements/packages/views'
import DebugScreen from './elements/debug/views'
import WhitelistIp from './elements/whitelistIps/views'
import TicketDetails from './containers/tickets/details'
import AllSyncLogs from './containers/Mcp/all/syncLogs'

const menu = [
  {
    'path': '/dashboard',
    'name': 'Dashboard',
    'icon': 'dashboard',
    'key': 'dashboard',
    'homepage': true,
    'component': Dashboard,
    'authority': [
      'superAdmin',
      'subAdmin'
    ]
  },
  {
    'path': '/company',
    'name': 'Company',
    'icon': 'shop',
    'key': 'company',
    'authority': [
      'superAdmin',
      'subAdmin'
    ],
    'children': [
      {
        'path': '/company/add',
        'name': 'Add Company',
        'icon': 'shop',
        'key': 'addCompany',
        'component': AddCompany,
        'authority': [
          'superAdmin',
          'subAdmin'
        ],
      },
      {
        'path': '/company/update/:id',
        'name': 'Update Company',
        'icon': 'dashboard',
        'key': 'updateCompany',
        'dontShowOnMenu': true,
        'component': AddCompany
      },
      {
        'path': '/company/all',
        'name': 'All Company',
        'icon': 'shop',
        'key': 'allCompany',
        'component': AllCompany,
        'authority': [
          'superAdmin',
          'subAdmin'
        ],
      },
      {
        'path': '/company/trial/all',
        'name': 'All Trial Company',
        'icon': 'shop',
        'key': 'allTrialCompany',
        'component': AllTrialCompanies,
        'authority': [
          'superAdmin',
          'subAdmin'
        ],
      },
      {
        'path': '/company/suspended/all',
        'name': 'All Suspended Company',
        'icon': 'shop',
        'key': 'allTrialCompany',
        'component': AllSuspendedCompanies,
        'authority': [
          'superAdmin',
          'subAdmin'
        ],
      },
    ]
  },
  {
    'path': '/mcpGroup',
    'name': 'MCP Group',
    'key': 'mcpGroup',
    'icon': 'team',
    'authority': [
      'superAdmin',
      'company'
    ],
    'children': [
      {
        'path': '/mcpGroup/add',
        'name': 'Add Mcp Group',
        'key': 'addMcpGroup',
        'title': 'Add MCP Group',
        'component': AddMcpGroup,
        dontShowOnMenu: true
      },
      {
        'path': '/mcpGroup/edit/:id',
        'name': 'Edit Mcp Group',
        'key': 'editMcpGroup',
        'title': 'Edit MCP Group',
        dontShowOnMenu: true,
        'component': AddMcpGroup
      },
      {
        'path': '/mcpGroup/all',
        'name': 'All Mcp Group',
        'key': 'allMcpGroup',
        'title': 'All MCP Group',
        'component': AllMcpGroup
      }
    ]
  },
  {
    'path': '/mcp',
    'name': 'MCP',
    'key': 'mcp',
    'icon': 'user',
    'authority': [
      'superAdmin',
      'user'
    ],
    'children': [
      {
        'path': '/mcp/add',
        'name': 'Add MCP',
        'key': 'addMcp',
        'title': 'Add MCP',
        'component': AddMcc,
        // dontShowOnMenu: true

      },
      {
        'path': '/mcp/all',
        'name': 'All MCP',
        'title': 'All MCP',
        'component': AllMcc
      },
      {
        'path': '/mcp/editMcc/:id',
        'name': 'Update MCP',
        'title': 'Update MCP',
        'key': 'editMcc',
        dontShowOnMenu: true,
        'component': AddMcc
      },
      {
        'path': '/sync/logs',
        'name': 'Sync Logs',
        'title': 'Sync Logs',
        'authority': [
          'superAdmin',
        ],
        'component': AllSyncLogs
      },
    ]
  },
  /* {
    'path': '/devices',
    'name': 'Device',
    'icon': 'desktop',
    'key': 'devices',
    'authority': [
      'superAdmin',
      'user'
    ],
    'children': [
      {
        'path': '/devices/all',
        'name': 'All Device',
        'icon': 'desktop',
        'key': 'allDevice',
        'component': AllDevice
      },
      {
        'path': '/devices/info/:id',
        'name': 'Info Device',
        'icon': 'dashboard',
        'key': 'infoDevice',
        'dontShowOnMenu': true,
        'component': InfoDevice
      }
    ]
  }, */
  {
    'path': '/farmer',
    'name': 'Farmers',
    'icon': 'user',
    'key': 'farmer',
    'icon': 'dashboard',
    dontShowOnMenu: true,
    'authority': [
      'superAdmin',
      'user'
    ],
    'children': [
      {
        'path': '/farmer/add',
        'name': 'Add Farmer',
        'title': 'Add Farmer',
        'component': AddFarmer
      },
      {
        'path': '/farmer/all',
        'name': 'All Farmers',
        'title': 'All Farmers',
        'component': AllFarmers
      },
      {
        'path': '/farmer/edit/:id/:companyId',
        'name': 'Update Farmer',
        'title': 'Update Farmer',
        'key': 'editFarmer',
        dontShowOnMenu: true,
        'component': AddFarmer
      }
    ]
  },
  {
    'path': '/user',
    'name': 'User',
    'icon': 'user',
    'key': 'user',
    'authority': [
      'superAdmin',
      'user'
    ],
    'children': [
      {
        'path': '/user/add',
        'name': 'Add User',
        'icon': 'user-add',
        'key': 'addUser',
        'component': AddUser
      },
      {
        'path': '/user/update/:id',
        'name': 'Update User',
        'icon': 'user-add',
        'key': 'updateUser',
        'dontShowOnMenu': true,
        'component': AddUser
      },
      {
        'path': '/user/all',
        'name': 'All User',
        'icon': 'team',
        'key': 'allUser',
        'component': AllUser
      },
    ]
  },
  {
    'path': '/collections',
    'name': 'Collections',
    'icon': 'database',
    'key': 'collections',
    'component': Collections,
    'authority': [
      'superAdmin',
      'user'
    ],
  },
  {
    'path': '/payments',
    'name': 'Payments',
    'icon': 'dollar',
    'key': 'payments',
    dontShowOnMenu: true,
    'component': Payments,
    'authority': [
      'superAdmin'
    ]
  },
  {
    'path': '/transactions',
    'name': 'Transactions',
    'icon': 'dollar',
    'key': 'transactions',
    dontShowOnMenu: true,
    'component': Transactions,
    'authority': [
      'superAdmin'
    ]
  },
  {
    'path': '/smsPack',
    'name': 'SMS Pack',
    'icon': 'message',
    'key': 'smsPack',
    "component": AddPackages,
    'authority': [
      'superAdmin'
    ]
  },
  {
    'path': '/whitelistsIp',
    'name': 'Whitelisted IPs',
    'icon': 'control',
    'key': 'ip',
    "component": WhitelistIp,
    'authority': [
      'superAdmin'
    ]
  },
  {
    'path': '/debugScreen/:id/:uuid',
    'name': 'Debug Screen',
    'icon': 'bug',
    'key': 'debugScreen',
    'dontShowOnMenu': true,
    "component": DebugScreen,
    'authority': [
      'superAdmin'
    ]
  },
  {
    'path': '/testingUser',
    'name': 'Testing Users',
    'icon': 'user',
    'key': 'testingUser',
    'authority': [
      'superAdmin'
    ],
    'children': [
      {
        'path': '/testingUser/add',
        'name': 'Add Testing User',
        'icon': 'user-add',
        'key': 'addTestingUser',
        'component': AddTestingUser
      },
      {
        'path': '/testingUser/all',
        'name': 'All Testing User',
        'icon': 'team',
        'key': 'allTestingUser',
        'component': AllTestingUser
      },
      {
        'path': '/testingUser/update/:id',
        'name': 'Edit Testing User',
        'icon': 'team',
        'key': 'editTestingUser',
        'dontShowOnMenu': true,
        'component': EditTestingUser
      },
     
    ]
  },
  {
    'path': '/testingResults',
    'name': 'Testing Results',
    'icon': 'file-text',
    'key': 'testingResuts',
    'component': AllTestingResults,
    'authority': [
      'superAdmin',
      'subAdmin'
    ]
  },
  {
    'path': '/collectionCount',
    'name': 'Collection Count Results',
    'icon': 'file-text',
    'key': 'collectionCount',
    'component': AllCollectionCounts,
    'authority': [
      'superAdmin',
      'subAdmin'
    ]
  },
 
  {
    'path': '/helpDesk',
    'name': 'Help Desk',
    'icon': 'file-text',
    'key': 'helpDesk',
    'authority': [
      'superAdmin',
      'subAdmin'
      
    ],
    'children': [
      {
        'path': '/helpDesk/add',
        'name': 'Add',
        'icon': 'plus',
        'key': 'addHelpDesk',
        'component': AddHelpSetting
      },
      {
        'path': '/helpDesk/list',
        'name': 'All',
        'icon': 'list',
        'key': 'listHelpDesk',
        'component': ListHelpDesk
      },
      {
        'path': '/helpDesk/edit/:id',
        'name': 'Edit Helpdesk',
        'key': 'editHelpdesk',
        'title': 'Edit Help Desk',
        dontShowOnMenu: true,
        'component': AddHelpSetting
      },
     
    ]
  },
]


//<editor-fold desc="Functions Exports">
export const getUrlPushWrapper = (keyString, query) => {
  return push(getUrlPath(keyString, query))
}

export const getUrlPath = (keyString, params) => {

  if (!params) params = {}

  let keyArr = keyString.split('.')
  let val = _.find(menu, p => p.key === keyArr[0])

  if (!val) {
    return `/`
  }

  if (keyArr.length === 2) {
    val = _.find(val.children, p => p.key === keyArr[1])
  }

  if (!val) {
    return `/`
  }

  let queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&')

  return `${val.path}?${queryString}`
}

export const getPushPathWrapper = (keyString, params) => {

  let obj = getUrlObject(keyString)

  if (obj) {
    const path = new Path(obj.path)

    return push(path.build(params))
  }

  return 'error'
}

export const getUrlParams = (keyString, route) => {

  let obj = getUrlObject(keyString)

  if (obj) {
    const path = new Path(obj.path)

    return path.test(route)
  }

  return { error: true }
}

export const getUrlObject = (keyString) => {

  let keyArr = keyString.split('.')
  let val = _.find(menu, p => p.key === keyArr[0])
  if (!val) {

    return `/`
  }

  if (keyArr.length === 2) {
    val = _.find(val.children, p => p.key === keyArr[1])
  }

  if (!val) {
    return `/`
  }

  return val
}
//</editor-fold>

export default menu

