import React, { PureComponent } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
    Form,
    Input,
    DatePicker,
    Select,
    Button,
    Radio,
    Col,
    Row,
    Card,
    Tabs,
    Icon
} from 'antd'
import { ChromePicker } from 'react-color'
import _ from 'lodash'
import moment from 'moment'
import { FormUtils as GetAllFormFields } from 'sz-react-utils-lite'
// import {FormUtils as GetAllFormFields} from '../../../components/_utils/formUtils'
import { API_URL } from '../../../request'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { notification } from 'antd/lib/index'
import { hideLoader, showLoader } from '../../../modules/actions'
import Request from '../../../request'
import { connect } from 'react-redux'
import { createMatchSelector } from 'connected-react-router'
// import mockData from '../MOCK_DATA.json'
import async from 'async'
import { getUrlParams } from '../../../routes'

const FormItem = Form.Item
const { Option } = Select
const { TabPane } = Tabs

@Form.create()
class AddFarmer extends PureComponent {

    handleSubmit = e => {
        const { dispatch, form, currentUser } = this.props
        e.preventDefault()
        form.validateFieldsAndScroll(async (err, valData) => {
            if (!err) {
                this.setState({loading:true})
            
                let values = _.clone(valData)
                values.type = 'farmer'

                dispatch(showLoader())
                let x = null
                if (this.state.edit) {

                    x = await Request.updateFarmer(this.state.id, values)

                } else {
                    x = await Request.addFarmer(values)
                }

                dispatch(hideLoader())

                console.log(x)
                this.setState({loading:false})
            
                if (!x.error) {

                    notification.success({
                        message: this.state.id
                            ? x.message
                            : x.message
                    })

                    !this.state.edit && this.props.form.resetFields()

                } else {
                    notification.error({
                        message: 'Error Saving',
                        description: x.message
                    })
                }

            }
        })
    }
    selectCompany=async(company)=>{
        this.props.form.setFieldsValue({ companyId:company })
        let { data } = await Request.getAllmcp({ companyId:company })
        this.setState({
            allMcp: data,
            company
        })
    }
    selectedMcp=(mcpId)=>{
        this.props.form.setFieldsValue({ mcpId })
        this.getCategories(mcpId)
    }
    subInputTypes1 = () => ({
        fields: [
            {
                label: 'Select Company',
                key: 'companyId',
                type: 'select',
                keyAccessor: x => x.id,
                valueAccessor: x => `${x.name} (${x.id})`,
                options: this.state.allCompany,
                onChange: company =>{this.selectCompany(company)
                    this.props.form.resetFields('mcpId')
                },
                required: true
            },
            {
                label: 'Select Mcp',
                key: 'mcpId',
                type: 'select',
                keyAccessor: x => x.id,
                valueAccessor: x => `${x.name}(${x.id})`,
                options: this.state.allMcp ? this.state.allMcp : [],
                onChange: mcpId =>this.selectedMcp(mcpId),
                required: true
            },
            {
                label: 'Full Name',
                key: 'farmerName',
                dataIndex: 'farmerName',
                required: true
            },
            {
                label: 'Farmer Id',
                key: 'id',
                dataIndex: 'id',
                disabled:this.state.id,
                required: true
            },
            {
                label: 'Mobile',
                key: 'phone',
                dataIndex: 'phone',
                required: true
            },
            
            {
                label: 'Rfid',
                key: 'rfid',
                required: true

            },
            {
                label: 'Category',
                key: 'categoryId',
                type: 'select',
                keyAccessor: x => x.id,
                valueAccessor: x => `${x.categoryName}`,
                options: this.state.category ? this.state.category : [],
                onChange: categoryId => {
                    this.props.form.setFieldsValue({ categoryId })
                },
                required: true,
            }
        ]
    })
    getCategories = async (id) => {
        let category=await Request.listCategory({mcpId:id})
        console.log(category.data)
        this.setState({
            category:category.data
        })
    }
    setFormValues = async (data) => {
        if (data) {
            // this.setState({ update: true, edit: true })
                let obj={...data}
                obj.companyId=data.companyId
                obj.mcpId=data.mcpId.id
                obj.categoryId=data.categoryId.id

                    this.props.form.setFieldsValue(obj)
        }
    }
    onChange = e => {
        this.setState({
            value: e.target.value
        })
    }

    constructor(props) {
        super(props)
        this.state = {
            id: null,
            major: '',
            value: 1,
            activeKey: '1',
            allMcp: [],
            allCompany: [],
        }

    }

    async componentDidMount() {
        let data=getUrlParams('farmer.editFarmer',this.props.pathname)
        if(data){
            let value=await Request.getFarmerById(data.id,{companyId:data.companyId})
                console.log(value)
        this.setFormValues(value.data)
        this.selectCompany(data.companyId)
            this.setState({
                id:data.id,
                edit:true
            })
        }
            let { data: allCompany } = await Request.listCompanies()
            this.setState({
                allCompany
            })
        
    }

    render() {
        const { submitting } = this.props
        const {
            form: { getFieldDecorator, getFieldValue, setFieldsValue }
        } = this.props

        const { id, update } = this.state

        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
                md: { span: 8 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
                md: { span: 12 }
            }
        }

        const submitFormLayout = {
            wrapperCol: {
                xs: { span: 24, offset: 0 },
                sm: { span: 10, offset: 7 },
                md: { span: 12, offset: 8 }
            }
        }

        const formItemLayoutWithOutLabel = {
            wrapperCol: {
                xs: { span: 24, offset: 0 },
                sm: { span: 20, offset: 4 }
            }
        }

        return (
            <PageHeaderWrapper
                title={!this.state.edit?'Add Farmer':'Edit Farmer'}
            >
                <Card loading={update}>
                    <Form onSubmit={this.handleSubmit} style={{ marginTop: 8 }}>
                        <GetAllFormFields apiurl={API_URL}
                            inputSchema={this.subInputTypes1()}
                            formItemLayout={formItemLayout}
                            getFieldDecorator={getFieldDecorator} />

                        <Form.Item {...submitFormLayout} style={{ marginTop: 32 }}>
                            <Button type="primary" htmlType="submit" loading={this.state.loading}>
                                SAVE
                                        </Button>
                        </Form.Item>
                    </Form>

                </Card>


            </PageHeaderWrapper>
        )
    }
}

const mapStateToProps = ({ global, router }) => ({
    loading: global.buttonLoading,
    categories: global.categories,
    search: router.location.search,
    currentUser: global.currentUser,
    pathname:router.location.pathname
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddFarmer)
