import React, { Component } from 'react'
import PageHeaderWrapper from '../../../../components/PageHeaderWrapper'
import { TableComp } from 'sz-react-utils-lite'

import moment from 'moment'
import {
    Table,
    Badge,
    Card,
    Tag,
    Tooltip,
    Popconfirm,
    Select,
    Form,
    notification,
    Switch,
    Input, Button, Icon
} from 'antd'
import Request from '../../../../request'
import Color from 'color'
import _ from 'lodash'
import async from 'async'
import Highlighter from 'react-highlight-words'
import styles from './styles.less'
import { connect } from 'react-redux'
import memoizeOne from 'memoize-one'
import update from 'immutability-helper'
import { getUrlPushWrapper, getPushPathWrapper } from '../../../../routes'
import { timeStampFormat } from '../../../../settings'


@Form.create()
class AllMcpGroup extends Component {

    constructor(props) {
        super(props)
        this.table = React.createRef()
    }
    state = {}
    deleteMcpGroup = async id => {
        this.setState({ loading: true })
        let x = await Request.deleteMcpGroup(id)
        console.log(x)
        this.setState({ loading: false })
        this.apiRequest()

        if (!x.error) {
            notification.success({
                message: x.message,
                btn: <Button type='primary' onClick={() => this.undo(id)}>Undo</Button>,
                key: `${id}-close`
            })

        }
        else {
            notification.error({
                message: x.message,
                key: `${id}-close`
            })
        }


    }

    undo = async (id) => {
        const response = await Request.restoreMcpGroup(id)
        notification.destroy()
        this.apiRequest()
    }

    companyChildrens = []
    getCompanies = async () => {
        let { data } = await Request.listCompanies()
        if (data) {
            _.forEach(data, (company) => {
                this.companyChildrens.push(
                    <Select.Option value={company.id} key={company.id}>{company.name}</Select.Option>
                )
            })
        }
    }

    onSelectChangeCompany = (val) => {
        this.state.selectedCompany = val
        this.table.current.reload(true)
    }
    onReset = () => {
        this.props.form.resetFields()
        this.state.selectedMcp = null
        this.state.selectedCompany = null
        this.apiRequest()
    }

    componentDidMount() {
        this.getCompanies()
        // this.apiRequest()
    }
    apiRequest = (params, columns) => {

        return new Promise(async resolve => {

            const { selectedCompany } = this.state
            const { currentUser } = this.props

            this.setState({
                dataSearchParams: params
            })
            let filters = {
                ...params,
                details: true
            }
            if (selectedCompany) {
                filters.companyId = selectedCompany
            }
            let data = await Request.getAllGroup(filters)
            console.log(data)
            if (data.error) {
                return resolve({
                    total: 0
                })
            }

            this.setState({
                total: data.total
            })

            resolve(data)
        })
    }
    render() {
        const { submitting, dispatch, form: { getFieldDecorator } } = this.props
        const { loading, total } = this.state


        const columns = [
            // {
            //     title: 'Id',
            //     dataIndex: 'id',
            //     key: 'id',
            // },
            {
                title: 'Group Name',
                dataIndex: 'groupName',
                key: 'groupName',
                searchTextName: true
            },
            {
                title: 'Total Mcp',
                dataIndex: 'mcpCount',
                key: 'mcpCount',
                render: (val, record) => {
                    return val ? <Tag>{val}</Tag> : <Tag>0</Tag>
                },
                searchTextName: true
            },
            // {
            //     title: 'Company Id',
            //     dataIndex: 'companyId',
            //     key: 'company',
            //     searchTextName: true,
            //     render: (val)=>val.id
            // },
            {
                title: 'Company',
                dataIndex: 'companyId',
                key: 'company',
                searchTextName: true,
                render: (val) => val.name
            },
            {
                title: 'Created At',
                dataIndex: 'createdOn',
                key: 'createdOn',
                render: (val, record) => {
                    return <Tag>{moment(record.time).format(timeStampFormat)}</Tag>
                }
            },
            {
                title: 'Updated At',
                dataIndex: 'updatedOn',
                key: 'updatedOn',
                render: (val, record) => {
                    return <Tag>{moment(record.time).format(timeStampFormat)}</Tag>
                }
            },
            // {
            //     key: 'actions',
            //     title: 'Actions',
            //     render: (text, record) => {
            //         return <React.Fragment>
            //             <Tooltip title="Edit MCP Group">
            //                 <Button
            //                     shape="circle"
            //                     style={{ marginRight: 6 }}
            //                     size="small"
            //                     onClick={() => {
            //                         dispatch(
            //                             getPushPathWrapper('mcpGroup.editMcpGroup', { id: record.id })
            //                         )
            //                     }}
            //                     icon="edit"
            //                 />
            //             </Tooltip>
            //             <Tooltip title="Delete MCP Group">
            //                 <Popconfirm
            //                     title="Are you sure delete this MCP Group?"
            //                     onConfirm={() => {
            //                         this.deleteMcpGroup(record.id)
            //                     }}
            //                     onCancel={() => {
            //                         console.log()
            //                     }}
            //                     okText="Yes"
            //                     cancelText="No">
            //                     <Button
            //                         shape="circle"
            //                         size="small"
            //                         icon="delete"
            //                         type='danger'
            //                     />
            //                 </Popconfirm>
            //             </Tooltip>
            //         </React.Fragment>
            //     }
            // }

        ]
        return (
            <PageHeaderWrapper
                title={`All MCP Groups (${total})`}>

                <Card bordered={true}>
                    <div style={{ marginBottom: '10px' }} className={styles.filter}>
                        <div>
                            <Form layout={'inline'}>
                                <Form.Item>
                                    {getFieldDecorator('company')(
                                        <Select
                                            style={{ width: '200px' }}
                                            placeholder='Company Filter'
                                            onChange={this.onSelectChangeCompany}
                                        >
                                            {this.companyChildrens}
                                        </Select>
                                    )}
                                </Form.Item>
                            </Form>
                        </div>
                        <Button onClick={this.onReset}>Reset</Button>
                    </div>

                    <TableComp ref={this.table}
                        columns={columns}
                        bordered={true}
                        // dataSource={allGroups}
                        loading={loading}
                        apiRequest={params => this.apiRequest(params, columns)}
                        extraProps={{
                            scroll: { x: true }
                        }}
                        pagination={{
                            showSizeChanger: true,
                            defaultPageSize: 20,
                            pageSizeOptions: ['10', '20', '50', '100', '1000']
                        }}
                    />
                </Card>

            </PageHeaderWrapper>)

    }
}


const mapStateToProps = ({ global }) => ({
    categories: global.categories
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AllMcpGroup)
