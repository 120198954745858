import React, { PureComponent } from 'react'
import _ from 'lodash'
import { Spin, Icon } from 'antd'
import { ReactSortable } from "react-sortablejs";
import { getUrlParams } from '../../routes'
import styles from './styles.less'
import Request from '../../request'
import { connect } from 'react-redux'
import { Empty, List } from 'antd'
const defaultDataSource = [
    'id',
    'collectionDate',
    'milkType',
    'collectionTime',
    'farmerUUID',
    'farmerId',
    'farmerName',
    'rate',
    'totalAmount',
    'fat',
    'snf',
    'addedWater',
    'quantity',
    'quantityType',
    'extraField',
    'status',
    'stage',
    'farmerMobile',
    'rateChartId',
    'rateChartName',
    'collectionMode',
    'sync',
    'edit',
    'createdOn',
    'updatedOn',
    'machineCollectionTime',
    'clr',
    'anotherExtra',
    'incentive'
]
const defaultTargetKeys = [
    'id',
    'collectionDate',
    'milkType',
    'collectionTime',
    'farmerUUID',
    'farmerId',
    'farmerName',
    'rate',
    'totalAmount'
]

class CollectionExportSettings extends PureComponent {

    state = {
        cardLoading: false,
        dataSource: [],
        targetKeys: [],
        loading: false
    }

    componentDidMount() {
        // this.getTemplates()
        this.props.setDefaultSettings(this.apiRequest.bind(this))
        this.props.getExportFields(this.getTargetKeys.bind(this))
        this.apiRequest()
    }

    getTargetKeys = () => {
        const keys = _.clone(this.state.targetKeys)
        return keys.map(val => val.dataIndex)
    }

    apiRequest = async () => {
        let id = getUrlParams('mcp.editMcc', this.props.pathname)
        const { currentUser, companyId, settingLevel } = this.props
        this.setState({ loading: true })
        let filter = { level: settingLevel, companyId }
        if (settingLevel == 'MCP' && id && id.id)
            filter.mcpId = id.id
        else
            filter.level = 'COMPANY'
        let data = await Request.getCollectionExportSettings(filter)
        this.setState({ loading: false })
        console.log(data)
        if (data && data.data && data.data.fields) {
            let targetKeys = []
            _.forEach(data.data.fields, (val, key) => {
                const data = {
                    key: key + 1,
                    title: _.startCase(val),
                    dataIndex: val,
                    selected: true
                }
                targetKeys.push(data)
            })
            this.setState({ targetKeys })
            this.getFields(targetKeys.map(val => val.dataIndex), true)
        }
        else
            this.getFields()

    }

    handleChange = (item, remove) => {
        let targetKeys = _.clone(this.state.targetKeys)
        const newData = this.state.dataSource.map(val => {
            if (val.dataIndex === item.dataIndex) {
                val.selected = !val.selected
            }
            return val
        })
        if (remove) {
            targetKeys = targetKeys.filter(val => val.dataIndex != item.dataIndex)
        }
        else
            targetKeys.push(item)

        this.setState({ dataSource: newData, targetKeys });
    };
    // get all fields form server
    /* getFields = async () => {
        const targetKeys = [];
        const dataSource = [];
        const allFields = await Request.getAllCollectionFields()
        _.forEach(allFields, (val, key) => {
            const data = {
                key: key + 1,
                title: _.startCase(val),
                dataIndex: val,
                selected: false
            };
            dataSource.push(data);
        })
        this.setState({ dataSource, targetKeys });
    }; */
    getFields = async (selectedKeys = defaultTargetKeys, isEdit) => {
        const targetKeys = [], dataSource = [];

        _.forEach(defaultDataSource, (val, key) => {
            const data = {
                key: key + 1,
                title: _.startCase(val),
                dataIndex: val,
                selected: selectedKeys.includes(val) ? true : false
            };
            dataSource.push(data);
        })
        if (!isEdit) {
            _.forEach(selectedKeys, (val, key) => {
                const data = {
                    key: key + 1,
                    title: _.startCase(val),
                    dataIndex: val,
                    selected: true
                };
                targetKeys.push(data);
            })
            this.setState({ targetKeys });
        }

        this.setState({ dataSource });
    };

    render() {
        const { targetKeys, dataSource } = this.state
        const { settingLevel, isCompany } = this.props
        return (
            <div className={styles.wrapper}>
                {settingLevel === 'COMPANY' && !isCompany ?
                    <h4 style={{ color: 'blue' }}>{'NOTE :This MCP has company level settings'}</h4> :
                    <Spin tip={'Loading...'} spinning={this.state.loading}>
                        <div style={{ display: 'flex' }}>
                            <div className={styles.list}>
                                <h2>All Fields</h2>
                                <List
                                    size="small"
                                    bordered
                                    style={{
                                        width: 300,
                                        height: 400,
                                        overflowY: 'scroll'
                                    }}
                                    dataSource={dataSource.filter(item => !item.selected)}
                                    renderItem={item => <List.Item actions={[
                                        <Icon onClick={() => { this.handleChange(item) }} style={{ margin: '0 0 0 10px' }} className={styles.icon} type='arrow-right' />
                                    ]} >{item.title}</List.Item>}
                                />
                            </div>
                            <div className={styles.list}>
                                <h2>Export Collection Fields</h2>
                                <div className={styles.sortableList}>
                                    {targetKeys.length ?
                                        <ReactSortable
                                            handle=".handle"
                                            list={targetKeys}
                                            animation={150}
                                            setList={newState => this.setState({ targetKeys: newState })}
                                        >
                                            {targetKeys.map((item) => <div key={item.key}>
                                                <span><Icon onClick={() => this.handleChange(item, true)} className={styles.icon} type='arrow-left' />{item.title}</span>
                                                <Icon className={`handle ${styles.handle}`} type='drag' />
                                            </div>)}
                                        </ReactSortable> :
                                        <div style={{ alignSelf: 'center' }}>
                                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </Spin>
                }
            </div>

        )
    }
}

const mapStateToProps = ({ global, router }) => ({
    loading: global.buttonLoading,
    categories: global.categories,
    search: router.location.search,
    currentUser: global.currentUser,
    pathname: router.location.pathname

})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CollectionExportSettings)
