import React, { useEffect, useState, useRef } from 'react'
import {
    Button,
    Drawer, Card, Tag,
    Popconfirm,
    Icon,
    Input,
    DatePicker,
} from 'antd'
import styles from './styles.less'
import Highlighter from 'react-highlight-words'
import async from 'async'
import { TableComp } from 'sz-react-utils-lite'
import _ from 'lodash'
import moment from 'moment'
import { dateFormat, timeStampFormat } from '../../../settings'
import Request, { API_URL } from '../../../request'
import { FormUtils as GetAllFormFields } from 'sz-react-utils-lite'
import { ListTrialCompanies, UpdateTrialCompany } from '../action/trialCompaniesAction'
import { useSelector, useDispatch } from 'react-redux'
import { getPushPathWrapper } from '../../../routes'
const { RangePicker } = DatePicker;

const AllTrialCompanies = (props) => {
    const table = useRef(null)

    const dispatch = useDispatch()
    const [state, setState] = useState({
        dateFilters: {}, searchText: '', list: [], listFirst: [], filter: [], total: 0
    })

    const { trialCompanyLoader, currentUser, trialCompanyList } = useSelector((state) => ({
        trialCompanyList: state.trialCompany.trialCompanyList,
        trialCompanyLoader: state.trialCompany.trialCompanyLoader,
        currentUser: state.global.currentUser
    }))

    const [pagination, setPagination] = useState({})
    useEffect(() => {
        apiRequest()
    }, [])
    useEffect(() => {
        if(trialCompanyList){
            setState({...state, total: trialCompanyList.total})
        }
    },[trialCompanyList])
    const apiRequest = (params = {}) => {
        return new Promise(async (resolve) => {
            const { isFilter } = state
            if (isFilter) {
                params.page = 1
                setState({ ...state, isFilter: false })
            }
            setState({
                ...state,
                dataSearchParams: params
            })
            let filter = { ...params }
            // console.log(filter)
            dispatch(ListTrialCompanies(filter))

        })
    }
    const handleSearch = (selectedKeys, confirm) => {
        confirm();
        setState({ ...state, searchText: selectedKeys[0] });
    };
    const getColumnSearchProps = (dataIndex, index) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    id={`input-${dataIndex}`}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    id={`search-${dataIndex}`}
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm)}
                    icon="search"
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
            </Button>
                <Button id={`reset-${dataIndex}`} onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reset
            </Button>
            </div>
        ),
        filterIcon: filtered => (
            <Icon id={`filter-menu-${dataIndex}`} style={{ color: filtered ? '#1890ff' : undefined }} type='search' />
        ),
        onFilter: (value, record) => {
            return index ?
                (
                    record[dataIndex][index]
                        .toString()
                        .toLowerCase()
                        .includes(value.toLowerCase())

                ) :
                (
                    record[dataIndex]
                        .toString()
                        .toLowerCase()
                        .includes(value.toLowerCase())

                )
        },
        render: text => (
            <Highlighter
                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                searchWords={[state.searchText]}
                autoEscape
                textToHighlight={text.toString()}
            />
        ),
    });

    const handleReset = clearFilters => {
        clearFilters();
        setState({ ...state, searchText: '' });
    };
    const toogleUserDrawer = async (id) => {
        const data = {
            trial: false
        }
        dispatch(UpdateTrialCompany(id, data)).then(() => {
            apiRequest()
        })
    }

    const editCompany = async (id) => {
        dispatch(getPushPathWrapper('company.updateCompany', { id }))
    }
    // console.log(trialCompanyList)
    const columns = [
        {
            title: 'Company Name',
            dataIndex: 'name',
            key: 'name',
            fixed: 'left',
            width: 150,
            // searchTextName: 'name',
            ...getColumnSearchProps('name')
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
            align: 'center',
            ...getColumnSearchProps('address')

        },
        {
            title: 'E-mail',
            dataIndex: 'email',
            key: 'email',
            align: 'center',
            ...getColumnSearchProps('email')

        },
        {
            title: 'Mobile',
            dataIndex: 'mobile',
            key: 'mobile',
            align: 'center',
            ...getColumnSearchProps('mobile')

        },
        {
            title: 'Gst No.',
            dataIndex: 'gstNo',
            key: 'gstNo',
            align: 'center',
            // ...this.getColumnSearchProps('branchArray')
        },
        {
            title: 'Created On',
            dataIndex: 'createdOn',
            key: 'createdOn',
            align: 'center',
            render: (val, record) => {
                return <Tag>{moment(val).format(timeStampFormat)}</Tag>
            }
            // ...this.getColumnSearchProps('branchArray')
        },
        {
            title: 'Action',
            dataIndex: 'id',
            key: 'action',
            align: 'center',
            fixed: 'right',
            width: 150,
            render: (val) => {
                return (
                    <div>
                    <Button shape="circle" icon="edit" id={'edit'} className={styles.iconBtn} onClick={() => editCompany(val)} />
                    </div>
                )
            }
        }
       
    ]
    return (
        <Card title={`All Suspended Companies (${state.total  == undefined ? 0 : state.total})`}>

            <TableComp
                ref={table}
                size='small'
                loading={trialCompanyLoader}
                columns={columns}
                dataSource={trialCompanyList.data}
                pagination={{
                    ...pagination,
                    defaultPageSize: 10,
                    pageSizeOptions: ['10', '25', '50', '100', '500'],
                    showSizeChanger: false
                }}
            />
        </Card>
    )
}

export default AllTrialCompanies