import React, { Component } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { getPushPathWrapper } from '../../../routes'
import FormItem from '../../../components/FormItem'
import { getUrlParams } from '../../../routes'
import {
    Table,
    Card,
    Tabs,
    Divider,
    notification,
    Switch,
    Tag,
    Input, Button, Icon,
    Layout,
    Form,
    Descriptions,
    Popconfirm,
    Select,
    InputNumber
} from 'antd'
import Request from '../../../request'
import _ from 'lodash'
import styles from '../all/style.less'
import Async from 'async'
import { connect } from 'react-redux'
import { hideLoader, showLoader } from '../../../modules/actions';

const { TabPane } = Tabs;
const { Option } = Select
function BlackBox(props) {

    return (props.name && <><div id="blackbox" className={styles.blackbox}>{props.name}</div>
        {props.imei && <div id="sometext" className={styles.sometext}>({props.imei})</div>}</>);
}
function Language(props) {
    return (props.language &&
        <><div className={styles.language}>Language: {props.language}</div>
        </>
    )
}
function Farmers(props) {
    return (props.farmers &&
        <><div className={styles.farmer}>No. Of Farmers: {props.farmers}</div></>
    )
}
const EditableContext = React.createContext();

const EditableRow = ({ form, index, ...props }) => (
    <EditableContext.Provider value={form}>
        <tr {...props} />
    </EditableContext.Provider>
);

const EditableFormRow = Form.create()(EditableRow);

class EditableCell extends React.Component {
    state = {
        editing: false,
    };

    toggleEdit = () => {
        const editing = !this.state.editing;
        this.setState({ editing }, () => {
            if (editing) {
                this.input.focus();
            }
        });
    };

    save = e => {
        const { record, handleSave } = this.props;
        this.form.validateFields((error, values) => {
            if (error && error[e.currentTarget.id]) {
                return;
            }
            this.toggleEdit();
            handleSave({ ...record, ...values });
        });
    };

    renderCell = form => {
        this.form = form;
        const { children, dataIndex, record, title } = this.props;
        const { editing } = this.state;
        return editing ? (
            <Form.Item style={{ margin: 0 }}>
                {form.getFieldDecorator(dataIndex, {
                    rules: [
                        {
                            required: true,
                            message: `${title} is required.`,
                        },
                    ],
                    initialValue: record[dataIndex],
                })(dataIndex == 'price' ? <InputNumber ref={node => (this.input = node)} onPressEnter={this.save} onBlur={this.save} /> : <Input ref={node => (this.input = node)} onPressEnter={this.save} onBlur={this.save} />)}
            </Form.Item>
        ) : (
                <div
                    className="editable-cell-value-wrap"
                    style={{ paddingRight: 24 }}
                    onClick={this.toggleEdit}
                >
                    {children}
                </div>
            );
    };

    render() {
        const {
            editable,
            dataIndex,
            title,
            record,
            index,
            handleSave,
            children,
            ...restProps
        } = this.props;
        return (
            <td {...restProps}>
                {editable ? (
                    <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
                ) : (
                        children
                    )}
            </td>
        );
    }
}

@Form.create()
class RateChart extends React.Component {
    state = {
        categories: [],
        catChildrens: [],
        dataSource: [],
        count: 0,
        selected: [],
        catValues: [],
        fatDataSource: [],
        fatDataSources: [],
        snfDataSource: [],
        showTable: false,
        showGrid: false,
        showForm: false
    };
    constructor(props) {
        super(props);
        this.columns = [
            {

                title: 'Serial No',
                key: 'id',
                editable: true,
                render: (val, k, key) => {
                    return key + 1
                }
            },
            {
                title: 'Name',
                key: 'Name',
                dataIndex: 'chartName',
                editable: true

            },
            {
                title: 'Price',
                key: 'price',
                dataIndex: 'fatRate'
            },
            {
                title: 'Type',
                key: 'type',
                dataIndex: 'type',
                editable: true

            },
            {
                title: 'Category Id',
                key: 'category',
                dataIndex: 'category',
                render: (text, record, index) => {
                    return (
                        <Select
                            style={{ width: '200px' }}
                            label='Category'
                            required={true}
                            mode="tags"
                            size={'default'}
                            placeholder="Please select"
                            value={this.state.catValues[index] || []}
                            onChange={(val) => {
                                if (val) {
                                    let newValues = _.cloneDeep(this.state.catValues)
                                    newValues[index] = val
                                    let cids = []
                                    _.forEach(val, (cid) => {
                                        cids.push({ name: cid })
                                    })
                                    if (!this.state.dataSource[index].rateChartCategories)
                                        this.state.dataSource[index].rateChartCategories = { categories: [] }
                                    this.state.dataSource[index].rateChartCategories.categories = [...cids]
                                    this.setState({ dataSource: this.state.dataSource, catValues: newValues }, () => console.log(this.state))
                                }
                            }}
                        >
                            {this.state.catChildrens || []}
                        </Select>)
                }
            },
            {
                title: 'operation',
                dataIndex: 'operation',
                render: (text, record) =>
                    this.state.dataSource.length >= 1 ? (<>
                        <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(record.id)}>
                            <Button shape='circle' type='danger' icon='delete' />
                        </Popconfirm>
                        {record.type != 'Kg Fat' && <Button style={{ marginLeft: '1em' }} shape='circle' icon='edit' onClick={() => this.editRate(record)} />}
                    </>
                    ) : null,
            },
        ];
        this.fatColumn = [
            {

                title: 'Serial No',
                key: 'id',
                editable: true,
                render: (val, k, key) => {
                    return key + 1
                }
            },
            {
                title: 'FAT',
                key: 'fat',
                dataIndex: 'fat'

            },
            {
                title: 'Price',
                key: 'rate',
                dataIndex: 'rate',
                render: (text, row, key) => {
                    return (text ? <InputNumber defaultValue={text} onChange={(val) => {
                        row.rate = val
                        if (row.index >= 0) this.state.details[row.index].rate = val
                    }} /> : <InputNumber onChange={(val) => { row.rate = val }} />)
                }
            }
        ];
    }
    componentDidMount() {
        this.apiRequest();
        this.props.dispatch({ type: 'TOGGLE_SIDEBAR_COLLAPSED' })
    }
    editRate = async (record) => {
        this.setState({ edit: true })
        let category = []
        _.forEach(record.rateChartCategories.categories, (cat) => {
            category.push(cat.name)
        })
        let { data: details } = await Request.getRateChartDetails({ rateChartId: record.id })
        record.details = [...details]
        record.category = [...category]
        this.setState({ details })
        if (record.type == 'FAT Only') {
            this.handleFatRateGrid(record)
        }
        else this.handleFatSnfRateGrid(record)
    }
    getRateChart = (type = 'Kg Fat') => {
        return new Promise(async (resolve) => {
            this.setState({ loading: true })
            this.props.dispatch(showLoader())
            let rateCharts = await Request.listRate({ device: this.state.device.id, type });
            console.log(rateCharts)
            this.props.dispatch(hideLoader())
            this.setState({
                showTable: true,
                loading: false,
                saveLoading: false,
                showForm: false,
                showGrid: false,
                edit: false
            })
            if (!rateCharts.error) {
                let newData = []
                Async.eachOf(rateCharts.data, async (data, i, cb) => {
                    if (data.rateChartCategories) {
                        let arr = []
                        _.forEach(data.rateChartCategories.categories, val => {
                            arr.push(val.name)
                        })
                        newData[i] = [...arr]
                    }
                    return cb()
                }, (err) => {
                    this.setState({
                        dataSource: rateCharts.data || [],
                        catValues: newData,
                    })
                })
            }
        })
    }
    apiRequest = (params = {}) => {
        return new Promise(async (resolve) => {
            this.setState({ tableLoading: true })
            const deviceId = getUrlParams('devices.infoDevice', this.props.pathname).id;
            let data = await Request.getDevice(deviceId);
            this.setState({
                device: data.data,
                categories: data.data.mcp.categories,
                tableLoading: false,
                loading: false,
            }, () => {
                this.categoriesOptions(this.state.categories)
            })
        })
    }
    categoriesOptions = (array) => {
        const childrens = [];
        _.forEach(array, (val) => {
            childrens.push(<Option key={val.id} value={val.name}>{val.name}</Option>);
        })
        this.setState({ catChildrens: childrens })
        return childrens;
    }
    handleDelete = async (id) => {
        await Request.deleteRate(id)
        this.reset()
    };
    handleSave = row => {
        const newData = [...this.state.dataSource];
        const index = row.key ? newData.findIndex(item => (row.key === item.key)) : newData.findIndex(item => (row.id === item.id))
        const item = newData[index];
        newData.splice(index, 1, {
            ...item,
            ...row,
        });
        this.setState({ dataSource: newData }, () => console.log(this.state));
    };
    //delete me
    updateRate = () => {
        this.setState({ saveLoading: true })
        let data = _.cloneDeep(this.state.dataSource)
        Async.forEach(data, (value, done) => {
            Async.eachOf(value.rateChartCategories.categories, async (cat, i, cb) => {
                await Request.addCategory({ name: cat.name, mcp: this.state.device.mcp.id })
                return cb()
            }, async () => {
                let id = value.id
                delete value.id
                let rate = await Request.updateRate(id, { ...value })
                return done()
            })
        }, () => {
            setTimeout(this.reset, 1000)
            this.setState({ saveLoading: false })
            notification.success({ message: 'Rate Chart Updated' })
        })
    }
    handleInputChange = (val, key) => {
        if ((key == 'fatInc' && val == 0) || (key == 'snfInc' && val == 0))
            return false;
        this.setState({ [key]: val })
    }
    handleKgRate = (valData) => {
        const { dispatch, form } = this.props
        const { device } = this.state
        dispatch(showLoader())
        valData.device = device.id
        valData.mcp = device.mcp.id
        valData.type = 'Kg Fat'
        valData.categories = []
        let categories = form.getFieldValue('category')
        Async.forEach(categories, async (cat, cb) => {
            const { data } = await Request.addCategory({ name: cat, mcp: device.mcp.id })
            valData.categories.push(data)
            return cb()
        }, async (err) => {
            if (!err) {
                console.log(valData)
                let x = await Request.addRate(valData)
                dispatch(hideLoader())
                if (x.data) {
                    this.getRateChart()
                }

                if (!x.error) {
                    notification.success({
                        message: x.message
                    })
                    form.resetFields()
                }
                else {
                    notification.error({
                        message: 'Error Saving',
                        description: x.message
                    })
                }
            }
            else {
                dispatch(hideLoader())
            }
        })
    }
    handleSubmit = (e) => {
        const { dispatch, form } = this.props
        const { device, rateType } = this.state
        e.preventDefault()
        form.validateFieldsAndScroll((err, valData) => {
            if (!err) {
                if (rateType == 'kgFat') this.handleKgRate(valData)
                if (rateType == 'fatSnf') this.handleFatSnfRateGrid(valData)
                if (rateType == 'fatOnly') this.handleFatRateGrid(valData)
            }
        })
    }
    fatSNfGrid = () => {
        let fatData = _.cloneDeep(this.state.fatDataSources)
        let snfData = _.cloneDeep(this.state.snfDataSource)
        let dataSource = []
        _.forEach(fatData, (data, index) => {
            _.forEach(data, (subData) => {
                subData = { ...subData, ...snfData[index] }
                dataSource.push(subData)
            })
        })
        return dataSource;
    }
    reset = () => {
        let type = (this.state.rateType == 'fatOnly') ? 'FAT Only' : (this.state.rateType == 'kgFat') ? 'Kg Fat' : 'FAT + SNF'
        this.getRateChart(type)
    }
    handleEdit = () => {
        const { details } = this.state
        if (!this.checkPrice(details)) {
            this.setState({ saveLoading: true })
            _.forEach(details, async (chartDetail) => {
                await Request.updateRateChartDetails(chartDetail.id, { rate: chartDetail.rate })
                this.setState({ saveLoading: false }, this.reset)
            })
            notification.success({ message: 'Rates Updated Successfully' })
        }
    }
    checkPrice = (arr) => {
        console.log(arr)
        let flag = false
        _.forEach(arr, (val) => {
            if (val.rate <= 0) {
                flag = true
                notification.error({ message: 'Price must be greater than Zero' })
                return false
            }
        })
        return flag
    }
    handleRateGrid = async () => {
        const { dispatch, form } = this.props
        let { device, dataSource, rateType } = this.state
        if (rateType == 'fatSnf') dataSource = this.fatSNfGrid()
        if (!this.checkPrice(dataSource)) {
            this.setState({ saveLoading: true })
            dataSource[0].device = device.id
            dataSource[0].mcp = device.mcp.id
            dataSource[0].categories = []
            Async.forEach(dataSource[0].rateChartCategories.categories, async (cat, cb) => {
                const { data } = await Request.addCategory({ name: cat, mcp: device.mcp.id })
                dataSource[0].categories.push(data)
                return cb()
            }, async (err) => {
                if (!err) {
                    let rateChart = await Request.addRate(dataSource[0])
                    console.log(rateChart)
                    this.setState({ saveLoading: false })

                    if (!rateChart.err) {
                        _.forEach(dataSource, async (valData, i) => {
                            valData.rateChartId = rateChart.data.id
                            let data = await Request.addRateChartDetails(valData)
                        })
                        notification.success({
                            message: 'Rate Chart added successfully',
                            description: rateChart.message
                        })
                        form.resetFields()
                        this.reset()
                    }
                    else {
                        notification.error({
                            message: 'Error Saving Rate Chart',
                            description: rateChart.message
                        })
                    }
                }
            })
        }
    }
    rateTable = (type, columns, components) => {
        const { dataSource } = this.state;
        const { loading } = this.props;
        let fatColumns = _.cloneDeep(columns)
        fatColumns.splice(2, 1)
        if (type == 'fatSnf') fatColumns.splice(3, 0, { ...this.snfColumn })
        return <Table
            loading={loading}
            style={{ marginTop: '1em' }}
            components={components}
            rowClassName={() => 'editable-row'}
            bordered
            pagination={false}
            dataSource={dataSource}
            columns={fatColumns}
        />
    }
    handleFatRateGrid = (valData) => {
        this.setState({ showGrid: true })
        console.log(valData)
        let { fatMax, fatMin, fatIncrement, chartName, milkType } = valData
        if (fatMin && fatMax && fatIncrement > 0) {
            let fatDataSource = []
            for (let i = 0; i < (((fatMax - fatMin) / fatIncrement) + 1); i++) {
                let obj = {
                    chartName: chartName,
                    type: 'FAT Only',
                    rate: 0,
                    key: i,
                    rateChartCategories: { categories: [...valData.category] },
                    fat: (parseInt(fatMin) + (parseFloat(fatIncrement) * i)).toFixed(1),
                    fatMax,
                    fatMin,
                    fatIncrement,
                    milkType: milkType
                }
                if (valData.details && valData.details[i] && valData.details[i].rate) {
                    obj.rate = valData.details[i].rate
                }
                fatDataSource.push(obj)
            }
            this.setState({
                dataSource: fatDataSource,
                showTable: false,
                showGrid: true,
                showForm: false
            })
        }
    }
    handleFatSnfRateGrid = (valData) => {
        const { fatMax, fatMin, fatIncrement, snfMax, snfMin, snfIncrement, chartName, milkType } = valData
        console.log(valData)
        if (fatMin && fatMax && fatIncrement > 0) {
            let fatDataSource = []
            for (let i = 0; i < (((fatMax - fatMin) / fatIncrement) + 1); i++) {
                let obj = {
                    chartName: chartName,
                    type: 'FAT + SNF',
                    rate: 0,
                    key: i,
                    rateChartCategories: { categories: [...valData.category] },
                    fat: (parseInt(fatMin) + (parseFloat(fatIncrement) * i)).toFixed(1),
                    fatMax,
                    fatMin,
                    fatIncrement,
                    milkType: milkType,
                }
                if (valData.details) obj.details = valData.details
                fatDataSource.push(obj)
                this.setState({
                    fatDataSource,
                    showTable: false,
                    showGrid: true,
                    showForm: false
                })

            }
            if (snfMin && snfMax && snfIncrement > 0) {
                let snfDataSource = []
                for (let i = 0; i < (((snfMax - snfMin) / snfIncrement) + 1); i++) {
                    let obj = {
                        snf: (parseInt(snfMin) + (parseFloat(snfIncrement) * i)).toFixed(1),
                        snfMax,
                        snfMin,
                        snfIncrement
                    }
                    snfDataSource.push(obj)
                }
                this.setState({ snfDataSource })
            }
        }
    }
    render() {
        const { dataSource, device, rateType, fatDataSource, details, fatDataSources, edit, snfDataSource, showGrid, saveLoading, showTable, showForm, fatMilkType, fatChartName } = this.state;
        const {
            form: { getFieldDecorator, getFieldValue, setFields },
            loading
        } = this.props
        const validateRule = {
            rules: [{ required: true, message: 'Required field' }]
        }
        const fIAll = {
            getFieldDecorator,
            validateRule
        }
        const components = {
            body: {
                row: EditableFormRow,
                cell: EditableCell,
            },
        };
        const columns = this.columns.map(col => {
            if (!col.editable) {
                return col;
            }
            return {
                ...col,
                onCell: record => ({
                    record,
                    editable: col.editable,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    handleSave: this.handleSave,
                }),
            };
        });
        const selectComp = () => {
            return (
                <div>
                    <Form layout='inline'>
                        <Form.Item label='Rate Chart'>
                            <Select
                                style={{ width: '200px', marginLeft: '10px' }}
                                placeholder='Select Rate Chart Type'
                                onChange={async (val) => {
                                    if (val == 'kgFat') {
                                        this.getRateChart()
                                        this.setState({ rateType: val })
                                    }
                                    if (val != 'kgFat') {
                                        this.setState({ dataSource: [], rateType: val }, this.reset)
                                    }
                                }}
                            >
                                <Option value='kgFat'>KG FAT RATE</Option>
                                <Option value='fatOnly'>FAT ONLY</Option>
                                <Option value='fatSnf'>FAT + SNF</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item>
                            <Button disabled={!showTable} type='primary' onClick={() => {
                                this.setState({ showForm: true, showGrid: false })
                            }}>Add New Rate Chart</Button>
                        </Form.Item>
                            <Form.Item>
                                <Button disabled={showTable || (!showGrid && !showForm && !showTable)} type='danger' onClick={this.reset}>Reset</Button>
                            </Form.Item>
                    </Form>
                </div>
            )
        }
        const kgForm = () => (
            <Form onSubmit={this.handleSubmit} layout='inline' >
                <FormItem
                    {...fIAll}
                    name='chartName'
                    layout='inline'
                />
                {rateType == 'kgFat' && <FormItem
                    {...fIAll}
                    name='fatRate'
                    label='Fat Rate'
                    layout='inline'
                />}
                <FormItem
                    {...fIAll}
                    name='milkType'
                    label='Milk Type'
                    inputType='SELECT'
                    options={['Cow', 'Buffalo', 'Mix']}
                    width={150}
                />
                {rateType != 'kgFat' && FatOnlyForm()}
                {rateType == 'fatSnf' && FatSnfForm()}
                <Form.Item
                    label='Category'
                >
                    {getFieldDecorator('category', { rules: [{ required: true }] })(

                        <Select
                            label='Category'
                            required={true}
                            mode="tags"
                            style={{ width: '150px' }}
                            size={'default'}
                            placeholder="Please select"
                        >
                            {this.state.catChildrens || []}
                        </Select>
                    )}

                </Form.Item>
                <Form.Item >
                    <Button type='primary' htmlType='submit' loading={loading} > Add</Button>
                </Form.Item>
            </Form>
        )
        const FatOnlyForm = () => (<>
            <FormItem
                {...fIAll}
                name='fatMax'
                label='Maximum FAT'
                layout='inline'
                inputType='NUMBER'
            />
            <FormItem
                {...fIAll}
                name='fatMin'
                label='Minimum FAT'
                layout='inline'
                inputType='NUMBER'
            />
            <FormItem
                {...fIAll}
                name='fatIncrement'
                label='FAT Increment'
                layout='inline'
                inputType='NUMBER'
            />
        </>)
        const FatSnfForm = () => {
            return (<>
                <FormItem
                    {...fIAll}
                    name='snfMax'
                    label='Maximum SNF'
                    layout='inline'
                    inputType='NUMBER'
                />
                <FormItem
                    {...fIAll}
                    name='snfMin'
                    label='Minimum SNF'
                    layout='inline'
                    inputType='NUMBER'
                />
                <FormItem
                    {...fIAll}
                    name='snfIncrement'
                    label='SNF Increment'
                    layout='inline'
                    inputType='NUMBER'
                />
            </>)
        }
        const kgFat = (columns, components) => {
            const { loading } = this.props;
            return (
                <Table
                    loading={loading}
                    components={components}
                    rowClassName={() => 'editable-row'}
                    bordered
                    pagination={false}
                    dataSource={dataSource}
                    columns={columns}
                />
            )
        }
        return (
            <>
                <Card id={'deviceInfo'}>
                    {device && <BlackBox name={device.name} imei={device.imei} />}
                </Card>
                <Card className={styles.card}>
                    {device && <Language className={styles.language} language={device.language} />}
                </Card>
                <Card className={styles.card2}>
                    {device && <Farmers farmers={device.farmers} />}
                </Card>

                <Divider className={styles.divider}>Rate Chart</Divider>
                <div className={styles.table}>{selectComp()}</div>
                {/* description */}
                {showGrid && (fatDataSource[0] || dataSource[0]) && <div className={styles.rateChartDetails}>
                    <Descriptions title={<div className={styles.detailsTitle}>Rate Chart Details</div>} layout="vertical">
                        <Descriptions.Item label={<b>Chart Name</b>}>{fatDataSource[0] ? fatDataSource[0].chartName : dataSource[0].chartName}</Descriptions.Item>
                        <Descriptions.Item label={<b>Type</b>}>{fatDataSource[0] ? fatDataSource[0].type : dataSource[0].type}</Descriptions.Item>
                        <Descriptions.Item label={<b>Milk Type</b>}>{fatDataSource[0] ? fatDataSource[0].milkType : dataSource[0].milkType}</Descriptions.Item>
                        <Descriptions.Item label={<b>Minimum FAT</b>} >{fatDataSource[0] ? fatDataSource[0].fatMin : dataSource[0].fatMin}</Descriptions.Item>
                        <Descriptions.Item label={<b>Maximum FAT</b>} >{fatDataSource[0] ? fatDataSource[0].fatMax : dataSource[0].fatMax}</Descriptions.Item>
                        <Descriptions.Item label={<b>Increment FAT</b>} >{fatDataSource[0] ? fatDataSource[0].fatIncrement : dataSource[0].fatIncrement}</Descriptions.Item>
                        {rateType == 'fatSnf' && snfDataSource[0] && <Descriptions.Item label={<b>Maximum SNF</b>} >{snfDataSource[0].snfMax}</Descriptions.Item>}
                        {rateType == 'fatSnf' && snfDataSource[0] && <Descriptions.Item label={<b>Minimum SNF</b>} >{snfDataSource[0].snfMin}</Descriptions.Item>}
                        {rateType == 'fatSnf' && snfDataSource[0] && <Descriptions.Item label={<b>Increment SNF</b>} >{snfDataSource[0].snfIncrement}</Descriptions.Item>}
                    </Descriptions>
                </div>}
                {rateType && !(rateType == 'fatSnf' && showGrid) && <div className={styles.table}>
                    {showForm && <div style={{ marginTop: '20px', border: '1px solid lightGrey', padding: '1em' }}>
                        {kgForm()}
                    </div>}
                    {rateType == 'kgFat' && showTable && kgFat(columns, components)}
                    {rateType != 'kgFat' && showTable && this.rateTable(rateType, columns, components)}
                    {showGrid && rateType == 'fatOnly' &&
                        <Table
                            bordered
                            pagination={false}
                            columns={this.fatColumn}
                            dataSource={edit ? details : dataSource}
                        />}

                </div>}

                {rateType == 'fatSnf' && showGrid && <div className={styles.cardContainer}>
                    <Tabs type="card" tabPosition='left' >
                        {
                            snfDataSource.map((snf, key) => {
                                fatDataSources[key] = _.cloneDeep(fatDataSource)
                                _.forEach(fatDataSources[key], (valData) => {
                                    if (valData.details) {
                                        _.forEach(valData.details, (val, i) => {
                                            if (val.fat == valData.fat && val.snf == snf.snf) {
                                                valData.rate = val.rate
                                                valData.index = i
                                                return false
                                            }
                                        })
                                    }
                                })
                                return <TabPane tab={`SNF ${snf.snf}`} key={key + 1}>
                                    <div className={styles.snfTable}>
                                        <Table
                                            bordered
                                            rowKey={(record) => key + record.key}
                                            pagination={false}
                                            columns={this.fatColumn}
                                            dataSource={fatDataSources[key]}
                                        />
                                    </div>
                                </TabPane>
                            })
                        }
                    </Tabs>
                </div>}
                {(showTable || showGrid) &&
                    <Button type='primary' className={styles.saveBtn} loading={saveLoading} onClick={!showGrid ? this.updateRate : edit ? this.handleEdit : this.handleRateGrid}>Save</Button>}
            </>
        );
    }
}

const mapStateToProps = ({ global, router }) => ({
    loading: global.buttonLoading,
    categories: global.categories,
    currentUser: global.currentUser,
    pathname: router.location.pathname
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RateChart)
