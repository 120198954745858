import React, { useEffect, useState, useRef } from 'react'
import {
  notification,
  Table,
  Button,
  Spin,
  Form,
  Drawer, Card, Tag,
  Select,
  Tooltip,
  Popconfirm,
  Switch,
  Icon,
  Input,
  DatePicker
} from 'antd'
import Highlighter from 'react-highlight-words'
import async from 'async'
import { TableComp } from 'sz-react-utils-lite'
import _ from 'lodash'
import moment from 'moment'
import { dateFormat, timeStampFormat, apiUrl } from '../../../settings'
import Request, { API_URL } from '../../../request'
import { FormUtils as GetAllFormFields } from 'sz-react-utils-lite'
import { ListTestingUser } from '../actions/testingResultsAction'
import { useSelector, useDispatch } from 'react-redux'

const { RangePicker } = DatePicker

const AllTestingResults = (props) => {

  const dispatch = useDispatch()
  const [state, setState] = useState({
    defaultDateValue: [
      moment().startOf('day'),
      moment().endOf('day')
    ], dateFilters: {}, searchText: '', list: [], listFirst: [], filter: []
  })

  const { testingResultLoader, currentUser, testingResultList } = useSelector((state) => ({
    testingResultList: state.testingResults.testingResultList,
    testingResultLoader: state.testingResults.testingResultLoader,
    currentUser: state.global.currentUser
  }))
  useEffect(() => {
    // dispatch({type:'TESTING_RESULT_SHOW_LOADER'})
    const { filter } = state
    console.log('call!@#')
    filter['$gte'] = moment().startOf('day').format('YYYY-MM-DD HH:mm:ss')
    filter['$lt'] = moment().endOf('day').format('YYYY-MM-DD HH:mm:ss')
    let arrFirst = []

    _.forEach(testingResultList.data, (val, key) => {
      if (moment(val.testDate).isBetween(filter['$gte'], filter['$lt'])) {
        arrFirst.push(val)
      }
    })
    setState((prevState) => ({ ...prevState, listFirst: arrFirst, list: arrFirst }))

    // dispatch({type:'TESTING_RESULT_HIDE_LOADER'})

    // setState({ ...state, list: testingResultList.data })
  }, [testingResultList])
  useEffect(() => {
    const { filter } = state
    console.log(filter)
    if (filter.length !== 0) {
      filter['$gte'] = filter[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
      filter['$lt'] = filter[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
      console.log(filter[0].startOf('day').format('YYYY-MM-DD HH:mm:ss'))
      let arr = []
      _.forEach(testingResultList.data, (val, key) => {
        if (moment(val.testDate).isBetween(filter['$gte'], filter['$lt'])) {
          arr.push(val)
        }
      })
      setState((prevState) => ({ ...prevState, list: arr }))
    } else {
      // console.log(arrFirst)
      setState((prevState) => ({ ...prevState, list: state.listFirst }))
    }
  }, [state.filter])
  const [pagination, setPagination] = useState({})
  useEffect(() => {
    dispatch(ListTestingUser())
  }, [])
  console.log(testingResultList.data)
  const {
    form: { getFieldDecorator }
  } = props
  const getColumnDateSearchProps = (dataIndex) => ({
    filterDropdown: (pro) => {
      let { setSelectedKeys, selectedKeys, confirm, clearFilters } = pro
      return (
        <div
          style={{
            padding: '8px',
            borderRadius: '4px',
            backgroundColor: '#ffffff',
            boxShadow: '0 2px 8px rgba(0, 0, 0, .15)'
          }}>
          <RangePicker
            className={`search-value-${dataIndex}`}
            style={{ width: 250, marginBottom: 8, display: 'block' }}
            onChange={(date) => {
              if (!date.length == 0) {
                setSelectedKeys({
                  $gte: date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss'),
                  $lt: date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
                })
              }

            }}
          />

          <div style={{ flex: 1, justifyContent: 'flex-end' }}>
            <Button
              className={`search-${dataIndex}`}
              type="primary"
              onClick={() => {
                let dateFilters = _.clone(state.dateFilters)

                dateFilters[dataIndex] = true
                setState(() => ({ dateFilters }))
                handleDateSearch(selectedKeys, confirm)
                confirm()
              }}
              icon="search"
              size="small"
              style={{ width: 90, marginRight: 8 }}>
              Search
            </Button>
            <Button
              className={`reset-${dataIndex}`}
              onClick={() => {
                let dateFilters = _.clone(state.dateFilters)

                dateFilters[dataIndex] = false

                setState(() => ({
                  ...state,
                  dateFilters,
                  filter: null
                }))
                clearFilters()
              }}
              size="small"
              style={{ width: 90 }}>
              Reset
            </Button>
          </div>
        </div>
      )
    },
    filterIcon: (x) => {
      let { dateFilters } = state
      let filtered = dateFilters && dateFilters[dataIndex]
      return (
        <Icon
          className={`filter-menu-${dataIndex}`}
          type="search"
          style={{ color: filtered ? '#1890ff' : undefined }}
        />
      )
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        // setTimeout(() => searchInput.focus())
      }
    }
  })

  const handleSearch = (selectedKeys, confirm) => {
    confirm()
    setState({ ...state, searchText: selectedKeys[0] })
  }
  const handleDateSearch = (selectedKeys, confirm) => {
    setState(() => ({ ...state, filter: selectedKeys }))
    confirm()
  }
  const getColumnSearchProps = (dataIndex, index) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          id={`input-${dataIndex}`}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          id={`search-${dataIndex}`}
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button id={`reset-${dataIndex}`} onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon id={`filter-menu-${dataIndex}`} style={{ color: filtered ? '#1890ff' : undefined }} type='search'/>
    ),
    onFilter: (value, record) => {
      return index ?
             (
               record[dataIndex][index]
                 .toString()
                 .toLowerCase()
                 .includes(value.toLowerCase())

             ) :
             (
               record[dataIndex]
                 .toString()
                 .toLowerCase()
                 .includes(value.toLowerCase())

             )
    },
    render: text => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    )
  })

  const handleReset = clearFilters => {
    clearFilters()
    setState({ ...state, searchText: '' })
  }
  const viewImage = (val) => {
    let data = 'data:image/png;base64,' + val
    let w = window.open('about:blank')
    let image = new Image()
    image.src = data
    setTimeout(function() {
      w.document.write(image.outerHTML)
    }, 0)
  }
  const columns = [
    {
      title: 'Testing User Name',
      dataIndex: 'userId',
      key: 'userId',
      width: 100,
      ...getColumnSearchProps('userId', 'name'),
      render: (val) => {

        if (!val || !val.name) {
          return <div>Nothing found</div>
        }

        return <div>
          <div>{val.name}</div>
          <div>({val.mobile})</div>
        </div>

      }
    },

    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      width: 100
    },
    {
      title: 'Analyser Data',
      dataIndex: 'analyser',
      key: 'analyser',
      width: 100,
      render: (val) => {
        if (val.length !== 1) {
          return val
        } else return <div>no data</div>
      }
    },

    {
      title: 'Print Image',
      dataIndex: 'printer',
      kwy: 'printer',
      width: 100,
      render: (val) => {
        if (val) {

          let src = `${apiUrl}${val}`
          return <a href={src} target={'_blank'}>
            <img style={{ width: '40px', height: '40px' }} src={src}/>
          </a>
        }
        return <img src={''} alt='no image'/>
      }
    },
    {
      title: 'Test Date',
      dataIndex: 'testDate',
      key: 'testDate',
      width: 100,
      // searchDateName:'testDate',
      render: (val) => {
        return (<Tag color='blue'> {moment(val).format(timeStampFormat)}</Tag>)
      }
      // ...getColumnDateSearchProps('testDate')

    }
  ]
  return (
    <Card title={`All Testing Results (${state.list ? state.list.length : 0})`}>
      <RangePicker
        defaultValue={state.defaultDateValue}
        style={{ marginBottom: 10 }}
        allowClear={true}
        id={'rangePicker'}
        onChange={date => {
          setState(() => ({ ...state, filter: date }))
        }}
      />
      <Table
        size='small'
        loading={testingResultLoader}
        columns={columns}
        dataSource={state.list}
        pagination={{
          ...pagination,
          defaultPageSize: 10,
          pageSizeOptions: ['10', '25', '50', '100', '500'],
          showSizeChanger: false
        }}
      />
    </Card>
  )
}
const WrappedComponent = Form.create()(AllTestingResults)

export default WrappedComponent
