import React, { useEffect, useState } from 'react'
import { Card, Descriptions } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import { GetMcpDetailAction } from '../action/debugActions'

const LeftSideDetails = (props) => {

    const dispatch = useDispatch()
    const [mcp, setMcp] = useState({})

    useEffect(() => {
        if (props.mcpId)
            dispatch(GetMcpDetailAction(props.mcpId))
    }, [props.mcpId])

    const { currentUser, mcpDetails } = useSelector((state) => ({
        currentUser: state.global.currentUser,
        mcpDetails: state.debug.mcpDetails.data || {}
    }))


    return (
        <Card size='small' title={<b>{mcpDetails.companyId && mcpDetails.companyId.name}</b>}>
            <Descriptions size='small' bordered layout='vertical'>
                <Descriptions.Item span={3} label={<b>Company Address</b>}>{mcpDetails.companyId && mcpDetails.companyId.address}</Descriptions.Item>
                <Descriptions.Item span={3} label={<b>MCP Name</b>}>{mcpDetails.name}</Descriptions.Item>
                <Descriptions.Item span={3} label={<b>MCP Id</b>}>{mcpDetails.mcpId}</Descriptions.Item>
                <Descriptions.Item span={3} label={<b>MCP UUID</b>}>{mcpDetails.uuid}</Descriptions.Item>
                <Descriptions.Item span={3} label={<b>Used</b>}>{mcpDetails.used ? 'True' : 'False'}</Descriptions.Item>
                <Descriptions.Item span={3} label={<b>Setting Level</b>}>{mcpDetails.settingLevel}</Descriptions.Item>
            </Descriptions>
        </Card>
    )
}

export default LeftSideDetails
