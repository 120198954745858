export default (
    state = {
        registerLoader: false,
        registerChecker: false,
    },
    action
) => {
    switch (action.type) {
        case "REGISTER_SHOW_LOADER":
            return { ...state, registerLoader: true };
        case "REGISTER_HIDE_LOADER":
            return { ...state, registerLoader: false };
        case "REGISTER_ERROR_CHECKER":
            return { ...state, registerChecker: false };
        case "REGISTER_SUCCESS_CHECKER":
            return { ...state, registerChecker: true };


        default:
            return state;
    }
};
