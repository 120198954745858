import axios from "axios";
import { listSettingUrl, getMcpDetailUrl } from "../api/debugUrl";
import { notification } from 'antd'
import { getToken } from "../../../request";

export const ListSettingsAction = (mcpId) => async (dispatch) => {
    dispatch({ type: 'DEBUG_SHOW_LOADER' });
    await axios
        .get(listSettingUrl(mcpId), getToken())
        .then(function ({ data }) {
            dispatch({ type: 'DEBUG_HIDE_LOADER' });
            if (data.error) {
                notification.error({
                    message: data.message,
                })
            }
            else
                dispatch({
                    type: 'SET_SETTINGS',
                    payload: data,
                });
        })
        .catch(function (error) {
            // toast.error(JSON.stringify(error));
            dispatch({ type: 'DEBUG_HIDE_LOADER' });
        });
};
export const GetMcpDetailAction = (mcpId) => async (dispatch) => {
    dispatch({ type: 'DETAILS_SHOW_LOADER' });
    await axios
        .get(getMcpDetailUrl(mcpId), getToken())
        .then(function ({ data }) {
            dispatch({ type: 'DETAILS_HIDE_LOADER' });
            if (data.error) {
                notification.error({
                    message: data.message,
                })
            }
            else
                dispatch({
                    type: 'SET_MCP_DETAILS',
                    payload: data,
                });
        })
        .catch(function (error) {
            // toast.error(JSON.stringify(error));
            dispatch({ type: 'DETAILS_HIDE_LOADER' });
        });
};