import React, { PureComponent } from 'react'
import PageHeaderWrapper from '../PageHeaderWrapper'
import styles from './styles.less'
import { Spin } from 'antd'
import _ from 'lodash'

import moment from 'moment'
import { FormUtils as GetAllFormFields } from 'sz-react-utils-lite'
import { API_URL } from '../../request'
//import GetAllFormFields from '../_utils/formUtils';
import { getUrlParams } from '../../routes'


import { notification } from 'antd/lib'
import { hideLoader, showLoader } from '../../modules/actions'
import Request from '../../request'
import { connect } from 'react-redux'

class GeneralSettings extends PureComponent {

  state = {
    cardLoading: false,
    allTemplates: [],
    key: moment(),
    loading: false
  }
  // }
  apiRequest = async () => {
    let id = getUrlParams('mcp.editMcc', this.props.pathname)
    const { currentUser, form, dispatch, companyId } = this.props
    if (!id) {
      this.setState({ loading: true })
      let data = await Request.getSetting(companyId)
      this.setState({ loading: false })
      if (data && data.data) {
        const settingObj = data.data
        console.log(settingObj, 'setting....')
        form.setFieldsValue({
          collectionMode: settingObj.collectionMode,
          dateFormat: settingObj.dateFormat,
          allowDelete: settingObj.allowDelete,
          measurement: settingObj.measurement,
          manualScale: settingObj.manualScale,
          manualAnalyser: settingObj.manualAnalyser,
          language: settingObj.language,
          clrFormula: settingObj.clrFormula,
          autoAcceptedTime: settingObj.autoAcceptedTime,
          weightStableTime: settingObj.weightStableTime,
          showRmrdReport: settingObj.showRmrdReport,
          screenPassword: settingObj.screenPassword || '0000',
          searchFarmerViaBarcode: settingObj.searchFarmerViaBarcode,
          allowReject: settingObj.allowReject,
          allowFarmerEdit: settingObj.allowFarmerEdit,
          ...settingObj.parameterDecimal
        })
      }
      else {
        form.setFieldsValue({
          screenPassword: '0000'
        })
      }
    }
  }

  // getTemplates = async () => {
  //   Request.getAllTemplates({ companyId: this.props.currentUser.companyId, type: 'collection' })
  //     .then(doc => {
  //       if (!doc.error && doc.data) {
  //         this.setState({ allTemplates: doc.data })
  //       }
  //     })

  componentDidMount() {
    // this.getTemplates()
    this.props.setDefaultSettings(this.apiRequest.bind(this))
    this.apiRequest()
  }

  onSelectChange = (key, val) => {
    this.setState({ [key]: val })
  }

  setFormCollectionMode = (value) => {
    const { form } = this.props
    form.setFieldsValue({
      collectionMode: value

    }, () => {
      this.setState({ key: moment() })
    })
  }

  render() {
    const {
      form: { getFieldDecorator, getFieldValue, setFieldsValue },
      setInputFields
    } = this.props


    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
        md: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
        md: { span: 12 }
      }
    }


    const inputType = {
      fields: [

        {
          label: 'Collection Mode',
          key: 'collectionMode',
          dataIndex: 'collectionMode',
          required: true,
          type: 'radioGroup',
          mode: 'solid',
          options: ['AUTOMATIC', 'MANUAL'],
          onChange: (e) => {
            if (e && e.target) {
              this.setFormCollectionMode(e.target.value)
            }
          }
        },

        {
          label: 'Manual Scale',
          key: 'manualScale',
          dataIndex: 'manualScale',
          type: 'switch',
          hidden: getFieldValue('collectionMode') == 'AUTOMATIC',
          onChange: (manualScale) => {
            setFieldsValue({ manualScale })
          }
        },

        {
          label: 'Manual Analyser',
          key: 'manualAnalyser',
          dataIndex: 'manualAnalyser',
          type: 'switch',
          hidden: getFieldValue('collectionMode') == 'AUTOMATIC',
          onChange: (manualAnalyser) => {
            setFieldsValue({ manualAnalyser })
          }
        },
        {
          label: 'Date Format',
          key: 'dateFormat',
          dataIndex: 'dateFormat',
          type: 'select',
          required: true,
          options: ['MM-DD-YY', 'DD-MM-YY'],
          onChange: (dateFormat) => {
            this.props.form.setFieldsValue({ dateFormat })
          }

        },
        {
          label: 'Allow Delete',
          key: 'allowDelete',
          dataIndex: 'allowDelete',
          type: 'switch',
          required: true,
          onChange: (allowDelete) => {
            setFieldsValue({ allowDelete })
          }

        },
       
        {
          label: 'Allow Farmer Edit',
          key: 'allowFarmerEdit',
          dataIndex: 'allowFarmerEdit',
          type: 'switch',
          required: true,
          onChange: (allowFarmerEdit) => {
            setFieldsValue({ allowFarmerEdit })
          }

        },
        {
          label: 'Measurement',
          key: 'measurement',
          dataIndex: 'measurement',
          type: 'select',
          required: true,
          options: [{ id: 'Kg', display: 'Kg' }, { id: 'Lt', display: 'Litre' }],
          onChange: (measurement) => {
            this.props.form.setFieldsValue({ measurement })
          }

        },
        {
          label: 'Language',
          key: 'language',
          dataIndex: 'language',
          type: 'select',
          required: true,
          options: [{ id: 'en', display: 'English' }, { id: 'hi', display: 'हिन्दी' }, { id: 'pn', display: 'ਪੰਜਾਬੀ' }],
          onChange: (language) => {
            this.props.form.setFieldsValue({ language })
          }

        },
        {
          label: 'Clr Settings',
          key: 'clrFormula',
          dataIndex: 'clrFormula',
          type: 'select',
          required: true,
          options: [
            '4(Snf-0.20(Fat%)-0.29)',
            '4(Snf-0.21(Fat%)-0.36)',
            '4(Snf-0.25(Fat%)-0.44)',
            '4(Snf-0.20(Fat%)-0.36)',
            '4(Snf-0.20(Fat%)-0.66)',
            '4(Snf-0.20(Fat%)-0.70)',
            '4(Snf-0.25(Fat%)-0.35)',
            '4(Snf-0.20(Fat%)-0.14)'
          ],
          onChange: (clrFormula) => {
            this.props.form.setFieldsValue({ clrFormula })
          }
        },
        {
          label: 'Auto Accepted Time',
          key: 'autoAcceptedTime',
          dataIndex: 'autoAcceptedTime',
          type: 'select',
          required: true,
          options: ['5', '10', '15', '20', '25'],
          onChange: (autoAcceptedTime) => {
            this.props.form.setFieldsValue({ autoAcceptedTime })
          }
        },
        {
          label: 'Weight Stable Time',
          key: 'weightStableTime',
          dataIndex: 'weightStableTime',
          type: 'select',
          required: true,
          options: ['5', '10', '15', '20', '25'],
          onChange: (weightStableTime) => {
            this.props.form.setFieldsValue({ weightStableTime })
          }
        },
        {
          label: 'Quantity RoundOff',
          key: 'quantity',
          dataIndex: 'quantity',
          type: 'select',
          required: true,
          options: ['1', '2', '3'],
          onChange: (quantity) => {
            this.props.form.setFieldsValue({ quantity })
          }
        },
        {
          label: 'Scanner RoundOff',
          key: 'scanner',
          dataIndex: 'scanner',
          type: 'select',
          required: true,
          options: ['1', '2', '3'],
          onChange: (scanner) => {
            this.props.form.setFieldsValue({ scanner })
          }
        },
        {
          label: 'Total Amount RoundOff',
          key: 'totalAmount',
          dataIndex: 'totalAmount',
          type: 'select',
          required: true,
          options: ['1', '2', '3'],
          onChange: (totalAmount) => {
            this.props.form.setFieldsValue({ totalAmount })
          }
        },
        {
          label: 'Show RMRD Report',
          key: 'showRmrdReport',
          dataIndex: 'showRmrdReport',
          type: 'switch',
          onChange: (showRmrdReport) => {
            setFieldsValue({ showRmrdReport })
          }
        },
        {
          label: 'Screen Password',
          key: 'screenPassword',
        },
        {
          label: 'Search Farmer Via Barcode',
          key: 'searchFarmerViaBarcode',
          dataIndex: 'searchFarmerViaBarcode',
          type: 'switch',
          onChange: (searchFarmerViaBarcode) => {
            setFieldsValue({ searchFarmerViaBarcode })
          }
        },
        {
          label: 'Allow Reject Option In Collections',
          key: 'allowReject',
          dataIndex: 'allowReject',
          type: 'switch',
          required: true,
          onChange: (allowReject) => {
            setFieldsValue({ allowReject })
          }
        }
      ]
    }
    setInputFields(inputType.fields.map(field => field.key), 2)

    return (
      <div className={styles.container}>
        <Spin tip={'Loading...'} spinning={this.state.loading}>
          <GetAllFormFields
            apiurl={API_URL}
            inputSchema={inputType}
            formItemLayout={formItemLayout}
            getFieldDecorator={getFieldDecorator}
          />
        </Spin>
      </div>

    )
  }
}

const mapStateToProps = ({ global, router }) => ({
  loading: global.buttonLoading,
  categories: global.categories,
  search: router.location.search,
  currentUser: global.currentUser,
  pathname: router.location.pathname

})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GeneralSettings)
