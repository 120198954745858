import React, { PureComponent } from 'react'
import PageHeaderWrapper from '../PageHeaderWrapper'
import styles from './styles.less'
import { Spin } from 'antd'
import _ from 'lodash'

import moment from 'moment'
import { FormUtils as GetAllFormFields } from 'sz-react-utils-lite'
import { API_URL } from '../../request'
//import GetAllFormFields from '../_utils/formUtils';
import { getUrlParams } from '../../routes'


import { notification } from 'antd/lib'
import { hideLoader, showLoader } from '../../modules/actions'
import Request from '../../request'
import { connect } from 'react-redux'
const defaultValue = {
  printer: 'On Board Printer',
  printHeader: 'Hindustan thermostatics',
  printFooter: 'Mobile AMCU ( www.amcu.in )',
  printFarmerNameAndId: true,
  printQuantity: true,
  printFat: true,
  printSnf: true,
  printMilkType: true,
  printCollectionDate: true,
  printCollectionTime: true,
  printRate: true,
  printTotalAmount: true,
  printStatus: true,
  printTimeOfPrint: true,
  printIncentive: true,
  printFarmerMobile: false,
  printClr: false,
  printDensity: false,
  printLactose: false,
  printSalts: false,
  printProtein: false,
  printTemp: false,
  printAddedWater: false,
  printFreezingPoint: false,
  printRateChartName: false,
  printStage: false,
}
class PrinterSettings extends PureComponent {

  state = {
    cardLoading: false,
    allTemplates: [],
    key: moment(),
    loading: false
  }


  componentDidMount() {
    this.props.setDefaultSettings(this.apiRequest.bind(this))
    this.apiRequest()
  }

  apiRequest = async () => {
    let id = getUrlParams('mcp.editMcc', this.props.pathname)
    const { currentUser, form, companyId, settingLevel } = this.props
    this.setState({ loading: true })
    let filter = { level: settingLevel, companyId }
    if (settingLevel == 'MCP' && id && id.id) {
      filter.mcpId = id.id
    }
    else
      filter.level = 'COMPANY'
    let data = await Request.getMachineSettings(filter)
    this.setState({ loading: false })
    if (data && data.data.length) {
      let obj = {}
      form.setFieldsValue({ tear: true })
      _.forEach(data.data, (setting) => {
        if (setting.type === 'printerParams') {
          _.forEach(setting.value, (param, key) => {
            obj[_.camelCase(`print ${key}`)] = param
          })
        }
        if (setting.type === 'printerHeaderFooter') {
          obj.printHeader = setting.value.header || ''
          obj.printFooter = setting.value.footer || ''
        }
        else
          obj[setting.type] = setting.value || setting.enabled

      })
      form.setFieldsValue(obj)
    }
    else {
      form.setFieldsValue(defaultValue)
    }

    // dispatch(hideLoader())
  }

  render() {
    const { key, allTemplates } = this.state
    const { submitting, settingLevel, isCompany } = this.props
    const {
      form: { getFieldDecorator, getFieldValue, setFieldsValue },
      setInputFields
    } = this.props


    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
        md: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
        md: { span: 12 }
      }
    }


    const inputType = {
      fields: [
        {
          label: 'Printer Type',
          key: 'printer',
          dataIndex: 'printer',
          type: 'select',
          required: true,

          options: [
            { display: 'On Board Printer', id: 'onBoardRD' },
            { display: 'Sunmi Inner Printer', id: 'sunmi' },
            { display: 'Bluetooth Printer 58mm', id: 'bluetooth58mm' }],

          onChange: (printer) => {
            this.props.form.setFieldsValue({ printer })
          }
        },
        {
          label: 'Header',
          key: 'printHeader',
          // dataIndex: 'farmerTitle',
        },
        {
          label: 'Footer',
          key: 'printFooter',
          // dataIndex: 'farmerTitle',
        },
        {
          label: 'Farmer Name AND Id',
          key: 'printFarmerNameAndId',
          dataIndex: 'printFarmerName',
          type: 'switch',
          onChange: (printFarmerNameAndId) => {
            setFieldsValue({ printFarmerNameAndId })
          }
        },
        {
          label: 'Farmer Mobile',
          key: 'printFarmerMobile',
          dataIndex: 'printFarmerMobile',
          type: 'switch',
          onChange: (printFarmerMobile) => {
            setFieldsValue({ printFarmerMobile })
          }
        },
        {
          label: 'Quantity',
          key: 'printQuantity',
          dataIndex: 'printQuantity',
          type: 'switch',
          onChange: (printQuantity) => {
            setFieldsValue({ printQuantity })
          }
        },
        {
          label: 'Fat',
          key: 'printFat',
          dataIndex: 'printFat',
          type: 'switch',
          onChange: (printFat) => {
            setFieldsValue({ printFat })
          }
        },
        {
          label: 'SNF',
          key: 'printSnf',
          dataIndex: 'printSnf',
          type: 'switch',
          onChange: (printSnf) => {
            setFieldsValue({ printSnf })
          }
        },
        {
          label: 'CLR',
          key: 'printClr',
          dataIndex: 'printClr',
          type: 'switch',
          onChange: (printClr) => {
            setFieldsValue({ printClr })
          }
        },
        {
          label: 'Density',
          key: 'printDensity',
          dataIndex: 'printDensity',
          type: 'switch',
          onChange: (printDensity) => {
            setFieldsValue({ printDensity })
          }
        },
        {
          label: 'Lactose',
          key: 'printLactose',
          dataIndex: 'printLactose',
          type: 'switch',
          onChange: (printLactose) => {
            setFieldsValue({ printLactose })
          }
        },
        {
          label: 'Salts',
          key: 'printSalts',
          dataIndex: 'printSalts',
          type: 'switch',
          onChange: (printSalts) => {
            setFieldsValue({ printSalts })
          }
        },
        {
          label: 'Protein',
          key: 'printProtein',
          dataIndex: 'printProtein',
          type: 'switch',
          onChange: (printProtein) => {
            setFieldsValue({ printProtein })
          }
        },
        {
          label: 'Temp',
          key: 'printTemp',
          dataIndex: 'printTemp',
          type: 'switch',
          onChange: (printTemp) => {
            setFieldsValue({ printTemp })
          }
        },
        {
          label: 'Added Water',
          key: 'printAddedWater',
          dataIndex: 'printAddedWater',
          type: 'switch',
          onChange: (printAddedWater) => {
            setFieldsValue({ printAddedWater })
          }
        },
        {
          label: 'Freezing Point',
          key: 'printFreezingPoint',
          dataIndex: 'printFreezingPoint',
          type: 'switch',
          onChange: (printFreezingPoint) => {
            setFieldsValue({ printFreezingPoint })
          }
        },
        {
          label: 'Milk Type',
          key: 'printMilkType',
          dataIndex: 'printMilkType',
          type: 'switch',
          onChange: (printMilkType) => {
            setFieldsValue({ printMilkType })
          }
        },
        {
          label: 'Collection Date',
          key: 'printCollectionDate',
          dataIndex: 'printCollectionDate',
          type: 'switch',
          onChange: (printCollectionDate) => {
            setFieldsValue({ printCollectionDate })
          }
        },
        {
          label: 'Collection Time',
          key: 'printCollectionTime',
          dataIndex: 'printCollectionTime',
          type: 'switch',
          onChange: (printCollectionTime) => {
            setFieldsValue({ printCollectionTime })
          }
        },
        {
          label: 'Rate Chart Name',
          key: 'printRateChartName',
          dataIndex: 'printRateChartName',
          type: 'switch',
          onChange: (printRateChartName) => {
            setFieldsValue({ printRateChartName })
          }
        },
        {
          label: 'Rate',
          key: 'printRate',
          dataIndex: 'printRate',
          type: 'switch',
          onChange: (printRate) => {
            setFieldsValue({ printRate })
          }
        },
        {
          label: 'Total Amount',
          key: 'printTotalAmount',
          dataIndex: 'printTotalAmount',
          type: 'switch',
          onChange: (printTotalAmount) => {
            setFieldsValue({ printTotalAmount })
          }
        },
        {
          label: 'Status',
          key: 'printStatus',
          dataIndex: 'printStatus',
          type: 'switch',
          onChange: (printStatus) => {
            setFieldsValue({ printStatus })
          }
        },
        {
          label: 'Stage',
          key: 'printStage',
          dataIndex: 'printStage',
          type: 'switch',
          onChange: (printStage) => {
            setFieldsValue({ printStage })
          }
        },
        {
          label: 'Time Of Print',
          key: 'printTimeOfPrint',
          dataIndex: 'printTimeOfPrint',
          type: 'switch',
          onChange: (printTimeOfPrint) => {
            setFieldsValue({ printTimeOfPrint })
          }
        },
        {
          label: 'Incentive',
          key: 'printIncentive',
          dataIndex: 'printIncentive',
          type: 'switch',
          onChange: (printIncentive) => {
            setFieldsValue({ printIncentive })
          }
        },
      ]
    }
    setInputFields(inputType.fields.map(field => field.key), 7)

    return (
      <div className={styles.container}>
        {settingLevel === 'COMPANY' && !isCompany ?
          <h4 style={{ color: 'blue' }}>{'NOTE :This MCP has company level settings'}</h4>
          : <Spin tip={'Loading...'} spinning={this.state.loading}>
            <GetAllFormFields
              apiurl={API_URL}
              inputSchema={inputType}
              formItemLayout={formItemLayout}
              getFieldDecorator={getFieldDecorator}
            />
          </Spin>}
      </div>

    )
  }
}

const mapStateToProps = ({ global, router }) => ({
  loading: global.buttonLoading,
  categories: global.categories,
  search: router.location.search,
  currentUser: global.currentUser,
  pathname: router.location.pathname

})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrinterSettings)
