export default (
    state = {
        mcpDetailsLoader: false,
        debugLoader: false,
        settings: {},
        mcpDetails: {}
    },
    action
) => {
    switch (action.type) {
        case "DEBUG_SHOW_LOADER":
            return { ...state, debugLoader: true };
        case "DEBUG_HIDE_LOADER":
            return { ...state, debugLoader: false };
        case "DETAILS_SHOW_LOADER":
            return { ...state, mcpDetailsLoader: true };
        case "DETAILS_HIDE_LOADER":
            return { ...state, mcpDetailsLoader: false };
        case "SET_SETTINGS":
            return { ...state, settings: action.payload };
        case "SET_MCP_DETAILS":
            return { ...state, mcpDetails: action.payload };
        default:
            return state;
    }
};
