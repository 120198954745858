import { apiUrl } from "../../../settings";

export const addTestingUserUrl = () => {
    return apiUrl + '/testingUser/add';
};
export const listTestingUserUrl = () => {
    return apiUrl + '/testingUser/list' ;
};
export const editTestingUserUrl = (id) => {
    return apiUrl + '/testingUser/'+id ;
};
export const deleteTestingUser = (id) => {
    return apiUrl + '/testingUser/'+id
}

