import React, { Component } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { getPushPathWrapper } from '../../../routes'
import { TableComp } from 'sz-react-utils-lite'
import {
    Table,
    Badge,
    Card,
    Tooltip,
    notification,
    Switch,
    Tag,
    Input, Button, Icon, Select, Form
} from 'antd'
import Request from '../../../request'
import Color from 'color'
import _ from 'lodash'
import Highlighter from 'react-highlight-words'
import { connect } from 'react-redux'
import memoizeOne from 'memoize-one'
import update from 'immutability-helper'
import styles from './style.less'
import { hideLoader, showLoader } from '../../../modules/actions';
import { any } from 'prop-types'
import capitalize from 'capitalize'
const { Option } = Select
@Form.create()
class listDevices extends Component {

    state = {
        tableLoading: false,
        loading: false,
        dataSource: [],
        companies: []
    }


    componentDidMount() {
        this.apiRequest();
        this.getCompanies();
    }
    apiRequest = (params = {}) => {
        return new Promise(async (resolve) => {
            this.setState({ tableLoading: true })

            let data = await Request.listDevices();
            this.setState({
                dataSource: data.error ? [] : data.data,
                tableLoading: false,
                loading: false,
                filteredInfo: params.filters || {}
            })
            return resolve(data.data)
        })
    }
    getCompanies = (params = {}) => {
        return new Promise(async (resolve) => {
            this.setState({ tableLoading: true })

            let data = await Request.listCompanies();
            this.setState({
                companies: data.error ? [] : data.data,
            })
            return resolve(data.data)
        })
    }
    handleChange = (pagination, filters, sorter) => {
        console.log('Various parameters', pagination, filters, sorter);
        this.setState({
            filteredInfo: filters,
        });
        this.apiRequest({
            results: pagination.pageSize,
            page: pagination.current,
            sortField: sorter.field,
            sortOrder: sorter.order,
            filters
        })
    };

    getColumnSearchProps = (dataIndex, index) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    id={`input-${dataIndex}`}
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    id={`search-${dataIndex}`}
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm)}
                    icon="search"
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
            </Button>
                <Button id={`reset-${dataIndex}`} onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reset
            </Button>
            </div>
        ),
        filterIcon: filtered => (
            <Icon id={`filter-menu-${dataIndex}`} type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) => {
            return index ?
                (
                    record[dataIndex][index]
                        .toString()
                        .toLowerCase()
                        .includes(value.toLowerCase())

                ) :
                (
                    record[dataIndex]
                        .toString()
                        .toLowerCase()
                        .includes(value.toLowerCase())

                )
        },
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: text => (
            <Highlighter
                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                searchWords={[this.state.searchText]}
                autoEscape
                textToHighlight={text.toString()}
            />
        ),
    });

    handleSearch = (selectedKeys, confirm) => {
        confirm();
        this.setState({ searchText: selectedKeys[0] });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };
    deleteDevice = async (id) => {
        this.setState({ loading: true })
        const response = await Request.deleteDevice(id)

        if (!response.error) {
            notification.success({
                message: capitalize.words(response.message),
                btn: <Button type='primary' onClick={() => this.undo(id)}>Undo</Button>
            })
            this.apiRequest()
        } else {
            notification.error({
                message: 'Error Saving',
                description: capitalize.words(response.message)
            })
        }
    }
    undo = async (id) => {
        const response = await Request.restoreDevice(id)
        notification.destroy()
        this.apiRequest()
    }
    editDevice = async (id) => {
        console.log(id)
        this.props.dispatch(getPushPathWrapper('devices.infoDevice', { id }))
    }
    render() {
        const { tableLoading, dataSource, loading, companies } = this.state
        const { getFieldDecorator, resetFields } = this.props.form
        const columns = [
            {
                title: 'Name',
                key: 'name',
                dataIndex: 'name'
            },
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                ...this.getColumnSearchProps('id'),
            },
            {
                title: 'IMEI',
                dataIndex: 'imei',
                key: 'imei',
                ...this.getColumnSearchProps('imei'),
            },
            {
                title: 'Location',
                dataIndex: 'location',
                key: 'location',
                // ...this.getColumnSearchProps('discount'),

            },
            {
                title: 'Rate Chart',
                dataIndex: 'rateChart',
                key: 'rateChart',
                // ...this.getColumnSearchProps('discount')
            },
            {
                title: 'Language',
                dataIndex: 'language',
                key: 'language',
                // ...this.getColumnSearchProps('branchArray')
            },
            {
                title: 'Farmers',
                dataIndex: 'farmers',
                key: 'farmers',

            },

            {
                title: 'Action',
                dataIndex: 'id',
                key: 'action',
                render: (val) => {
                    console.log(val)
                    return (
                        <div>
                            <Button shape="circle" icon="info" id={'edit'} className={styles.iconBtn} onClick={() => this.editDevice(val)} />
                            <Button shape="circle" icon="delete" loading={loading} type='danger' id={'delete'} className={styles.iconBtn} onClick={() => this.deleteDevice(val)} />
                        </div>
                    )
                }
            }

        ];

        return (
            <PageHeaderWrapper
                title={'All Devices'}>
                <Card id={'listDevices'}>
                    <div className={styles.filter}>
                        <Form.Item>{getFieldDecorator('company')(
                            <Select
                                style={{ width: 200 }}
                                placeholder='Filter by Company'
                                onChange={(val) => this.apiRequest({ company: val })}
                            >{
                                    companies.map(comp => <Option value={comp.id} label={comp.id}>{comp.name}</Option>)
                                }
                            </Select>
                        )}</Form.Item>
                        <Button type='primary' onClick={() => {
                            this.apiRequest()
                            resetFields()
                        }}>Reset</Button>
                    </div>
                    <Table size='small' id={'listDevices-table'} loading={tableLoading} columns={columns} rowKey={record => record.id} dataSource={dataSource} onChange={this.handleChange} />
                </Card>
            </PageHeaderWrapper>
        )
    }
}


const mapStateToProps = ({ global, router }) => ({
    categories: global.categories,
    currentUser: global.currentUser,
    pathname: router.location.pathname
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(listDevices)
