import React, { useEffect, useState } from 'react'
import styles from './styles.less'
import LeftPanel from './leftSidePanel'
import RightPanel from './rightSidePanel'
import { useSelector, useDispatch } from 'react-redux'
import DebugScreen from './debugScreen'
import { getUrlParams } from '../../../routes'


const McpDebugScreen = (props) => {

    const dispatch = useDispatch()
    const [mcpId, setMcpId] = useState(null)
    const [uuid, setUUID] = useState(null)
    const { pathname } = useSelector((state) => ({
        pathname: state.router.location.pathname
    }))
    useEffect(() => {
        dispatch({ type: 'TOGGLE_SIDEBAR_COLLAPSED' })
        dispatch({ type: 'DEBUG_HIDE_LOADER' })
        dispatch({ type: 'DETAILS_HIDE_LOADER' })
        dispatch({ type: 'SET_SETTINGS', payload: {} })
        dispatch({ type: 'mcpDetails', payload: {} })
        const params = getUrlParams('debugScreen', pathname)
        if (params && params.id){
            setMcpId(params.id)
            setUUID(params.uuid)
        }
        // dispatch(ListSettingsAction(params.id))
    }, [])
    return (
        <div className={styles.rootDiv}>
            <div className={styles.leftSideDivs}>
                <LeftPanel mcpId={mcpId} />
            </div>
            <div className={styles.debugLogs}>
                <DebugScreen mcpId={mcpId} uuid={uuid} />
            </div>
            <div className={styles.rightSideDivs}>
                <RightPanel mcpId={mcpId} />
            </div>
        </div>

    )
}

export default McpDebugScreen
