import React, { PureComponent } from 'react'
import _ from 'lodash'
import { Card, Form, Icon, Spin } from 'antd'
import { ReactSortable } from "react-sortablejs";
import styles from './styles.less'
import Request from '../../../request'
import { connect } from 'react-redux'
import { Empty, List } from 'antd'
const defaultDataSource = [
    'id',
    'collectionDate',
    'milkType',
    'collectionTime',
    'farmerUUID',
    'farmerId',
    'farmerName',
    'rate',
    'totalAmount',
    'fat',
    'snf',
    'addedWater',
    'quantity',
    'quantityType',
    'extraField',
    'status',
    'stage',
    'farmerMobile',
    'rateChartId',
    'rateChartName',
    'collectionMode',
    'sync',
    'edit',
    'createdOn',
    'updatedOn',
    'machineCollectionTime',
    'clr',
    'anotherExtra',
    'incentive'
]
const defaultTargetKeys = [
    'id',
    'collectionDate',
    'milkType',
    'collectionTime',
    'farmerUUID',
    'farmerId',
    'farmerName',
    'rate',
    'totalAmount'
]

@Form.create()
class CollectionExportSettings extends PureComponent {

    state = {
        cardLoading: false,
        dataSource: [],
        targetKeys: [],
        loading: false,
        reset: false
    }

    componentDidMount() {
        this.apiRequest()

    }
    updateProps = () => {
        const { updateFormData, reset, submit, form } = this.props
        if (reset !== this.state.reset) {
            this.state.reset = reset
            this.state.targetKeys = []
            this.state.dataSource = []
            this.state.company = false
            this.getFields()
        }
        if (submit !== this.state.submit) {
            this.state.submit = submit
            updateFormData('collectionExportSettings', { fields: [...this.state.targetKeys.map(x => x.dataIndex)] })
        }
    }
    getTargetKeys = () => {
        const keys = _.clone(this.state.targetKeys)
        return keys.map(val => val.dataIndex)
    }

    apiRequest = async () => {
        const { currentUser, form, company } = this.props
        if (company) {

            this.setState({ cardLoading: true })
            let filter = { level: 'COMPANY', companyId: company }
            let data = await Request.getCollectionExportSettings(filter)
            this.setState({ cardLoading: false })

            if (data && data.data && data.data.fields) {
                let targetKeys = []
                _.forEach(data.data.fields, (val, key) => {
                    const data = {
                        key: key + 1,
                        title: _.startCase(val),
                        dataIndex: val,
                        selected: true
                    }
                    targetKeys.push(data)
                })
                this.setState({ targetKeys })
                this.getFields(targetKeys.map(val => val.dataIndex), true)
            }
            else
                this.getFields()
        }
        else
            this.getFields()

    }

    handleChange = (item, remove) => {
        let targetKeys = _.clone(this.state.targetKeys)
        const newData = this.state.dataSource.map(val => {
            if (val.dataIndex === item.dataIndex) {
                val.selected = !val.selected
            }
            return val
        })
        if (remove) {
            targetKeys = targetKeys.filter(val => val.dataIndex != item.dataIndex)
        }
        else
            targetKeys.push(item)

        this.setState({ dataSource: newData, targetKeys });
    };

    getFields = async (selectedKeys = defaultTargetKeys, isEdit) => {
        const targetKeys = [], dataSource = [];

        _.forEach(defaultDataSource, (val, key) => {
            const data = {
                key: key + 1,
                title: _.startCase(val),
                dataIndex: val,
                selected: selectedKeys.includes(val) ? true : false
            };
            dataSource.push(data);
        })
        if (!isEdit) {
            _.forEach(selectedKeys, (val, key) => {
                const data = {
                    key: key + 1,
                    title: _.startCase(val),
                    dataIndex: val,
                    selected: true
                };
                targetKeys.push(data);
            })
            this.setState({ targetKeys });
        }

        this.setState({ dataSource });
    };

    render() {
        const { targetKeys, dataSource, cardLoading } = this.state
        this.updateProps()

        return (
            <Card className={styles.wrapper}>
                <Spin spinning={cardLoading}>
                    <div style={{ display: 'flex' }}>
                        <div className={styles.list}>
                            <h2>All Fields</h2>
                            <List
                                size="small"
                                bordered
                                style={{
                                    width: 300,
                                    height: 400,
                                    overflowY: 'scroll'
                                }}
                                dataSource={dataSource.filter(item => !item.selected)}
                                renderItem={item => <List.Item actions={[
                                    <Icon onClick={() => { this.handleChange(item) }} style={{ margin: '0 0 0 10px' }} className={styles.icon} type='arrow-right' />
                                ]} >{item.title}</List.Item>}
                            />
                        </div>
                        <div className={styles.list}>
                            <h2>Export Collection Fields</h2>
                            <div className={styles.sortableList}>
                                {targetKeys.length ?
                                    <ReactSortable
                                        handle=".handle"
                                        list={targetKeys}
                                        animation={150}
                                        setList={newState => this.setState({ targetKeys: newState })}
                                    >
                                        {targetKeys.map((item) => <div key={item.key}>
                                            <span><Icon onClick={() => this.handleChange(item, true)} className={styles.icon} type='arrow-left' />{item.title}</span>
                                            <Icon className={`handle ${styles.handle}`} type='drag' />
                                        </div>)}
                                    </ReactSortable> :
                                    <div style={{ alignSelf: 'center' }}>
                                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </Spin>
            </Card>

        )
    }
}

const mapStateToProps = ({ global, router }) => ({
    loading: global.buttonLoading,
    categories: global.categories,
    search: router.location.search,
    currentUser: global.currentUser,
    pathname: router.location.pathname

})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CollectionExportSettings)
