import React, { Component } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { TableComp } from 'sz-react-utils-lite'

import moment from 'moment'
import {
  Table,
  Badge,
  Card,
  Tag,
  Tooltip,
  Popconfirm,
  Select,
  Form,
  notification,
  Switch,
  Input, Button, Icon
} from 'antd'
import Request from '../../../request'
import Color from 'color'
import _ from 'lodash'
import Highlighter from 'react-highlight-words'
import styles from './styles.less'
import { connect } from 'react-redux'
import memoizeOne from 'memoize-one'
import update from 'immutability-helper'
import { getUrlPushWrapper, getPushPathWrapper } from '../../../routes'

@Form.create()
class AllMcc extends Component {
  state = {
    total: 0
  }

  constructor(props) {
    super(props)
    this.table = React.createRef()
  }
  deleteMcc = async id => {
    this.setState({ loading: true })
    let x = await Request.deletemcp(id)
    console.log(x)
    this.setState({ loading: false })
    this.table.current.reload()
    if (!x.error) {
      notification.success({
        message: x.message,
        btn: <Button type='primary' onClick={() => this.undo(id)}>Undo</Button>,
        key: `${id}-close`
      })

    }
    else {
      notification.error({
        message: x.message,
        key: `${id}-close`
      })
    }


  }

  undo = async (id) => {
    const response = await Request.restoremcp(id)
    notification.destroy()
    this.table.current.reload()
  }

  apiRequest = (params, columns) => {

    return new Promise(async resolve => {

      const { selectedCompany } = this.state
      const { currentUser } = this.props

      this.setState({
        dataSearchParams: params
      })
      let filters = {
        ...params,
        details: true
      }
      if (selectedCompany) {
        filters.companyId = selectedCompany
      }
      let data = await Request.getAllmcp(filters)
      if (data.error) {
        return resolve({
          total: 0
        })
      }

      this.setState({
        total: data.total
      })

      resolve(data)
    })
  }
  /* apiRequest = (params = {}) => {
    return new Promise(async (resolve) => {
      let data = await Request.getAllmcp({ ...params })
      console.log({ ...params }, data)
      this.setState({
        allmcp: data.data
      })
      resolve(data)
    })
  } */

  companyChildrens = []
  getCompanies = async () => {
    let { data } = await Request.listCompanies()
    if (data) {
      _.forEach(data, (company) => {
        this.companyChildrens.push(
          <Select.Option value={company.id} key={company.id}>{company.name}</Select.Option>
        )
      })
    }
  }

  onSelectChangeCompany = (val) => {
    this.state.selectedCompany = val
    this.table.current.reload(true)
  }
  onReset = () => {
    this.props.form.resetFields()
    this.state.selectedMcp = null
    this.state.selectedCompany = null
    this.table.current.reload()
    // this.apiRequest()
  }
  componentDidMount() {
    this.getCompanies()
  }

  render() {
    const { submitting, dispatch, form: { getFieldDecorator } } = this.props
    const { total } = this.state


    const columns = [
      {
        title: 'Key',
        dataIndex: 'uuid',
        key: 'uuid',
        searchTextName: true
      },
      {
        title: 'Company',
        dataIndex: 'companyId.name',
        key: 'companyId'
      },
      {
        title: 'MCP Group',
        dataIndex: 'mcpGroupId',
        key: 'mcpGroupId',
        render: (val) => val && val.groupName
      },
      {
        title: 'MCP Name',
        dataIndex: 'name',
        key: 'name',
        searchTextName: true
      },
      {
        title: 'Contact Name',
        dataIndex: 'contactName',
        key: 'contactName',
        searchTextName: true
      },
      {
        title: 'Mobile No',
        dataIndex: 'mobile',
        key: 'mobile',
        searchTextName: true
      },
      {
        title: 'Created',
        dataIndex: 'createdBy.name',
        key: 'createdBy.name',
        render: (val, record) => {
          return <Tag color="blue">{moment(record.time).format('YYYY-MMM-DD')}</Tag>
        }

      },
      {
        key: 'actions',
        title: 'Actions',
        render: (text, record) => {
          console.log(record)
          return <React.Fragment>
            <Tooltip title="Edit MCP">
              <Button
                shape="circle"
                style={{ marginRight: 6 }}
                size="small"
                onClick={() => {
                  dispatch(
                    getPushPathWrapper('mcp.editMcc', { id: record.id })
                  )
                }}
                icon="edit"
              />
            </Tooltip>
            <Tooltip title="MCP logs">
              <Button
                shape="circle"
                style={{ marginRight: 6 }}
                size="small"
                onClick={() => {
                  dispatch(
                    getPushPathWrapper('debugScreen', { id: record.id, uuid: record.uuid })
                  )
                }}
                icon="bug"
              />
            </Tooltip>
            {/* <Tooltip title="Delete MCP">
              <Popconfirm
                title="Are you sure delete this MCC?"
                onConfirm={() => {
                  this.deleteMcc(record.id)
                }}
                onCancel={() => {
                  console.log()
                }}
                okText="Yes"
                cancelText="No">
                <Button
                  shape="circle"
                  size="small"
                  icon="delete"
                  type='danger'
                />
              </Popconfirm>
            </Tooltip> */}
          </React.Fragment>
        }
      }

    ]
    return (
      <PageHeaderWrapper
        title={`All MCP (${total})`}>

        <Card bordered={true}>
          <div style={{ marginBottom: '10px' }} className={styles.filter}>
            <div>
              <Form layout={'inline'}>
                <Form.Item>
                  {getFieldDecorator('company')(
                    <Select
                      style={{ width: '200px' }}
                      placeholder='Company Filter'
                      onChange={this.onSelectChangeCompany}
                      showSearch
                      optionFilterProp='children'
                      defaultActiveFirstOption={false}
                      showArrow={true}
                      filterOption={true}
                    >
                      {this.companyChildrens}
                    </Select>
                  )}
                </Form.Item>
              </Form>
            </div>
            <Button onClick={this.onReset}>Reset</Button>
          </div>

          <TableComp ref={this.table}
            columns={columns}
            apiRequest={params => this.apiRequest(params, columns)}
            pagination={{
              showSizeChanger: true,
              defaultPageSize: 20,
              pageSizeOptions: ['10', '20', '50', '100', '1000']
            }}
          />
        </Card>

      </PageHeaderWrapper>)

  }
}


const mapStateToProps = ({ global }) => ({
  categories: global.categories
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AllMcc)
